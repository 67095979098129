/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateSchoolContactDto } from './createSchoolContactDto';


/**
 * DTO for school account creation
 */
export interface CreateSchoolDto { 
    name: string;
    districtId: number;
    schoolTypeId: number;
    schoolGradeIds: Array<number>;
    schoolSubjectIds: Array<number>;
    code: string;
    createSchoolContactDto: CreateSchoolContactDto;
    hasCustomLogo?: boolean;
    hasCustomTheme?: boolean;
    maxDocumentDiskSpace?: number;
    maxDocumentDiskSpaceUnit?: CreateSchoolDto.MaxDocumentDiskSpaceUnitEnum;
    maxDocumentDiskSpaceBuffer?: number;
    maxDocumentDiskSpaceBufferUnit?: CreateSchoolDto.MaxDocumentDiskSpaceBufferUnitEnum;
}
export namespace CreateSchoolDto {
    export type MaxDocumentDiskSpaceUnitEnum = 'BYTES' | 'KILOBYTES' | 'MEGABYTES' | 'GIGABYTES' | 'TERABYTES' | 'PETABYTES';
    export const MaxDocumentDiskSpaceUnitEnum = {
        BYTES: 'BYTES' as MaxDocumentDiskSpaceUnitEnum,
        KILOBYTES: 'KILOBYTES' as MaxDocumentDiskSpaceUnitEnum,
        MEGABYTES: 'MEGABYTES' as MaxDocumentDiskSpaceUnitEnum,
        GIGABYTES: 'GIGABYTES' as MaxDocumentDiskSpaceUnitEnum,
        TERABYTES: 'TERABYTES' as MaxDocumentDiskSpaceUnitEnum,
        PETABYTES: 'PETABYTES' as MaxDocumentDiskSpaceUnitEnum
    };
    export type MaxDocumentDiskSpaceBufferUnitEnum = 'BYTES' | 'KILOBYTES' | 'MEGABYTES' | 'GIGABYTES' | 'TERABYTES' | 'PETABYTES';
    export const MaxDocumentDiskSpaceBufferUnitEnum = {
        BYTES: 'BYTES' as MaxDocumentDiskSpaceBufferUnitEnum,
        KILOBYTES: 'KILOBYTES' as MaxDocumentDiskSpaceBufferUnitEnum,
        MEGABYTES: 'MEGABYTES' as MaxDocumentDiskSpaceBufferUnitEnum,
        GIGABYTES: 'GIGABYTES' as MaxDocumentDiskSpaceBufferUnitEnum,
        TERABYTES: 'TERABYTES' as MaxDocumentDiskSpaceBufferUnitEnum,
        PETABYTES: 'PETABYTES' as MaxDocumentDiskSpaceBufferUnitEnum
    };
}
