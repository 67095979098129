/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { RestResponseMessage } from '../model/restResponseMessage';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class XmasSpecialControllerService {

    protected basePath = 'https://api.skills4school.de/s4s-api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * update calendar door entry and set flag if question behind door has been answered correctly or no
     * 
     * @param doorNumber doorNumber
     * @param isCorrectlyAnswered isCorrectlyAnswered
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public answerQuestionBehindCalendarDoorUsingPATCH(doorNumber: number, isCorrectlyAnswered: boolean, name?: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public answerQuestionBehindCalendarDoorUsingPATCH(doorNumber: number, isCorrectlyAnswered: boolean, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public answerQuestionBehindCalendarDoorUsingPATCH(doorNumber: number, isCorrectlyAnswered: boolean, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public answerQuestionBehindCalendarDoorUsingPATCH(doorNumber: number, isCorrectlyAnswered: boolean, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (doorNumber === null || doorNumber === undefined) {
            throw new Error('Required parameter doorNumber was null or undefined when calling answerQuestionBehindCalendarDoorUsingPATCH.');
        }

        if (isCorrectlyAnswered === null || isCorrectlyAnswered === undefined) {
            throw new Error('Required parameter isCorrectlyAnswered was null or undefined when calling answerQuestionBehindCalendarDoorUsingPATCH.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (doorNumber !== undefined && doorNumber !== null) {
            queryParameters = queryParameters.set('doorNumber', <any>doorNumber);
        }
        if (isCorrectlyAnswered !== undefined && isCorrectlyAnswered !== null) {
            queryParameters = queryParameters.set('isCorrectlyAnswered', <any>isCorrectlyAnswered);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<RestResponseMessage>(`${this.basePath}/xmas/calendar/door/update/answered`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * count correct answers by user
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countCorrectAnswersUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public countCorrectAnswersUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public countCorrectAnswersUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public countCorrectAnswersUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/xmas/calendar/door/count/correct-answers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get a list of CalendarDoorDTO for current user
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCalendarDoorsUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getCalendarDoorsUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getCalendarDoorsUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getCalendarDoorsUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/xmas/calendar/door/get/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get all questions for X-mas collection
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleQuestionDTOsForXmasCollectionUsingGET(observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getSimpleQuestionDTOsForXmasCollectionUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getSimpleQuestionDTOsForXmasCollectionUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getSimpleQuestionDTOsForXmasCollectionUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/xmas/collection/question/get/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get video link for X-mas collection
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVideoLinkUsingGET(observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getVideoLinkUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getVideoLinkUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getVideoLinkUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/xmas/get/video/link`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
