/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ChatQuestionsByFilterDTO { 
    deleted?: boolean;
    district?: number;
    editor?: number;
    hasPicture?: boolean;
    oldestTime?: number;
    page?: number;
    pageSize?: number;
    questionIds?: Array<number>;
    recentTime?: number;
    referenceQuestion?: number;
    schoolGrade?: number;
    schoolSubject?: number;
    schoolTopic?: number;
    schoolType?: number;
    sort?: ChatQuestionsByFilterDTO.SortEnum;
    textSearch?: string;
}
export namespace ChatQuestionsByFilterDTO {
    export type SortEnum = 'newest' | 'mostcomments' | 'mostlikes';
    export const SortEnum = {
        Newest: 'newest' as SortEnum,
        Mostcomments: 'mostcomments' as SortEnum,
        Mostlikes: 'mostlikes' as SortEnum
    };
}
