/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * DTO to add content from multiple school subjects to a teacher
 */
export interface AddSubjectsToTeacherDto { 
    schoolCode: string;
    teacherUsername: string;
    schoolSubjectIds: Array<number>;
}
