import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { AppComponent } from './app.component';
// Localization
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr-FR');
// Swagger
import { ApiModule, BASE_PATH, Configuration } from '../swagger';
import { AuthenticationService } from './services/authentication.service';
import { environment } from 'src/environments/environment';
import { FCM } from '@capacitor-community/fcm';

import * as Sentry from '@sentry/angular';
Sentry.init({ dsn: 'https://7072fa3ede6c435295ff725064a8a629@sentry.skills4school.de/6' });

// Overwrite environment variable if needed
// const environment = {
//   basePath: 'https://dev.skills4school.de/s4s-api',
// };

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    ApiModule,
    BrowserModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot({
      mode: 'ios',
      spinner: 'crescent'
    }),
    AppRoutingModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
    { provide: BASE_PATH, useValue: environment.basePath },
    {
      provide: Configuration,
      useFactory: (authProvider: AuthenticationService) => {
        return new Configuration({
          basePath: environment.basePath,
          accessToken: (): string => {
            const token = authProvider.getAccessToken();
            return token;
          },
          username: 'skills4schoolApp',
          password: '',
          apiKeys: null,
          withCredentials: true
        });
      },
      deps: [AuthenticationService],
      multi: false
    },
    FCM
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
