/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * DTO for school contact creation
 */
export interface CreateSchoolContactDto { 
    firstName: string;
    lastName: string;
    mailAddress: string;
    position: string;
    department?: string;
    salutation?: string;
    title?: string;
    phoneNumber?: string;
    faxNumber?: string;
    street?: string;
    streetNumber?: string;
    zipCode?: string;
    city?: string;
    postOfficeBoxZipCode?: string;
    postOfficeBoxCity?: string;
    mainContact?: boolean;
}
