/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { SimplePublisherDTO } from '../model/simplePublisherDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PublisherControllerService {

    protected basePath = 'https://api.skills4school.de/s4s-api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create a new publisher
     * 
     * @param simplePublisherDTO simplePublisherDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPublisherUsingPOST(simplePublisherDTO: SimplePublisherDTO, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public createPublisherUsingPOST(simplePublisherDTO: SimplePublisherDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public createPublisherUsingPOST(simplePublisherDTO: SimplePublisherDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public createPublisherUsingPOST(simplePublisherDTO: SimplePublisherDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simplePublisherDTO === null || simplePublisherDTO === undefined) {
            throw new Error('Required parameter simplePublisherDTO was null or undefined when calling createPublisherUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/publisher/data/simple`,
            simplePublisherDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all publishers
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllPublishersUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<SimplePublisherDTO>>;
    public getAllPublishersUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimplePublisherDTO>>>;
    public getAllPublishersUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimplePublisherDTO>>>;
    public getAllPublishersUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimplePublisherDTO>>(`${this.basePath}/publisher/data/simple/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get publisher as simple dto by id
     * 
     * @param publisherId publisherId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublisherByIdUsingGET(publisherId: number, observe?: 'body', reportProgress?: boolean): Observable<SimplePublisherDTO>;
    public getPublisherByIdUsingGET(publisherId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimplePublisherDTO>>;
    public getPublisherByIdUsingGET(publisherId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimplePublisherDTO>>;
    public getPublisherByIdUsingGET(publisherId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publisherId === null || publisherId === undefined) {
            throw new Error('Required parameter publisherId was null or undefined when calling getPublisherByIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimplePublisherDTO>(`${this.basePath}/publisher/data/simple/single/${encodeURIComponent(String(publisherId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
