import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ThemeSwitcherService } from './services/theme-switcher.service';
import { AuthenticationService } from './services/authentication.service';

import { Plugins, StatusBarStyle } from '@capacitor/core';
const { SplashScreen, StatusBar } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private themeSwitcherService: ThemeSwitcherService,
    private navController: NavController,
    private authenticationService: AuthenticationService
  ) { }

  async ngOnInit() {
    await this.authenticationService.setAccessToken();
    await this.themeSwitcherService.initTheme();

    const token = await this.authenticationService.getAccessTokenAysnc();
    if (!token) {
      await this.navController.navigateRoot('/login');
    }
  }

  async ngAfterViewInit() {
    try {
      await SplashScreen.hide();
      await StatusBar.setStyle({
        style: StatusBarStyle.Dark
      });
    } catch { }
  }

}
