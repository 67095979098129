/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Simple DTO for question collections
 */
export interface SimpleQuestionCollectionDTO { 
    archived?: boolean;
    date: Date;
    districtId: number;
    examDate?: Date;
    hasCollectionReference?: boolean;
    id: number;
    name: string;
    ownerUserName: string;
    publisherId: number;
    /**
     * Subject ID which is fetched from the linked topics
     */
    readonly schoolSubjectId?: number;
    schoolTopicIds: Array<number>;
    schoolTypeId: number;
    shareCode: string;
    timestampInserted: Date;
    timestampUpdated?: Date;
}
