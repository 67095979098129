/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface QuestionsByFilterDTO { 
    active?: boolean;
    district?: number;
    editor?: number;
    iconPath?: boolean;
    points?: number;
    questionType?: QuestionsByFilterDTO.QuestionTypeEnum;
    reported?: boolean;
    schoolGrade?: number;
    schoolType?: number;
    subject?: number;
    topic?: number;
}
export namespace QuestionsByFilterDTO {
    export type QuestionTypeEnum = 'YES_NO' | 'MULTIPLE_CHOICE' | 'NUMBER_INPUT' | 'TEXT_INPUT' | 'COMPLEX';
    export const QuestionTypeEnum = {
        YESNO: 'YES_NO' as QuestionTypeEnum,
        MULTIPLECHOICE: 'MULTIPLE_CHOICE' as QuestionTypeEnum,
        NUMBERINPUT: 'NUMBER_INPUT' as QuestionTypeEnum,
        TEXTINPUT: 'TEXT_INPUT' as QuestionTypeEnum,
        COMPLEX: 'COMPLEX' as QuestionTypeEnum
    };
}
