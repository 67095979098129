/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Complete DTO for purchasables
 */
export interface PurchasableDTO { 
    amount?: number;
    id?: number;
    inAppPurchaseId?: string;
    name?: string;
    paypalPlanId?: string;
    paypalPrice?: number;
    paypalPriceDescription?: string;
    paypalPriceDiscountComparisionMonthly?: number;
    paypalProductId?: string;
    purchasableType?: PurchasableDTO.PurchasableTypeEnum;
}
export namespace PurchasableDTO {
    export type PurchasableTypeEnum = 'SUBSCRIPTION' | 'CREDITS';
    export const PurchasableTypeEnum = {
        SUBSCRIPTION: 'SUBSCRIPTION' as PurchasableTypeEnum,
        CREDITS: 'CREDITS' as PurchasableTypeEnum
    };
}
