/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { HintDTO } from './hintDTO';
import { ReportMessageDTO } from './reportMessageDTO';
import { SimpleContentPackageDTO } from './simpleContentPackageDTO';
import { SimpleFormulaDTO } from './simpleFormulaDTO';
import { SimpleSchoolDistrictDTO } from './simpleSchoolDistrictDTO';
import { SimpleSchoolTopicDTO } from './simpleSchoolTopicDTO';
import { SimpleSchoolTypeDTO } from './simpleSchoolTypeDTO';
import { SimpleSubTopicDTO } from './simpleSubTopicDTO';
import { UserDTO } from './userDTO';


/**
 * Full question DTO
 */
export interface QuestionDTO { 
    active?: boolean;
    creator?: UserDTO;
    description: string;
    errorHintDTO: HintDTO;
    hintDTOOne: HintDTO;
    hintDTOThree: HintDTO;
    hintDTOTwo: HintDTO;
    iconPath: string;
    iconSource: string;
    id: number;
    points: number;
    reportMessages?: Array<ReportMessageDTO>;
    reported?: boolean;
    simpleContentPackageDTO: SimpleContentPackageDTO;
    simpleFormulaDTOS: Array<SimpleFormulaDTO>;
    simpleSchoolDistrictDTOS: Array<SimpleSchoolDistrictDTO>;
    simpleSchoolTopicDTO: SimpleSchoolTopicDTO;
    simpleSchoolTypeDTOS: Array<SimpleSchoolTypeDTO>;
    simpleSubTopicDTO: SimpleSubTopicDTO;
    timestampInserted: Date;
    timestampUpdated?: Date;
    type: QuestionDTO.TypeEnum;
}
export namespace QuestionDTO {
    export type TypeEnum = 'YES_NO' | 'MULTIPLE_CHOICE' | 'NUMBER_INPUT' | 'TEXT_INPUT' | 'COMPLEX';
    export const TypeEnum = {
        YESNO: 'YES_NO' as TypeEnum,
        MULTIPLECHOICE: 'MULTIPLE_CHOICE' as TypeEnum,
        NUMBERINPUT: 'NUMBER_INPUT' as TypeEnum,
        TEXTINPUT: 'TEXT_INPUT' as TypeEnum,
        COMPLEX: 'COMPLEX' as TypeEnum
    };
}
