/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { InitSchoolTopicAndGetFirstQuestionsDto } from '../model/initSchoolTopicAndGetFirstQuestionsDto';
import { InitSchoolTopicWithinCollectionAndGetFirstQuestionsDto } from '../model/initSchoolTopicWithinCollectionAndGetFirstQuestionsDto';
import { InitSubtopicAndGetFirstQuestionsDto } from '../model/initSubtopicAndGetFirstQuestionsDto';
import { InitSubtopicWithinCollectionAndGetFirstQuestionsDto } from '../model/initSubtopicWithinCollectionAndGetFirstQuestionsDto';
import { RestResponseMessage } from '../model/restResponseMessage';
import { UpdateSchoolTopicAndGetNextQuestionsDto } from '../model/updateSchoolTopicAndGetNextQuestionsDto';
import { UpdateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto } from '../model/updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto';
import { UpdateSubTopicProgressAndGetNextQuestionsDto } from '../model/updateSubTopicProgressAndGetNextQuestionsDto';
import { UpdateSubTopicWithinCollectionProgressAndGetNextQuestionsDto } from '../model/updateSubTopicWithinCollectionProgressAndGetNextQuestionsDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class LearningProgressControllerService {

    protected basePath = 'https://api.skills4school.de/s4s-api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get learning progress for a school topic
     * 
     * @param schoolTopicId schoolTopicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgressForSchoolTopicUsingGET(schoolTopicId: number, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getProgressForSchoolTopicUsingGET(schoolTopicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getProgressForSchoolTopicUsingGET(schoolTopicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getProgressForSchoolTopicUsingGET(schoolTopicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schoolTopicId === null || schoolTopicId === undefined) {
            throw new Error('Required parameter schoolTopicId was null or undefined when calling getProgressForSchoolTopicUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/learning/get/progress/for/school-topic/${encodeURIComponent(String(schoolTopicId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get learning progress for a school topic within a collection
     * 
     * @param collectionId collectionId
     * @param schoolTopicId schoolTopicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgressForSchoolTopicWithinCollectionUsingGET(collectionId: number, schoolTopicId: number, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getProgressForSchoolTopicWithinCollectionUsingGET(collectionId: number, schoolTopicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getProgressForSchoolTopicWithinCollectionUsingGET(collectionId: number, schoolTopicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getProgressForSchoolTopicWithinCollectionUsingGET(collectionId: number, schoolTopicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (collectionId === null || collectionId === undefined) {
            throw new Error('Required parameter collectionId was null or undefined when calling getProgressForSchoolTopicWithinCollectionUsingGET.');
        }

        if (schoolTopicId === null || schoolTopicId === undefined) {
            throw new Error('Required parameter schoolTopicId was null or undefined when calling getProgressForSchoolTopicWithinCollectionUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/learning/get/progress/for/school-topic/${encodeURIComponent(String(schoolTopicId))}/within-collection/${encodeURIComponent(String(collectionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get learning progress for a sub topic
     * 
     * @param subTopicId subTopicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgressForSubTopicUsingGET(subTopicId: number, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getProgressForSubTopicUsingGET(subTopicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getProgressForSubTopicUsingGET(subTopicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getProgressForSubTopicUsingGET(subTopicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (subTopicId === null || subTopicId === undefined) {
            throw new Error('Required parameter subTopicId was null or undefined when calling getProgressForSubTopicUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/learning/get/progress/for/sub-topic/${encodeURIComponent(String(subTopicId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get learning progress for a sub topic within a collection
     * 
     * @param collectionId collectionId
     * @param subTopicId subTopicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgressForSubTopicWithinCollectionUsingGET(collectionId: number, subTopicId: number, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getProgressForSubTopicWithinCollectionUsingGET(collectionId: number, subTopicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getProgressForSubTopicWithinCollectionUsingGET(collectionId: number, subTopicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getProgressForSubTopicWithinCollectionUsingGET(collectionId: number, subTopicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (collectionId === null || collectionId === undefined) {
            throw new Error('Required parameter collectionId was null or undefined when calling getProgressForSubTopicWithinCollectionUsingGET.');
        }

        if (subTopicId === null || subTopicId === undefined) {
            throw new Error('Required parameter subTopicId was null or undefined when calling getProgressForSubTopicWithinCollectionUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/learning/get/progress/for/sub-topic/${encodeURIComponent(String(subTopicId))}/within-collection/${encodeURIComponent(String(collectionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initialize school topic for the learning mode and get first questions
     * 
     * @param initSchoolTopicAndGetFirstQuestionsDto initSchoolTopicAndGetFirstQuestionsDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public initSchoolTopicAndGetFirstQuestionsUsingPOST(initSchoolTopicAndGetFirstQuestionsDto: InitSchoolTopicAndGetFirstQuestionsDto, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public initSchoolTopicAndGetFirstQuestionsUsingPOST(initSchoolTopicAndGetFirstQuestionsDto: InitSchoolTopicAndGetFirstQuestionsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public initSchoolTopicAndGetFirstQuestionsUsingPOST(initSchoolTopicAndGetFirstQuestionsDto: InitSchoolTopicAndGetFirstQuestionsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public initSchoolTopicAndGetFirstQuestionsUsingPOST(initSchoolTopicAndGetFirstQuestionsDto: InitSchoolTopicAndGetFirstQuestionsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (initSchoolTopicAndGetFirstQuestionsDto === null || initSchoolTopicAndGetFirstQuestionsDto === undefined) {
            throw new Error('Required parameter initSchoolTopicAndGetFirstQuestionsDto was null or undefined when calling initSchoolTopicAndGetFirstQuestionsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RestResponseMessage>(`${this.basePath}/learning/init/school-topic/and/get/first-questions`,
            initSchoolTopicAndGetFirstQuestionsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initialize school topic within a collection for the learning mode and get first questions
     * 
     * @param initSchoolTopicWithinCollectionAndGetFirstQuestionsDto initSchoolTopicWithinCollectionAndGetFirstQuestionsDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public initSchoolTopicInCollectionAndGetFirstQuestionsUsingPOST(initSchoolTopicWithinCollectionAndGetFirstQuestionsDto: InitSchoolTopicWithinCollectionAndGetFirstQuestionsDto, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public initSchoolTopicInCollectionAndGetFirstQuestionsUsingPOST(initSchoolTopicWithinCollectionAndGetFirstQuestionsDto: InitSchoolTopicWithinCollectionAndGetFirstQuestionsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public initSchoolTopicInCollectionAndGetFirstQuestionsUsingPOST(initSchoolTopicWithinCollectionAndGetFirstQuestionsDto: InitSchoolTopicWithinCollectionAndGetFirstQuestionsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public initSchoolTopicInCollectionAndGetFirstQuestionsUsingPOST(initSchoolTopicWithinCollectionAndGetFirstQuestionsDto: InitSchoolTopicWithinCollectionAndGetFirstQuestionsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (initSchoolTopicWithinCollectionAndGetFirstQuestionsDto === null || initSchoolTopicWithinCollectionAndGetFirstQuestionsDto === undefined) {
            throw new Error('Required parameter initSchoolTopicWithinCollectionAndGetFirstQuestionsDto was null or undefined when calling initSchoolTopicInCollectionAndGetFirstQuestionsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RestResponseMessage>(`${this.basePath}/learning/init/school-topic/within-collection/and/get/first-questions`,
            initSchoolTopicWithinCollectionAndGetFirstQuestionsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initialize sub topic for the learning mode and get first questions
     * 
     * @param initSubtopicAndGetFirstQuestionsDto initSubtopicAndGetFirstQuestionsDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public initSubTopicAndGetFirstQuestionsUsingPOST(initSubtopicAndGetFirstQuestionsDto: InitSubtopicAndGetFirstQuestionsDto, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public initSubTopicAndGetFirstQuestionsUsingPOST(initSubtopicAndGetFirstQuestionsDto: InitSubtopicAndGetFirstQuestionsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public initSubTopicAndGetFirstQuestionsUsingPOST(initSubtopicAndGetFirstQuestionsDto: InitSubtopicAndGetFirstQuestionsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public initSubTopicAndGetFirstQuestionsUsingPOST(initSubtopicAndGetFirstQuestionsDto: InitSubtopicAndGetFirstQuestionsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (initSubtopicAndGetFirstQuestionsDto === null || initSubtopicAndGetFirstQuestionsDto === undefined) {
            throw new Error('Required parameter initSubtopicAndGetFirstQuestionsDto was null or undefined when calling initSubTopicAndGetFirstQuestionsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RestResponseMessage>(`${this.basePath}/learning/init/sub-topic/and/get/first-questions`,
            initSubtopicAndGetFirstQuestionsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initialize sub topic within a collection for the learning mode and get first questions
     * 
     * @param initSubtopicWithinCollectionAndGetFirstQuestionsDto initSubtopicWithinCollectionAndGetFirstQuestionsDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public initSubTopicInCollectionAndGetFirstQuestionsUsingPOST(initSubtopicWithinCollectionAndGetFirstQuestionsDto: InitSubtopicWithinCollectionAndGetFirstQuestionsDto, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public initSubTopicInCollectionAndGetFirstQuestionsUsingPOST(initSubtopicWithinCollectionAndGetFirstQuestionsDto: InitSubtopicWithinCollectionAndGetFirstQuestionsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public initSubTopicInCollectionAndGetFirstQuestionsUsingPOST(initSubtopicWithinCollectionAndGetFirstQuestionsDto: InitSubtopicWithinCollectionAndGetFirstQuestionsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public initSubTopicInCollectionAndGetFirstQuestionsUsingPOST(initSubtopicWithinCollectionAndGetFirstQuestionsDto: InitSubtopicWithinCollectionAndGetFirstQuestionsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (initSubtopicWithinCollectionAndGetFirstQuestionsDto === null || initSubtopicWithinCollectionAndGetFirstQuestionsDto === undefined) {
            throw new Error('Required parameter initSubtopicWithinCollectionAndGetFirstQuestionsDto was null or undefined when calling initSubTopicInCollectionAndGetFirstQuestionsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RestResponseMessage>(`${this.basePath}/learning/init/sub-topic/within-collection/and/get/first-questions`,
            initSubtopicWithinCollectionAndGetFirstQuestionsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update school topic learning progress and get next questions
     * 
     * @param updateSchoolTopicAndGetNextQuestionsDto updateSchoolTopicAndGetNextQuestionsDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSchoolTopicProgressAndGetNextQuestionsUsingPUT(updateSchoolTopicAndGetNextQuestionsDto: UpdateSchoolTopicAndGetNextQuestionsDto, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public updateSchoolTopicProgressAndGetNextQuestionsUsingPUT(updateSchoolTopicAndGetNextQuestionsDto: UpdateSchoolTopicAndGetNextQuestionsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public updateSchoolTopicProgressAndGetNextQuestionsUsingPUT(updateSchoolTopicAndGetNextQuestionsDto: UpdateSchoolTopicAndGetNextQuestionsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public updateSchoolTopicProgressAndGetNextQuestionsUsingPUT(updateSchoolTopicAndGetNextQuestionsDto: UpdateSchoolTopicAndGetNextQuestionsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateSchoolTopicAndGetNextQuestionsDto === null || updateSchoolTopicAndGetNextQuestionsDto === undefined) {
            throw new Error('Required parameter updateSchoolTopicAndGetNextQuestionsDto was null or undefined when calling updateSchoolTopicProgressAndGetNextQuestionsUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<RestResponseMessage>(`${this.basePath}/learning/update/school-topic/progress/and/get/next-questions`,
            updateSchoolTopicAndGetNextQuestionsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update school topic learning progress within a collection and get next questions
     * 
     * @param updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsUsingPUT(updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto: UpdateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsUsingPUT(updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto: UpdateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsUsingPUT(updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto: UpdateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsUsingPUT(updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto: UpdateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto === null || updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto === undefined) {
            throw new Error('Required parameter updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto was null or undefined when calling updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<RestResponseMessage>(`${this.basePath}/learning/update/school-topic/progress/within-collection/and/get/next-questions`,
            updateSchoolTopicWithinCollectionProgressAndGetNextQuestionsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update sub topic learning progress and get next questions
     * 
     * @param updateSubtopicProgressAndGetNextQuestionsDto updateSubtopicProgressAndGetNextQuestionsDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSubTopicProgressAndGetNextQuestionsUsingPUT(updateSubtopicProgressAndGetNextQuestionsDto: UpdateSubTopicProgressAndGetNextQuestionsDto, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public updateSubTopicProgressAndGetNextQuestionsUsingPUT(updateSubtopicProgressAndGetNextQuestionsDto: UpdateSubTopicProgressAndGetNextQuestionsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public updateSubTopicProgressAndGetNextQuestionsUsingPUT(updateSubtopicProgressAndGetNextQuestionsDto: UpdateSubTopicProgressAndGetNextQuestionsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public updateSubTopicProgressAndGetNextQuestionsUsingPUT(updateSubtopicProgressAndGetNextQuestionsDto: UpdateSubTopicProgressAndGetNextQuestionsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateSubtopicProgressAndGetNextQuestionsDto === null || updateSubtopicProgressAndGetNextQuestionsDto === undefined) {
            throw new Error('Required parameter updateSubtopicProgressAndGetNextQuestionsDto was null or undefined when calling updateSubTopicProgressAndGetNextQuestionsUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<RestResponseMessage>(`${this.basePath}/learning/update/sub-topic/progress/and/get/next-questions`,
            updateSubtopicProgressAndGetNextQuestionsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update sub topic learning progress within a collection and get next questions
     * 
     * @param updateSubTopicWithinCollectionProgressAndGetNextQuestionsDto updateSubTopicWithinCollectionProgressAndGetNextQuestionsDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSubTopicWithinCollectionProgressAndGetNextQuestionsUsingPUT(updateSubTopicWithinCollectionProgressAndGetNextQuestionsDto: UpdateSubTopicWithinCollectionProgressAndGetNextQuestionsDto, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public updateSubTopicWithinCollectionProgressAndGetNextQuestionsUsingPUT(updateSubTopicWithinCollectionProgressAndGetNextQuestionsDto: UpdateSubTopicWithinCollectionProgressAndGetNextQuestionsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public updateSubTopicWithinCollectionProgressAndGetNextQuestionsUsingPUT(updateSubTopicWithinCollectionProgressAndGetNextQuestionsDto: UpdateSubTopicWithinCollectionProgressAndGetNextQuestionsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public updateSubTopicWithinCollectionProgressAndGetNextQuestionsUsingPUT(updateSubTopicWithinCollectionProgressAndGetNextQuestionsDto: UpdateSubTopicWithinCollectionProgressAndGetNextQuestionsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateSubTopicWithinCollectionProgressAndGetNextQuestionsDto === null || updateSubTopicWithinCollectionProgressAndGetNextQuestionsDto === undefined) {
            throw new Error('Required parameter updateSubTopicWithinCollectionProgressAndGetNextQuestionsDto was null or undefined when calling updateSubTopicWithinCollectionProgressAndGetNextQuestionsUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<RestResponseMessage>(`${this.basePath}/learning/update/sub-topic/progress/within-collection/and/get/next-questions`,
            updateSubTopicWithinCollectionProgressAndGetNextQuestionsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
