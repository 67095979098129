import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AppQuestionControllerService } from './api/appQuestionController.service';
import { CampaignControllerService } from './api/campaignController.service';
import { ChatControllerService } from './api/chatController.service';
import { CollectionControllerService } from './api/collectionController.service';
import { EditorControllerService } from './api/editorController.service';
import { EditorQuestionControllerService } from './api/editorQuestionController.service';
import { ExclusiveTeacherControllerService } from './api/exclusiveTeacherController.service';
import { GroupRoomControllerService } from './api/groupRoomController.service';
import { GroupRoomInvitationControllerService } from './api/groupRoomInvitationController.service';
import { LearningProgressControllerService } from './api/learningProgressController.service';
import { MetaControllerService } from './api/metaController.service';
import { PaymentControllerService } from './api/paymentController.service';
import { PublisherControllerService } from './api/publisherController.service';
import { ReportingControllerService } from './api/reportingController.service';
import { SchoolControllerService } from './api/schoolController.service';
import { SchoolTopicControllerService } from './api/schoolTopicController.service';
import { SubTopicControllerService } from './api/subTopicController.service';
import { UserControllerService } from './api/userController.service';
import { UserProfileControllerService } from './api/userProfileController.service';
import { UserViewControllerService } from './api/userViewController.service';
import { ValidationControllerService } from './api/validationController.service';
import { XmasSpecialControllerService } from './api/xmasSpecialController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AppQuestionControllerService,
    CampaignControllerService,
    ChatControllerService,
    CollectionControllerService,
    EditorControllerService,
    EditorQuestionControllerService,
    ExclusiveTeacherControllerService,
    GroupRoomControllerService,
    GroupRoomInvitationControllerService,
    LearningProgressControllerService,
    MetaControllerService,
    PaymentControllerService,
    PublisherControllerService,
    ReportingControllerService,
    SchoolControllerService,
    SchoolTopicControllerService,
    SubTopicControllerService,
    UserControllerService,
    UserProfileControllerService,
    UserViewControllerService,
    ValidationControllerService,
    XmasSpecialControllerService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
