/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AppMetaChangeDTO } from '../model/appMetaChangeDTO';
import { DTOToGetSchoolTopicsWithLearningProgressDetailsByFilter } from '../model/dTOToGetSchoolTopicsWithLearningProgressDetailsByFilter';
import { GenericDataMessage } from '../model/genericDataMessage';
import { GenericMessage } from '../model/genericMessage';
import { OnboardingDTO } from '../model/onboardingDTO';
import { ResponseEntity } from '../model/responseEntity';
import { RestResponseMessage } from '../model/restResponseMessage';
import { SchoolTopicFilterDTO } from '../model/schoolTopicFilterDTO';
import { SchoolTopicTrainStatsDTO } from '../model/schoolTopicTrainStatsDTO';
import { SimpleSchoolDistrictDTO } from '../model/simpleSchoolDistrictDTO';
import { SimpleSchoolGradeDTO } from '../model/simpleSchoolGradeDTO';
import { SimpleSchoolSubjectDTO } from '../model/simpleSchoolSubjectDTO';
import { SimpleSchoolTopicDTO } from '../model/simpleSchoolTopicDTO';
import { SimpleSchoolTypeDTO } from '../model/simpleSchoolTypeDTO';
import { SimpleSubTopicDTO } from '../model/simpleSubTopicDTO';
import { SimpleTopicSequenceDTO } from '../model/simpleTopicSequenceDTO';
import { SimpleTopicsAndSubtopicsDTO } from '../model/simpleTopicsAndSubtopicsDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MetaControllerService {

    protected basePath = 'https://api.skills4school.de/s4s-api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * create application meta change entry
     * only administrators are allowed to use this method
     * @param appMetaChangeDTO appMetaChangeDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAppChangeUsingPOST(appMetaChangeDTO: AppMetaChangeDTO, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public createAppChangeUsingPOST(appMetaChangeDTO: AppMetaChangeDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public createAppChangeUsingPOST(appMetaChangeDTO: AppMetaChangeDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public createAppChangeUsingPOST(appMetaChangeDTO: AppMetaChangeDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appMetaChangeDTO === null || appMetaChangeDTO === undefined) {
            throw new Error('Required parameter appMetaChangeDTO was null or undefined when calling createAppChangeUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RestResponseMessage>(`${this.basePath}/meta/app/change/create`,
            appMetaChangeDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new school district
     * 
     * @param simpleSchoolDistrictDTO simpleSchoolDistrictDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSchoolDistrictUsingPOST(simpleSchoolDistrictDTO: SimpleSchoolDistrictDTO, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public createSchoolDistrictUsingPOST(simpleSchoolDistrictDTO: SimpleSchoolDistrictDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public createSchoolDistrictUsingPOST(simpleSchoolDistrictDTO: SimpleSchoolDistrictDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public createSchoolDistrictUsingPOST(simpleSchoolDistrictDTO: SimpleSchoolDistrictDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleSchoolDistrictDTO === null || simpleSchoolDistrictDTO === undefined) {
            throw new Error('Required parameter simpleSchoolDistrictDTO was null or undefined when calling createSchoolDistrictUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/meta/district/data/simple`,
            simpleSchoolDistrictDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new school grade
     * 
     * @param simpleSchoolGradeDTO simpleSchoolGradeDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSchoolGradeUsingPOST(simpleSchoolGradeDTO: SimpleSchoolGradeDTO, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public createSchoolGradeUsingPOST(simpleSchoolGradeDTO: SimpleSchoolGradeDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public createSchoolGradeUsingPOST(simpleSchoolGradeDTO: SimpleSchoolGradeDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public createSchoolGradeUsingPOST(simpleSchoolGradeDTO: SimpleSchoolGradeDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleSchoolGradeDTO === null || simpleSchoolGradeDTO === undefined) {
            throw new Error('Required parameter simpleSchoolGradeDTO was null or undefined when calling createSchoolGradeUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/meta/grade/data/simple`,
            simpleSchoolGradeDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new school subject
     * 
     * @param simpleSchoolSubjectDTO simpleSchoolSubjectDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSchoolSubjectUsingPOST(simpleSchoolSubjectDTO: SimpleSchoolSubjectDTO, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public createSchoolSubjectUsingPOST(simpleSchoolSubjectDTO: SimpleSchoolSubjectDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public createSchoolSubjectUsingPOST(simpleSchoolSubjectDTO: SimpleSchoolSubjectDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public createSchoolSubjectUsingPOST(simpleSchoolSubjectDTO: SimpleSchoolSubjectDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleSchoolSubjectDTO === null || simpleSchoolSubjectDTO === undefined) {
            throw new Error('Required parameter simpleSchoolSubjectDTO was null or undefined when calling createSchoolSubjectUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/meta/subject/data/simple`,
            simpleSchoolSubjectDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new school topic
     * 
     * @param simpleSchoolTopicDTO simpleSchoolTopicDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSchoolTopicUsingPOST(simpleSchoolTopicDTO: SimpleSchoolTopicDTO, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public createSchoolTopicUsingPOST(simpleSchoolTopicDTO: SimpleSchoolTopicDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public createSchoolTopicUsingPOST(simpleSchoolTopicDTO: SimpleSchoolTopicDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public createSchoolTopicUsingPOST(simpleSchoolTopicDTO: SimpleSchoolTopicDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleSchoolTopicDTO === null || simpleSchoolTopicDTO === undefined) {
            throw new Error('Required parameter simpleSchoolTopicDTO was null or undefined when calling createSchoolTopicUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/meta/topic/data/simple/`,
            simpleSchoolTopicDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new school type
     * 
     * @param simpleSchoolTypeDTO simpleSchoolTypeDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSchoolTypeUsingPOST(simpleSchoolTypeDTO: SimpleSchoolTypeDTO, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public createSchoolTypeUsingPOST(simpleSchoolTypeDTO: SimpleSchoolTypeDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public createSchoolTypeUsingPOST(simpleSchoolTypeDTO: SimpleSchoolTypeDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public createSchoolTypeUsingPOST(simpleSchoolTypeDTO: SimpleSchoolTypeDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleSchoolTypeDTO === null || simpleSchoolTypeDTO === undefined) {
            throw new Error('Required parameter simpleSchoolTypeDTO was null or undefined when calling createSchoolTypeUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/meta/schooltype/data/simple`,
            simpleSchoolTypeDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new sub topic
     * 
     * @param simpleSubTopicDTO simpleSubTopicDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSubTopicUsingPOST(simpleSubTopicDTO: SimpleSubTopicDTO, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public createSubTopicUsingPOST(simpleSubTopicDTO: SimpleSubTopicDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public createSubTopicUsingPOST(simpleSubTopicDTO: SimpleSubTopicDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public createSubTopicUsingPOST(simpleSubTopicDTO: SimpleSubTopicDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleSubTopicDTO === null || simpleSubTopicDTO === undefined) {
            throw new Error('Required parameter simpleSubTopicDTO was null or undefined when calling createSubTopicUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/meta/sub-topic/data/simple/`,
            simpleSubTopicDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a school district
     * 
     * @param schoolDistrictId schoolDistrictId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSchoolDistrictUsingDELETE(schoolDistrictId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteSchoolDistrictUsingDELETE(schoolDistrictId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteSchoolDistrictUsingDELETE(schoolDistrictId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteSchoolDistrictUsingDELETE(schoolDistrictId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schoolDistrictId === null || schoolDistrictId === undefined) {
            throw new Error('Required parameter schoolDistrictId was null or undefined when calling deleteSchoolDistrictUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/meta/district/id/${encodeURIComponent(String(schoolDistrictId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a school grade
     * 
     * @param schoolGradeId schoolGradeId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSchoolGradeUsingDELETE(schoolGradeId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteSchoolGradeUsingDELETE(schoolGradeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteSchoolGradeUsingDELETE(schoolGradeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteSchoolGradeUsingDELETE(schoolGradeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schoolGradeId === null || schoolGradeId === undefined) {
            throw new Error('Required parameter schoolGradeId was null or undefined when calling deleteSchoolGradeUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/meta/grade/id/${encodeURIComponent(String(schoolGradeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a school subject
     * 
     * @param schoolSubjectId schoolSubjectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSchoolSubjectUsingDELETE(schoolSubjectId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteSchoolSubjectUsingDELETE(schoolSubjectId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteSchoolSubjectUsingDELETE(schoolSubjectId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteSchoolSubjectUsingDELETE(schoolSubjectId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schoolSubjectId === null || schoolSubjectId === undefined) {
            throw new Error('Required parameter schoolSubjectId was null or undefined when calling deleteSchoolSubjectUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/meta/subject/id/${encodeURIComponent(String(schoolSubjectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a school topic
     * 
     * @param schoolTopicId schoolTopicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSchoolTopicUsingDELETE(schoolTopicId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public deleteSchoolTopicUsingDELETE(schoolTopicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public deleteSchoolTopicUsingDELETE(schoolTopicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public deleteSchoolTopicUsingDELETE(schoolTopicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schoolTopicId === null || schoolTopicId === undefined) {
            throw new Error('Required parameter schoolTopicId was null or undefined when calling deleteSchoolTopicUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ResponseEntity>(`${this.basePath}/meta/topic/id/${encodeURIComponent(String(schoolTopicId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a school schooltype
     * 
     * @param schoolTypeId schoolTypeId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSchoolTypeUsingDELETE(schoolTypeId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteSchoolTypeUsingDELETE(schoolTypeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteSchoolTypeUsingDELETE(schoolTypeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteSchoolTypeUsingDELETE(schoolTypeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schoolTypeId === null || schoolTypeId === undefined) {
            throw new Error('Required parameter schoolTypeId was null or undefined when calling deleteSchoolTypeUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/meta/schooltype/id/${encodeURIComponent(String(schoolTypeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a sub topic
     * 
     * @param subtopicId subtopicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSubTopicUsingDELETE(subtopicId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteSubTopicUsingDELETE(subtopicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteSubTopicUsingDELETE(subtopicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteSubTopicUsingDELETE(subtopicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (subtopicId === null || subtopicId === undefined) {
            throw new Error('Required parameter subtopicId was null or undefined when calling deleteSubTopicUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/meta/sub-topic/id/${encodeURIComponent(String(subtopicId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get active school topics and sub topics filtered by name, school type and district. Deprecated: use instead /meta/topic/data/simple/filtered
     * 
     * @param name name
     * @param districtId districtId
     * @param schoolTypeId schoolTypeId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveTopicsByFilterUsingGET(name: string, districtId?: number, schoolTypeId?: number, observe?: 'body', reportProgress?: boolean): Observable<SimpleTopicsAndSubtopicsDTO>;
    public getActiveTopicsByFilterUsingGET(name: string, districtId?: number, schoolTypeId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleTopicsAndSubtopicsDTO>>;
    public getActiveTopicsByFilterUsingGET(name: string, districtId?: number, schoolTypeId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleTopicsAndSubtopicsDTO>>;
    public getActiveTopicsByFilterUsingGET(name: string, districtId?: number, schoolTypeId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling getActiveTopicsByFilterUsingGET.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (districtId !== undefined && districtId !== null) {
            queryParameters = queryParameters.set('districtId', <any>districtId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (schoolTypeId !== undefined && schoolTypeId !== null) {
            queryParameters = queryParameters.set('schoolTypeId', <any>schoolTypeId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleTopicsAndSubtopicsDTO>(`${this.basePath}/meta/topic/data/simple/filter/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all districts
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllDistrictsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleSchoolDistrictDTO>>;
    public getAllDistrictsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleSchoolDistrictDTO>>>;
    public getAllDistrictsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleSchoolDistrictDTO>>>;
    public getAllDistrictsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleSchoolDistrictDTO>>(`${this.basePath}/meta/district/data/simple/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all onboarding entries
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllOnboardingEntriesUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<OnboardingDTO>>;
    public getAllOnboardingEntriesUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OnboardingDTO>>>;
    public getAllOnboardingEntriesUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OnboardingDTO>>>;
    public getAllOnboardingEntriesUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<OnboardingDTO>>(`${this.basePath}/meta/onboarding/data/simple/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all school grades by school subject
     * 
     * @param schoolSubjectId schoolSubjectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSchoolGradesBySubjectAndActiveUsingGET(schoolSubjectId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleSchoolGradeDTO>>;
    public getAllSchoolGradesBySubjectAndActiveUsingGET(schoolSubjectId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleSchoolGradeDTO>>>;
    public getAllSchoolGradesBySubjectAndActiveUsingGET(schoolSubjectId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleSchoolGradeDTO>>>;
    public getAllSchoolGradesBySubjectAndActiveUsingGET(schoolSubjectId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schoolSubjectId === null || schoolSubjectId === undefined) {
            throw new Error('Required parameter schoolSubjectId was null or undefined when calling getAllSchoolGradesBySubjectAndActiveUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleSchoolGradeDTO>>(`${this.basePath}/meta/grade/data/simple/all/by/subject/${encodeURIComponent(String(schoolSubjectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all school grades
     * 
     * @param gradesWithoutQuestions gradesWithoutQuestions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSchoolGradesUsingGET(gradesWithoutQuestions?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleSchoolGradeDTO>>;
    public getAllSchoolGradesUsingGET(gradesWithoutQuestions?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleSchoolGradeDTO>>>;
    public getAllSchoolGradesUsingGET(gradesWithoutQuestions?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleSchoolGradeDTO>>>;
    public getAllSchoolGradesUsingGET(gradesWithoutQuestions?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gradesWithoutQuestions !== undefined && gradesWithoutQuestions !== null) {
            queryParameters = queryParameters.set('gradesWithoutQuestions', <any>gradesWithoutQuestions);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleSchoolGradeDTO>>(`${this.basePath}/meta/grade/data/simple/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all subjects
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSchoolSubjectsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleSchoolSubjectDTO>>;
    public getAllSchoolSubjectsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleSchoolSubjectDTO>>>;
    public getAllSchoolSubjectsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleSchoolSubjectDTO>>>;
    public getAllSchoolSubjectsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleSchoolSubjectDTO>>(`${this.basePath}/meta/subject/data/simple/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the train stats for the current user on all his school topics
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSchoolTopicTrainStatsForUserUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SchoolTopicTrainStatsDTO>>;
    public getAllSchoolTopicTrainStatsForUserUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SchoolTopicTrainStatsDTO>>>;
    public getAllSchoolTopicTrainStatsForUserUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SchoolTopicTrainStatsDTO>>>;
    public getAllSchoolTopicTrainStatsForUserUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SchoolTopicTrainStatsDTO>>(`${this.basePath}/meta/topic/data/simple/get/stats/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all school topics
     * 
     * @param gradeId gradeId
     * @param subjectId subjectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSchoolTopicsUsingGET(gradeId?: Array<number>, subjectId?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleSchoolTopicDTO>>;
    public getAllSchoolTopicsUsingGET(gradeId?: Array<number>, subjectId?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleSchoolTopicDTO>>>;
    public getAllSchoolTopicsUsingGET(gradeId?: Array<number>, subjectId?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleSchoolTopicDTO>>>;
    public getAllSchoolTopicsUsingGET(gradeId?: Array<number>, subjectId?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gradeId) {
            gradeId.forEach((element) => {
                queryParameters = queryParameters.append('gradeId', <any>element);
            })
        }
        if (subjectId) {
            subjectId.forEach((element) => {
                queryParameters = queryParameters.append('subjectId', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleSchoolTopicDTO>>(`${this.basePath}/meta/topic/data/simple/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all school topics with active or both active and inactive questions
     * 
     * @param activeOnly activeOnly
     * @param gradeId gradeId
     * @param subjectId subjectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSchoolTopicsWithQuestionsUsingGET(activeOnly: boolean, gradeId?: Array<number>, subjectId?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleSchoolTopicDTO>>;
    public getAllSchoolTopicsWithQuestionsUsingGET(activeOnly: boolean, gradeId?: Array<number>, subjectId?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleSchoolTopicDTO>>>;
    public getAllSchoolTopicsWithQuestionsUsingGET(activeOnly: boolean, gradeId?: Array<number>, subjectId?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleSchoolTopicDTO>>>;
    public getAllSchoolTopicsWithQuestionsUsingGET(activeOnly: boolean, gradeId?: Array<number>, subjectId?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getAllSchoolTopicsWithQuestionsUsingGET.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gradeId) {
            gradeId.forEach((element) => {
                queryParameters = queryParameters.append('gradeId', <any>element);
            })
        }
        if (subjectId) {
            subjectId.forEach((element) => {
                queryParameters = queryParameters.append('subjectId', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleSchoolTopicDTO>>(`${this.basePath}/meta/topic/data/simple/all-with-questions/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all school topics with active questions
     * 
     * @param gradeId gradeId
     * @param subjectId subjectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSchoolTopicsWithQuestionsUsingGET1(gradeId?: Array<number>, subjectId?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleSchoolTopicDTO>>;
    public getAllSchoolTopicsWithQuestionsUsingGET1(gradeId?: Array<number>, subjectId?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleSchoolTopicDTO>>>;
    public getAllSchoolTopicsWithQuestionsUsingGET1(gradeId?: Array<number>, subjectId?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleSchoolTopicDTO>>>;
    public getAllSchoolTopicsWithQuestionsUsingGET1(gradeId?: Array<number>, subjectId?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gradeId) {
            gradeId.forEach((element) => {
                queryParameters = queryParameters.append('gradeId', <any>element);
            })
        }
        if (subjectId) {
            subjectId.forEach((element) => {
                queryParameters = queryParameters.append('subjectId', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleSchoolTopicDTO>>(`${this.basePath}/meta/topic/data/simple/all-with-questions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all school types
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSchoolTypesUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleSchoolTypeDTO>>;
    public getAllSchoolTypesUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleSchoolTypeDTO>>>;
    public getAllSchoolTypesUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleSchoolTypeDTO>>>;
    public getAllSchoolTypesUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleSchoolTypeDTO>>(`${this.basePath}/meta/schooltype/data/simple/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all sub topics
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSubTopicsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleSubTopicDTO>>;
    public getAllSubTopicsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleSubTopicDTO>>>;
    public getAllSubTopicsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleSubTopicDTO>>>;
    public getAllSubTopicsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleSubTopicDTO>>(`${this.basePath}/meta/sub-topic/data/simple/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get changes for a requested application and version
     * 
     * @param appCode appCode
     * @param version version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppChangesUsingGET(appCode: string, version: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getAppChangesUsingGET(appCode: string, version: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getAppChangesUsingGET(appCode: string, version: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getAppChangesUsingGET(appCode: string, version: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appCode === null || appCode === undefined) {
            throw new Error('Required parameter appCode was null or undefined when calling getAppChangesUsingGET.');
        }

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling getAppChangesUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (appCode !== undefined && appCode !== null) {
            queryParameters = queryParameters.set('appCode', <any>appCode);
        }
        if (version !== undefined && version !== null) {
            queryParameters = queryParameters.set('version', <any>version);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/meta/app/changes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get version for a specific app
     * 
     * @param applicationType applicationType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppVersionUsingGET(applicationType: string, observe?: 'body', reportProgress?: boolean): Observable<GenericDataMessage>;
    public getAppVersionUsingGET(applicationType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericDataMessage>>;
    public getAppVersionUsingGET(applicationType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericDataMessage>>;
    public getAppVersionUsingGET(applicationType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationType === null || applicationType === undefined) {
            throw new Error('Required parameter applicationType was null or undefined when calling getAppVersionUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GenericDataMessage>(`${this.basePath}/meta/app/version/${encodeURIComponent(String(applicationType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get environment value
     * method can be only used by administrators
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEnvironmentUsingGET(observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getEnvironmentUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getEnvironmentUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getEnvironmentUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/meta/app/get/environment`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request the count of active or both active and inactive questions for multiple topics
     * 
     * @param activeOnly activeOnly
     * @param schoolTopicIds schoolTopicIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionCountForTopicsUsingGET(activeOnly: boolean, schoolTopicIds: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: number; }>;
    public getQuestionCountForTopicsUsingGET(activeOnly: boolean, schoolTopicIds: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: number; }>>;
    public getQuestionCountForTopicsUsingGET(activeOnly: boolean, schoolTopicIds: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: number; }>>;
    public getQuestionCountForTopicsUsingGET(activeOnly: boolean, schoolTopicIds: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getQuestionCountForTopicsUsingGET.');
        }

        if (schoolTopicIds === null || schoolTopicIds === undefined) {
            throw new Error('Required parameter schoolTopicIds was null or undefined when calling getQuestionCountForTopicsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (schoolTopicIds) {
            schoolTopicIds.forEach((element) => {
                queryParameters = queryParameters.append('schoolTopicIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: number; }>(`${this.basePath}/meta/topic/exec/question-count-map/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request the count of active questions for multiple topics
     * 
     * @param schoolTopicIds schoolTopicIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionCountForTopicsUsingGET1(schoolTopicIds: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: number; }>;
    public getQuestionCountForTopicsUsingGET1(schoolTopicIds: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: number; }>>;
    public getQuestionCountForTopicsUsingGET1(schoolTopicIds: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: number; }>>;
    public getQuestionCountForTopicsUsingGET1(schoolTopicIds: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schoolTopicIds === null || schoolTopicIds === undefined) {
            throw new Error('Required parameter schoolTopicIds was null or undefined when calling getQuestionCountForTopicsUsingGET1.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (schoolTopicIds) {
            schoolTopicIds.forEach((element) => {
                queryParameters = queryParameters.append('schoolTopicIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: number; }>(`${this.basePath}/meta/topic/exec/question-count-map`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request the ids of active or both active and inactive questions for multiple topics
     * 
     * @param activeOnly activeOnly
     * @param schoolTopicIds schoolTopicIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionIdsForTopicsUsingGET(activeOnly: boolean, schoolTopicIds: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: Array<number>; }>;
    public getQuestionIdsForTopicsUsingGET(activeOnly: boolean, schoolTopicIds: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: Array<number>; }>>;
    public getQuestionIdsForTopicsUsingGET(activeOnly: boolean, schoolTopicIds: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: Array<number>; }>>;
    public getQuestionIdsForTopicsUsingGET(activeOnly: boolean, schoolTopicIds: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getQuestionIdsForTopicsUsingGET.');
        }

        if (schoolTopicIds === null || schoolTopicIds === undefined) {
            throw new Error('Required parameter schoolTopicIds was null or undefined when calling getQuestionIdsForTopicsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (schoolTopicIds) {
            schoolTopicIds.forEach((element) => {
                queryParameters = queryParameters.append('schoolTopicIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: Array<number>; }>(`${this.basePath}/meta/topic/exec/question-id-map/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request the ids of active questions for multiple topics
     * 
     * @param schoolTopicIds schoolTopicIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionIdsForTopicsUsingGET1(schoolTopicIds: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: Array<number>; }>;
    public getQuestionIdsForTopicsUsingGET1(schoolTopicIds: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: Array<number>; }>>;
    public getQuestionIdsForTopicsUsingGET1(schoolTopicIds: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: Array<number>; }>>;
    public getQuestionIdsForTopicsUsingGET1(schoolTopicIds: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schoolTopicIds === null || schoolTopicIds === undefined) {
            throw new Error('Required parameter schoolTopicIds was null or undefined when calling getQuestionIdsForTopicsUsingGET1.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (schoolTopicIds) {
            schoolTopicIds.forEach((element) => {
                queryParameters = queryParameters.append('schoolTopicIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: Array<number>; }>(`${this.basePath}/meta/topic/exec/question-id-map`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a school topic
     * 
     * @param schoolTopicId schoolTopicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSchoolTopicByIdUsingGET(schoolTopicId: number, observe?: 'body', reportProgress?: boolean): Observable<SimpleSchoolTopicDTO>;
    public getSchoolTopicByIdUsingGET(schoolTopicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleSchoolTopicDTO>>;
    public getSchoolTopicByIdUsingGET(schoolTopicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleSchoolTopicDTO>>;
    public getSchoolTopicByIdUsingGET(schoolTopicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schoolTopicId === null || schoolTopicId === undefined) {
            throw new Error('Required parameter schoolTopicId was null or undefined when calling getSchoolTopicByIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleSchoolTopicDTO>(`${this.basePath}/meta/topic/data/simple/single/${encodeURIComponent(String(schoolTopicId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get school topics and sub topics by filter
     * 
     * @param topicFilterDTO topicFilterDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSchoolTopicsByFilterUsingGET(topicFilterDTO: SchoolTopicFilterDTO, observe?: 'body', reportProgress?: boolean): Observable<SimpleTopicsAndSubtopicsDTO>;
    public getSchoolTopicsByFilterUsingGET(topicFilterDTO: SchoolTopicFilterDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleTopicsAndSubtopicsDTO>>;
    public getSchoolTopicsByFilterUsingGET(topicFilterDTO: SchoolTopicFilterDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleTopicsAndSubtopicsDTO>>;
    public getSchoolTopicsByFilterUsingGET(topicFilterDTO: SchoolTopicFilterDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (topicFilterDTO === null || topicFilterDTO === undefined) {
            throw new Error('Required parameter topicFilterDTO was null or undefined when calling getSchoolTopicsByFilterUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<SimpleTopicsAndSubtopicsDTO>(`${this.basePath}/meta/topic/data/simple/filtered`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get school topics with learning progress details by filter
     * 
     * @param getSchoolTopicsWithLearningProgressDetailsByFilterDto getSchoolTopicsWithLearningProgressDetailsByFilterDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSchoolTopicsWithLearningProgressDetailsByFilterUsingPOST(getSchoolTopicsWithLearningProgressDetailsByFilterDto: DTOToGetSchoolTopicsWithLearningProgressDetailsByFilter, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getSchoolTopicsWithLearningProgressDetailsByFilterUsingPOST(getSchoolTopicsWithLearningProgressDetailsByFilterDto: DTOToGetSchoolTopicsWithLearningProgressDetailsByFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getSchoolTopicsWithLearningProgressDetailsByFilterUsingPOST(getSchoolTopicsWithLearningProgressDetailsByFilterDto: DTOToGetSchoolTopicsWithLearningProgressDetailsByFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getSchoolTopicsWithLearningProgressDetailsByFilterUsingPOST(getSchoolTopicsWithLearningProgressDetailsByFilterDto: DTOToGetSchoolTopicsWithLearningProgressDetailsByFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (getSchoolTopicsWithLearningProgressDetailsByFilterDto === null || getSchoolTopicsWithLearningProgressDetailsByFilterDto === undefined) {
            throw new Error('Required parameter getSchoolTopicsWithLearningProgressDetailsByFilterDto was null or undefined when calling getSchoolTopicsWithLearningProgressDetailsByFilterUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RestResponseMessage>(`${this.basePath}/meta/get/school-topics/with/learning-progress-details/by/filter`,
            getSchoolTopicsWithLearningProgressDetailsByFilterDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get school topics with learning progress details by subject ID and school grade ID
     * 
     * @param schoolGradeId schoolGradeId
     * @param schoolSubjectId schoolSubjectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSchoolTopicsWithLearningProgressDetailsBySchoolSubjectIdAndSchoolGradeIdUsingGET(schoolGradeId: number, schoolSubjectId: number, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getSchoolTopicsWithLearningProgressDetailsBySchoolSubjectIdAndSchoolGradeIdUsingGET(schoolGradeId: number, schoolSubjectId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getSchoolTopicsWithLearningProgressDetailsBySchoolSubjectIdAndSchoolGradeIdUsingGET(schoolGradeId: number, schoolSubjectId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getSchoolTopicsWithLearningProgressDetailsBySchoolSubjectIdAndSchoolGradeIdUsingGET(schoolGradeId: number, schoolSubjectId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schoolGradeId === null || schoolGradeId === undefined) {
            throw new Error('Required parameter schoolGradeId was null or undefined when calling getSchoolTopicsWithLearningProgressDetailsBySchoolSubjectIdAndSchoolGradeIdUsingGET.');
        }

        if (schoolSubjectId === null || schoolSubjectId === undefined) {
            throw new Error('Required parameter schoolSubjectId was null or undefined when calling getSchoolTopicsWithLearningProgressDetailsBySchoolSubjectIdAndSchoolGradeIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/meta/get/school-topics/with/learning-progress-details/by/school-subject/id/${encodeURIComponent(String(schoolSubjectId))}/and/school-grade/id/${encodeURIComponent(String(schoolGradeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a single onboarding entry by ID
     * 
     * @param onboardingId onboardingId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSingleOnboardingEntryUsingGET(onboardingId: number, observe?: 'body', reportProgress?: boolean): Observable<OnboardingDTO>;
    public getSingleOnboardingEntryUsingGET(onboardingId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OnboardingDTO>>;
    public getSingleOnboardingEntryUsingGET(onboardingId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OnboardingDTO>>;
    public getSingleOnboardingEntryUsingGET(onboardingId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onboardingId === null || onboardingId === undefined) {
            throw new Error('Required parameter onboardingId was null or undefined when calling getSingleOnboardingEntryUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OnboardingDTO>(`${this.basePath}/meta/onboarding/data/simple/id/${encodeURIComponent(String(onboardingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a single school subject by ID
     * 
     * @param schoolSubjectId schoolSubjectId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSingleSchoolSubjectUsingGET(schoolSubjectId: number, observe?: 'body', reportProgress?: boolean): Observable<SimpleSchoolSubjectDTO>;
    public getSingleSchoolSubjectUsingGET(schoolSubjectId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleSchoolSubjectDTO>>;
    public getSingleSchoolSubjectUsingGET(schoolSubjectId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleSchoolSubjectDTO>>;
    public getSingleSchoolSubjectUsingGET(schoolSubjectId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schoolSubjectId === null || schoolSubjectId === undefined) {
            throw new Error('Required parameter schoolSubjectId was null or undefined when calling getSingleSchoolSubjectUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleSchoolSubjectDTO>(`${this.basePath}/meta/subject/data/simple/single/${encodeURIComponent(String(schoolSubjectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the train stats for the current user on a specific school topic
     * 
     * @param schoolTopicId schoolTopicId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSingleSchoolTopicTrainStatsForUserUsingGET(schoolTopicId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SchoolTopicTrainStatsDTO>;
    public getSingleSchoolTopicTrainStatsForUserUsingGET(schoolTopicId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SchoolTopicTrainStatsDTO>>;
    public getSingleSchoolTopicTrainStatsForUserUsingGET(schoolTopicId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SchoolTopicTrainStatsDTO>>;
    public getSingleSchoolTopicTrainStatsForUserUsingGET(schoolTopicId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schoolTopicId === null || schoolTopicId === undefined) {
            throw new Error('Required parameter schoolTopicId was null or undefined when calling getSingleSchoolTopicTrainStatsForUserUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SchoolTopicTrainStatsDTO>(`${this.basePath}/meta/topic/data/simple/id/${encodeURIComponent(String(schoolTopicId))}/get/stats/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get sub topics by parent topic id
     * 
     * @param parentTopicId parentTopicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubTopicsByTopicIdUsingGET(parentTopicId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleSubTopicDTO>>;
    public getSubTopicsByTopicIdUsingGET(parentTopicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleSubTopicDTO>>>;
    public getSubTopicsByTopicIdUsingGET(parentTopicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleSubTopicDTO>>>;
    public getSubTopicsByTopicIdUsingGET(parentTopicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parentTopicId === null || parentTopicId === undefined) {
            throw new Error('Required parameter parentTopicId was null or undefined when calling getSubTopicsByTopicIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleSubTopicDTO>>(`${this.basePath}/meta/sub-topic/data/simple/${encodeURIComponent(String(parentTopicId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set school topic sequences for a specific school topic
     * 
     * @param schoolTopicId schoolTopicId
     * @param simpleTopicSequenceDTO simpleTopicSequenceDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setSchoolTopicSequencesUsingPOST(schoolTopicId: number, simpleTopicSequenceDTO: SimpleTopicSequenceDTO, observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public setSchoolTopicSequencesUsingPOST(schoolTopicId: number, simpleTopicSequenceDTO: SimpleTopicSequenceDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public setSchoolTopicSequencesUsingPOST(schoolTopicId: number, simpleTopicSequenceDTO: SimpleTopicSequenceDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public setSchoolTopicSequencesUsingPOST(schoolTopicId: number, simpleTopicSequenceDTO: SimpleTopicSequenceDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schoolTopicId === null || schoolTopicId === undefined) {
            throw new Error('Required parameter schoolTopicId was null or undefined when calling setSchoolTopicSequencesUsingPOST.');
        }

        if (simpleTopicSequenceDTO === null || simpleTopicSequenceDTO === undefined) {
            throw new Error('Required parameter simpleTopicSequenceDTO was null or undefined when calling setSchoolTopicSequencesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericMessage>(`${this.basePath}/meta/topic/data/simple/id/${encodeURIComponent(String(schoolTopicId))}/set/sequences/`,
            simpleTopicSequenceDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a school district
     * 
     * @param simpleSchoolDistrictDTO simpleSchoolDistrictDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSchoolDistrictUsingPUT(simpleSchoolDistrictDTO: SimpleSchoolDistrictDTO, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public updateSchoolDistrictUsingPUT(simpleSchoolDistrictDTO: SimpleSchoolDistrictDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public updateSchoolDistrictUsingPUT(simpleSchoolDistrictDTO: SimpleSchoolDistrictDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public updateSchoolDistrictUsingPUT(simpleSchoolDistrictDTO: SimpleSchoolDistrictDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleSchoolDistrictDTO === null || simpleSchoolDistrictDTO === undefined) {
            throw new Error('Required parameter simpleSchoolDistrictDTO was null or undefined when calling updateSchoolDistrictUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<number>(`${this.basePath}/meta/district/data/simple/`,
            simpleSchoolDistrictDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a school grade
     * 
     * @param simpleSchoolGradeDTO simpleSchoolGradeDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSchoolGradeUsingPUT(simpleSchoolGradeDTO: SimpleSchoolGradeDTO, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public updateSchoolGradeUsingPUT(simpleSchoolGradeDTO: SimpleSchoolGradeDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public updateSchoolGradeUsingPUT(simpleSchoolGradeDTO: SimpleSchoolGradeDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public updateSchoolGradeUsingPUT(simpleSchoolGradeDTO: SimpleSchoolGradeDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleSchoolGradeDTO === null || simpleSchoolGradeDTO === undefined) {
            throw new Error('Required parameter simpleSchoolGradeDTO was null or undefined when calling updateSchoolGradeUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<number>(`${this.basePath}/meta/grade/data/simple/`,
            simpleSchoolGradeDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a school subject
     * 
     * @param simpleSchoolSubjectDTO simpleSchoolSubjectDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSchoolSubjectUsingPUT(simpleSchoolSubjectDTO: SimpleSchoolSubjectDTO, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public updateSchoolSubjectUsingPUT(simpleSchoolSubjectDTO: SimpleSchoolSubjectDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public updateSchoolSubjectUsingPUT(simpleSchoolSubjectDTO: SimpleSchoolSubjectDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public updateSchoolSubjectUsingPUT(simpleSchoolSubjectDTO: SimpleSchoolSubjectDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleSchoolSubjectDTO === null || simpleSchoolSubjectDTO === undefined) {
            throw new Error('Required parameter simpleSchoolSubjectDTO was null or undefined when calling updateSchoolSubjectUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<number>(`${this.basePath}/meta/subject/data/simple/`,
            simpleSchoolSubjectDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a school topic
     * 
     * @param simpleSchoolTopicDTO simpleSchoolTopicDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSchoolTopicUsingPUT(simpleSchoolTopicDTO: SimpleSchoolTopicDTO, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public updateSchoolTopicUsingPUT(simpleSchoolTopicDTO: SimpleSchoolTopicDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public updateSchoolTopicUsingPUT(simpleSchoolTopicDTO: SimpleSchoolTopicDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public updateSchoolTopicUsingPUT(simpleSchoolTopicDTO: SimpleSchoolTopicDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleSchoolTopicDTO === null || simpleSchoolTopicDTO === undefined) {
            throw new Error('Required parameter simpleSchoolTopicDTO was null or undefined when calling updateSchoolTopicUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<number>(`${this.basePath}/meta/topic/data/simple/`,
            simpleSchoolTopicDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a school type
     * 
     * @param simpleSchoolTypeDTO simpleSchoolTypeDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSchoolTypeUsingPUT(simpleSchoolTypeDTO: SimpleSchoolTypeDTO, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public updateSchoolTypeUsingPUT(simpleSchoolTypeDTO: SimpleSchoolTypeDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public updateSchoolTypeUsingPUT(simpleSchoolTypeDTO: SimpleSchoolTypeDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public updateSchoolTypeUsingPUT(simpleSchoolTypeDTO: SimpleSchoolTypeDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleSchoolTypeDTO === null || simpleSchoolTypeDTO === undefined) {
            throw new Error('Required parameter simpleSchoolTypeDTO was null or undefined when calling updateSchoolTypeUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<number>(`${this.basePath}/meta/schooltype/data/simple/`,
            simpleSchoolTypeDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a sub topic
     * 
     * @param simpleSubTopicDTO simpleSubTopicDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSubTopicUsingPUT(simpleSubTopicDTO: SimpleSubTopicDTO, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public updateSubTopicUsingPUT(simpleSubTopicDTO: SimpleSubTopicDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public updateSubTopicUsingPUT(simpleSubTopicDTO: SimpleSubTopicDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public updateSubTopicUsingPUT(simpleSubTopicDTO: SimpleSubTopicDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleSubTopicDTO === null || simpleSubTopicDTO === undefined) {
            throw new Error('Required parameter simpleSubTopicDTO was null or undefined when calling updateSubTopicUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<number>(`${this.basePath}/meta/sub-topic/data/simple/`,
            simpleSubTopicDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
