/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { CodeDTO } from '../model/codeDTO';
import { CodeGenerationDTO } from '../model/codeGenerationDTO';
import { GenericMessage } from '../model/genericMessage';
import { PaypalSubscriptionPurchaseDTO } from '../model/paypalSubscriptionPurchaseDTO';
import { PurchasableDTO } from '../model/purchasableDTO';
import { PurchaseDTO } from '../model/purchaseDTO';
import { ResponseEntity } from '../model/responseEntity';
import { RestResponseMessage } from '../model/restResponseMessage';
import { SubscriptionDTO } from '../model/subscriptionDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PaymentControllerService {

    protected basePath = 'https://api.skills4school.de/s4s-api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Generate coupon codes
     * 
     * @param codeGenerationDTO codeGenerationDTO
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateCodesUsingPOST(codeGenerationDTO: CodeGenerationDTO, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CodeDTO>>;
    public generateCodesUsingPOST(codeGenerationDTO: CodeGenerationDTO, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CodeDTO>>>;
    public generateCodesUsingPOST(codeGenerationDTO: CodeGenerationDTO, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CodeDTO>>>;
    public generateCodesUsingPOST(codeGenerationDTO: CodeGenerationDTO, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (codeGenerationDTO === null || codeGenerationDTO === undefined) {
            throw new Error('Required parameter codeGenerationDTO was null or undefined when calling generateCodesUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<CodeDTO>>(`${this.basePath}/payment/generateCouponCodes`,
            codeGenerationDTO,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Pay-what-you-want purchasable DTO
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPayWhatYouWantPurchasableUsingGET(observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getPayWhatYouWantPurchasableUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getPayWhatYouWantPurchasableUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getPayWhatYouWantPurchasableUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/payment/purchasable/donation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get PayPal purchasable DTO list for subscriptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPaypalSubscriptionPurchasableListUsingGET(observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getPaypalSubscriptionPurchasableListUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getPaypalSubscriptionPurchasableListUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getPaypalSubscriptionPurchasableListUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/payment/purchasable/data/list/paypal/subscriptions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all purchasables
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPurchasableListUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<PurchasableDTO>>;
    public getPurchasableListUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PurchasableDTO>>>;
    public getPurchasableListUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PurchasableDTO>>>;
    public getPurchasableListUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PurchasableDTO>>(`${this.basePath}/payment/purchasable/data/list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * External purchase (e.g. Sales Funnel)
     * 
     * @param xExternalKey X-ExternalKey
     * @param subscriptionDTO subscriptionDTO
     * @param userName userName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public purchaseExternalUsingPOST(xExternalKey: string, subscriptionDTO: SubscriptionDTO, userName: string, observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public purchaseExternalUsingPOST(xExternalKey: string, subscriptionDTO: SubscriptionDTO, userName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public purchaseExternalUsingPOST(xExternalKey: string, subscriptionDTO: SubscriptionDTO, userName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public purchaseExternalUsingPOST(xExternalKey: string, subscriptionDTO: SubscriptionDTO, userName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xExternalKey === null || xExternalKey === undefined) {
            throw new Error('Required parameter xExternalKey was null or undefined when calling purchaseExternalUsingPOST.');
        }

        if (subscriptionDTO === null || subscriptionDTO === undefined) {
            throw new Error('Required parameter subscriptionDTO was null or undefined when calling purchaseExternalUsingPOST.');
        }

        if (userName === null || userName === undefined) {
            throw new Error('Required parameter userName was null or undefined when calling purchaseExternalUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userName !== undefined && userName !== null) {
            queryParameters = queryParameters.set('userName', <any>userName);
        }

        let headers = this.defaultHeaders;
        if (xExternalKey !== undefined && xExternalKey !== null) {
            headers = headers.set('X-ExternalKey', String(xExternalKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericMessage>(`${this.basePath}/payment/purchaseExternal`,
            subscriptionDTO,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * External purchase with Paypal via Sales Funnel
     * currently this method could not throw exception due to legacy support for other methods
     * @param xExternalKey X-ExternalKey
     * @param paypalSubscriptionPurchaseDTO paypalSubscriptionPurchaseDTO
     * @param username username
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public purchaseSubscriptionViaFunnelWithPaypalUsingPOST(xExternalKey: string, paypalSubscriptionPurchaseDTO: PaypalSubscriptionPurchaseDTO, username: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public purchaseSubscriptionViaFunnelWithPaypalUsingPOST(xExternalKey: string, paypalSubscriptionPurchaseDTO: PaypalSubscriptionPurchaseDTO, username: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public purchaseSubscriptionViaFunnelWithPaypalUsingPOST(xExternalKey: string, paypalSubscriptionPurchaseDTO: PaypalSubscriptionPurchaseDTO, username: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public purchaseSubscriptionViaFunnelWithPaypalUsingPOST(xExternalKey: string, paypalSubscriptionPurchaseDTO: PaypalSubscriptionPurchaseDTO, username: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xExternalKey === null || xExternalKey === undefined) {
            throw new Error('Required parameter xExternalKey was null or undefined when calling purchaseSubscriptionViaFunnelWithPaypalUsingPOST.');
        }

        if (paypalSubscriptionPurchaseDTO === null || paypalSubscriptionPurchaseDTO === undefined) {
            throw new Error('Required parameter paypalSubscriptionPurchaseDTO was null or undefined when calling purchaseSubscriptionViaFunnelWithPaypalUsingPOST.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling purchaseSubscriptionViaFunnelWithPaypalUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (username !== undefined && username !== null) {
            queryParameters = queryParameters.set('username', <any>username);
        }

        let headers = this.defaultHeaders;
        if (xExternalKey !== undefined && xExternalKey !== null) {
            headers = headers.set('X-ExternalKey', String(xExternalKey));
        }

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RestResponseMessage>(`${this.basePath}/payment/purchase/subscription/via/funnel/with/paypal`,
            paypalSubscriptionPurchaseDTO,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Purchase request
     * 
     * @param purchaseDTO purchaseDTO
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public purchaseUsingPOST(purchaseDTO: PurchaseDTO, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public purchaseUsingPOST(purchaseDTO: PurchaseDTO, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public purchaseUsingPOST(purchaseDTO: PurchaseDTO, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public purchaseUsingPOST(purchaseDTO: PurchaseDTO, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (purchaseDTO === null || purchaseDTO === undefined) {
            throw new Error('Required parameter purchaseDTO was null or undefined when calling purchaseUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResponseEntity>(`${this.basePath}/payment/purchase`,
            purchaseDTO,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Activate a coupon code
     * 
     * @param code code
     * @param deviceId deviceId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public useCouponCodeUsingPOST(code: string, deviceId: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public useCouponCodeUsingPOST(code: string, deviceId: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public useCouponCodeUsingPOST(code: string, deviceId: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public useCouponCodeUsingPOST(code: string, deviceId: string, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling useCouponCodeUsingPOST.');
        }

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling useCouponCodeUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('deviceId', <any>deviceId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<GenericMessage>(`${this.basePath}/payment/activateCouponCode`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
