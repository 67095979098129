export * from './appQuestionController.service';
import { AppQuestionControllerService } from './appQuestionController.service';
export * from './campaignController.service';
import { CampaignControllerService } from './campaignController.service';
export * from './chatController.service';
import { ChatControllerService } from './chatController.service';
export * from './collectionController.service';
import { CollectionControllerService } from './collectionController.service';
export * from './editorController.service';
import { EditorControllerService } from './editorController.service';
export * from './editorQuestionController.service';
import { EditorQuestionControllerService } from './editorQuestionController.service';
export * from './exclusiveTeacherController.service';
import { ExclusiveTeacherControllerService } from './exclusiveTeacherController.service';
export * from './groupRoomController.service';
import { GroupRoomControllerService } from './groupRoomController.service';
export * from './groupRoomInvitationController.service';
import { GroupRoomInvitationControllerService } from './groupRoomInvitationController.service';
export * from './learningProgressController.service';
import { LearningProgressControllerService } from './learningProgressController.service';
export * from './metaController.service';
import { MetaControllerService } from './metaController.service';
export * from './paymentController.service';
import { PaymentControllerService } from './paymentController.service';
export * from './publisherController.service';
import { PublisherControllerService } from './publisherController.service';
export * from './reportingController.service';
import { ReportingControllerService } from './reportingController.service';
export * from './schoolController.service';
import { SchoolControllerService } from './schoolController.service';
export * from './schoolTopicController.service';
import { SchoolTopicControllerService } from './schoolTopicController.service';
export * from './subTopicController.service';
import { SubTopicControllerService } from './subTopicController.service';
export * from './userController.service';
import { UserControllerService } from './userController.service';
export * from './userProfileController.service';
import { UserProfileControllerService } from './userProfileController.service';
export * from './userViewController.service';
import { UserViewControllerService } from './userViewController.service';
export * from './validationController.service';
import { ValidationControllerService } from './validationController.service';
export * from './xmasSpecialController.service';
import { XmasSpecialControllerService } from './xmasSpecialController.service';
export const APIS = [AppQuestionControllerService, CampaignControllerService, ChatControllerService, CollectionControllerService, EditorControllerService, EditorQuestionControllerService, ExclusiveTeacherControllerService, GroupRoomControllerService, GroupRoomInvitationControllerService, LearningProgressControllerService, MetaControllerService, PaymentControllerService, PublisherControllerService, ReportingControllerService, SchoolControllerService, SchoolTopicControllerService, SubTopicControllerService, UserControllerService, UserProfileControllerService, UserViewControllerService, ValidationControllerService, XmasSpecialControllerService];
