/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { DuplicateQuestionDTO } from '../model/duplicateQuestionDTO';
import { PageOfSimpleQuestionDTO } from '../model/pageOfSimpleQuestionDTO';
import { QuestionDTO } from '../model/questionDTO';
import { QuestionsByFilterDTO } from '../model/questionsByFilterDTO';
import { QuestionsReportsFilterDto } from '../model/questionsReportsFilterDto';
import { ResponseEntity } from '../model/responseEntity';
import { RestResponseMessage } from '../model/restResponseMessage';
import { SimpleComplexQuestionDTO } from '../model/simpleComplexQuestionDTO';
import { SimpleMultipleChoiceQuestionDTO } from '../model/simpleMultipleChoiceQuestionDTO';
import { SimpleNumberInputQuestionDTO } from '../model/simpleNumberInputQuestionDTO';
import { SimpleQuestionDTO } from '../model/simpleQuestionDTO';
import { SimpleQuestionWithReportDTO } from '../model/simpleQuestionWithReportDTO';
import { SimpleTextInputQuestionDTO } from '../model/simpleTextInputQuestionDTO';
import { SimpleYesNoQuestionDTO } from '../model/simpleYesNoQuestionDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EditorQuestionControllerService {

    protected basePath = 'https://api.skills4school.de/s4s-api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Activate or deactivate a question
     * 
     * @param activeFlag activeFlag
     * @param questionId questionId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateQuestionUsingPUT(activeFlag: boolean, questionId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public activateQuestionUsingPUT(activeFlag: boolean, questionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public activateQuestionUsingPUT(activeFlag: boolean, questionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public activateQuestionUsingPUT(activeFlag: boolean, questionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeFlag === null || activeFlag === undefined) {
            throw new Error('Required parameter activeFlag was null or undefined when calling activateQuestionUsingPUT.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling activateQuestionUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<ResponseEntity>(`${this.basePath}/editor/question/exec/activate-or-deactivate/${encodeURIComponent(String(questionId))}/active-flag/${encodeURIComponent(String(activeFlag))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change the owner of a single question
     * 
     * @param questionId questionId
     * @param userId userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeQuestionOwnerSingleQuestionUsingPUT(questionId: number, userId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public changeQuestionOwnerSingleQuestionUsingPUT(questionId: number, userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public changeQuestionOwnerSingleQuestionUsingPUT(questionId: number, userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public changeQuestionOwnerSingleQuestionUsingPUT(questionId: number, userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling changeQuestionOwnerSingleQuestionUsingPUT.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling changeQuestionOwnerSingleQuestionUsingPUT.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (questionId !== undefined && questionId !== null) {
            queryParameters = queryParameters.set('questionId', <any>questionId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<ResponseEntity>(`${this.basePath}/editor/question/exec/change-owner-single`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change the owner of questions
     * 
     * @param questionIds questionIds
     * @param userId userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeQuestionOwnerUsingPUT(questionIds: Array<number>, userId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public changeQuestionOwnerUsingPUT(questionIds: Array<number>, userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public changeQuestionOwnerUsingPUT(questionIds: Array<number>, userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public changeQuestionOwnerUsingPUT(questionIds: Array<number>, userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionIds === null || questionIds === undefined) {
            throw new Error('Required parameter questionIds was null or undefined when calling changeQuestionOwnerUsingPUT.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling changeQuestionOwnerUsingPUT.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (questionIds) {
            questionIds.forEach((element) => {
                queryParameters = queryParameters.append('questionIds', <any>element);
            })
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<ResponseEntity>(`${this.basePath}/editor/question/exec/change-owner`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Confirm question correction and marks corresponding question report as solved. This end point is called after confirming the question correction.
     * 
     * @param questionReportId questionReportId
     * @param token token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmCorrectionUsingGET(questionReportId: number, token: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public confirmCorrectionUsingGET(questionReportId: number, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public confirmCorrectionUsingGET(questionReportId: number, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public confirmCorrectionUsingGET(questionReportId: number, token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionReportId === null || questionReportId === undefined) {
            throw new Error('Required parameter questionReportId was null or undefined when calling confirmCorrectionUsingGET.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling confirmCorrectionUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (questionReportId !== undefined && questionReportId !== null) {
            queryParameters = queryParameters.set('questionReportId', <any>questionReportId);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/editor/question/confirm/correction/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Question including Hints and Formulas
     * 
     * @param simpleQuestionDTO simpleQuestionDTO
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createQuestionFromSimpleDTOUsingPOST1(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public createQuestionFromSimpleDTOUsingPOST1(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public createQuestionFromSimpleDTOUsingPOST1(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public createQuestionFromSimpleDTOUsingPOST1(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleQuestionDTO === null || simpleQuestionDTO === undefined) {
            throw new Error('Required parameter simpleQuestionDTO was null or undefined when calling createQuestionFromSimpleDTOUsingPOST1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/editor/question/data/simple`,
            simpleQuestionDTO,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Declines the question correction and marks the corresponding question report as solved. This endpoint is called after the question correction has been declined.
     * 
     * @param questionReportId questionReportId
     * @param token token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public declineCorrectionUsingGET(questionReportId: number, token: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public declineCorrectionUsingGET(questionReportId: number, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public declineCorrectionUsingGET(questionReportId: number, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public declineCorrectionUsingGET(questionReportId: number, token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionReportId === null || questionReportId === undefined) {
            throw new Error('Required parameter questionReportId was null or undefined when calling declineCorrectionUsingGET.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling declineCorrectionUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (questionReportId !== undefined && questionReportId !== null) {
            queryParameters = queryParameters.set('questionReportId', <any>questionReportId);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/editor/question/decline/correction/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a Question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteQuestionByIdUsingDELETE1(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public deleteQuestionByIdUsingDELETE1(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public deleteQuestionByIdUsingDELETE1(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public deleteQuestionByIdUsingDELETE1(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling deleteQuestionByIdUsingDELETE1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ResponseEntity>(`${this.basePath}/editor/question/id/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a list of questions
     * 
     * @param questionIdList questionIdList
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteQuestionsByIdListUsingDELETE(questionIdList: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: string; }>;
    public deleteQuestionsByIdListUsingDELETE(questionIdList: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: string; }>>;
    public deleteQuestionsByIdListUsingDELETE(questionIdList: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: string; }>>;
    public deleteQuestionsByIdListUsingDELETE(questionIdList: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionIdList === null || questionIdList === undefined) {
            throw new Error('Required parameter questionIdList was null or undefined when calling deleteQuestionsByIdListUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.delete<{ [key: string]: string; }>(`${this.basePath}/editor/question/exec/delete/list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Duplicate a Question and set school districts, types and topic
     * 
     * @param duplicateQuestionDTO duplicateQuestionDTO
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public duplicateQuestionUsingPOST(duplicateQuestionDTO: DuplicateQuestionDTO, name?: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public duplicateQuestionUsingPOST(duplicateQuestionDTO: DuplicateQuestionDTO, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public duplicateQuestionUsingPOST(duplicateQuestionDTO: DuplicateQuestionDTO, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public duplicateQuestionUsingPOST(duplicateQuestionDTO: DuplicateQuestionDTO, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (duplicateQuestionDTO === null || duplicateQuestionDTO === undefined) {
            throw new Error('Required parameter duplicateQuestionDTO was null or undefined when calling duplicateQuestionUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/editor/question/data/duplicate`,
            duplicateQuestionDTO,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a all QuestionDTOs for active questions
     * 
     * @param name 
     * @param offset 
     * @param pageNumber 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllQuestionDTOsUsingGET(name?: string, offset?: number, pageNumber?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionDTO>>;
    public getAllQuestionDTOsUsingGET(name?: string, offset?: number, pageNumber?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionDTO>>>;
    public getAllQuestionDTOsUsingGET(name?: string, offset?: number, pageNumber?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionDTO>>>;
    public getAllQuestionDTOsUsingGET(name?: string, offset?: number, pageNumber?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('pageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleQuestionDTO>>(`${this.basePath}/editor/question/data/complex/questionsAllQuestions/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a all QuestionDTOs for active or both active and inactive questions
     * 
     * @param activeOnly activeOnly
     * @param name 
     * @param offset 
     * @param pageNumber 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllQuestionDTOsUsingGET1(activeOnly: boolean, name?: string, offset?: number, pageNumber?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionDTO>>;
    public getAllQuestionDTOsUsingGET1(activeOnly: boolean, name?: string, offset?: number, pageNumber?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionDTO>>>;
    public getAllQuestionDTOsUsingGET1(activeOnly: boolean, name?: string, offset?: number, pageNumber?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionDTO>>>;
    public getAllQuestionDTOsUsingGET1(activeOnly: boolean, name?: string, offset?: number, pageNumber?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getAllQuestionDTOsUsingGET1.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('pageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleQuestionDTO>>(`${this.basePath}/editor/question/data/complex/questionsAllQuestions/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a complex QuestionDTO for an active or inactive question
     * 
     * @param activeOnly activeOnly
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getComplexQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<QuestionDTO>;
    public getComplexQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<QuestionDTO>>;
    public getComplexQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<QuestionDTO>>;
    public getComplexQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getComplexQuestionDTOByIdUsingGET2.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getComplexQuestionDTOByIdUsingGET2.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<QuestionDTO>(`${this.basePath}/editor/question/data/complex/single/${encodeURIComponent(String(questionId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a complex QuestionDTO for an active question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getComplexQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<QuestionDTO>;
    public getComplexQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<QuestionDTO>>;
    public getComplexQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<QuestionDTO>>;
    public getComplexQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getComplexQuestionDTOByIdUsingGET3.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<QuestionDTO>(`${this.basePath}/editor/question/data/complex/single/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request the ids of all active or both active and inactive questions created by the current user
     * 
     * @param activeOnly activeOnly
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIdsOfQuestionsCreatedByActiveUserUsingGET2(activeOnly: boolean, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public getIdsOfQuestionsCreatedByActiveUserUsingGET2(activeOnly: boolean, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public getIdsOfQuestionsCreatedByActiveUserUsingGET2(activeOnly: boolean, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public getIdsOfQuestionsCreatedByActiveUserUsingGET2(activeOnly: boolean, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getIdsOfQuestionsCreatedByActiveUserUsingGET2.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/editor/question/data/id/owned-by-user/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request the ids of all active questions created by the current user
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIdsOfQuestionsCreatedByActiveUserUsingGET3(name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public getIdsOfQuestionsCreatedByActiveUserUsingGET3(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public getIdsOfQuestionsCreatedByActiveUserUsingGET3(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public getIdsOfQuestionsCreatedByActiveUserUsingGET3(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/editor/question/data/id/owned-by-user`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request the ids of all active or both active and inactive questions created by a user with the given id. Admin-Only!
     * 
     * @param activeOnly activeOnly
     * @param userId userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET2(activeOnly: boolean, userId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET2(activeOnly: boolean, userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET2(activeOnly: boolean, userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET2(activeOnly: boolean, userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getIdsOfQuestionsCreatedByUserWithIdUsingGET2.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getIdsOfQuestionsCreatedByUserWithIdUsingGET2.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/editor/question/id/owned-by-user/${encodeURIComponent(String(userId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request the ids of all active questions created by a user with the given id. Admin-Only!
     * 
     * @param userId userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET3(userId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET3(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET3(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET3(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getIdsOfQuestionsCreatedByUserWithIdUsingGET3.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/editor/question/id/owned-by-user/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request more recent QuestionDTOs
     * 
     * @param collectionId collectionId
     * @param creationTimeInSeconds creationTimeInSeconds
     * @param name 
     * @param updateTimeInSeconds updateTimeInSeconds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMoreRecentQuestionDTOsUsingGET(collectionId?: number, creationTimeInSeconds?: number, name?: string, updateTimeInSeconds?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionDTO>>;
    public getMoreRecentQuestionDTOsUsingGET(collectionId?: number, creationTimeInSeconds?: number, name?: string, updateTimeInSeconds?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionDTO>>>;
    public getMoreRecentQuestionDTOsUsingGET(collectionId?: number, creationTimeInSeconds?: number, name?: string, updateTimeInSeconds?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionDTO>>>;
    public getMoreRecentQuestionDTOsUsingGET(collectionId?: number, creationTimeInSeconds?: number, name?: string, updateTimeInSeconds?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (collectionId !== undefined && collectionId !== null) {
            queryParameters = queryParameters.set('collectionId', <any>collectionId);
        }
        if (creationTimeInSeconds !== undefined && creationTimeInSeconds !== null) {
            queryParameters = queryParameters.set('creationTimeInSeconds', <any>creationTimeInSeconds);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (updateTimeInSeconds !== undefined && updateTimeInSeconds !== null) {
            queryParameters = queryParameters.set('updateTimeInSeconds', <any>updateTimeInSeconds);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleQuestionDTO>>(`${this.basePath}/editor/question/data/complex/recentQuestions/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request ids of more recent active questions
     * 
     * @param collectionId collectionId
     * @param creationTimeInSeconds creationTimeInSeconds
     * @param name 
     * @param updateTimeInSeconds updateTimeInSeconds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMoreRecentQuestionIdsUsingGET(collectionId?: number, creationTimeInSeconds?: number, name?: string, updateTimeInSeconds?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public getMoreRecentQuestionIdsUsingGET(collectionId?: number, creationTimeInSeconds?: number, name?: string, updateTimeInSeconds?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public getMoreRecentQuestionIdsUsingGET(collectionId?: number, creationTimeInSeconds?: number, name?: string, updateTimeInSeconds?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public getMoreRecentQuestionIdsUsingGET(collectionId?: number, creationTimeInSeconds?: number, name?: string, updateTimeInSeconds?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (collectionId !== undefined && collectionId !== null) {
            queryParameters = queryParameters.set('collectionId', <any>collectionId);
        }
        if (creationTimeInSeconds !== undefined && creationTimeInSeconds !== null) {
            queryParameters = queryParameters.set('creationTimeInSeconds', <any>creationTimeInSeconds);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (updateTimeInSeconds !== undefined && updateTimeInSeconds !== null) {
            queryParameters = queryParameters.set('updateTimeInSeconds', <any>updateTimeInSeconds);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/editor/question/data/complex/recentQuestionIds/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request ids of more recent active or both active and inactive questions
     * 
     * @param activeOnly activeOnly
     * @param collectionId collectionId
     * @param creationTimeInSeconds creationTimeInSeconds
     * @param name 
     * @param updateTimeInSeconds updateTimeInSeconds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMoreRecentQuestionIdsUsingGET1(activeOnly: boolean, collectionId?: number, creationTimeInSeconds?: number, name?: string, updateTimeInSeconds?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public getMoreRecentQuestionIdsUsingGET1(activeOnly: boolean, collectionId?: number, creationTimeInSeconds?: number, name?: string, updateTimeInSeconds?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public getMoreRecentQuestionIdsUsingGET1(activeOnly: boolean, collectionId?: number, creationTimeInSeconds?: number, name?: string, updateTimeInSeconds?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public getMoreRecentQuestionIdsUsingGET1(activeOnly: boolean, collectionId?: number, creationTimeInSeconds?: number, name?: string, updateTimeInSeconds?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getMoreRecentQuestionIdsUsingGET1.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (collectionId !== undefined && collectionId !== null) {
            queryParameters = queryParameters.set('collectionId', <any>collectionId);
        }
        if (creationTimeInSeconds !== undefined && creationTimeInSeconds !== null) {
            queryParameters = queryParameters.set('creationTimeInSeconds', <any>creationTimeInSeconds);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (updateTimeInSeconds !== undefined && updateTimeInSeconds !== null) {
            queryParameters = queryParameters.set('updateTimeInSeconds', <any>updateTimeInSeconds);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/editor/question/data/complex/recentQuestionIds/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the count of active or both active and inactive questions for a list of topics
     * 
     * @param activeOnly activeOnly
     * @param topicIds topicIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionCountForTopicIdsUsingGET2(activeOnly: boolean, topicIds: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: number; }>;
    public getQuestionCountForTopicIdsUsingGET2(activeOnly: boolean, topicIds: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: number; }>>;
    public getQuestionCountForTopicIdsUsingGET2(activeOnly: boolean, topicIds: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: number; }>>;
    public getQuestionCountForTopicIdsUsingGET2(activeOnly: boolean, topicIds: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getQuestionCountForTopicIdsUsingGET2.');
        }

        if (topicIds === null || topicIds === undefined) {
            throw new Error('Required parameter topicIds was null or undefined when calling getQuestionCountForTopicIdsUsingGET2.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<{ [key: string]: number; }>(`${this.basePath}/editor/question/count/topic-ids/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the count of active questions for a list of topics
     * 
     * @param topicIds topicIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionCountForTopicIdsUsingGET3(topicIds: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: number; }>;
    public getQuestionCountForTopicIdsUsingGET3(topicIds: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: number; }>>;
    public getQuestionCountForTopicIdsUsingGET3(topicIds: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: number; }>>;
    public getQuestionCountForTopicIdsUsingGET3(topicIds: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (topicIds === null || topicIds === undefined) {
            throw new Error('Required parameter topicIds was null or undefined when calling getQuestionCountForTopicIdsUsingGET3.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<{ [key: string]: number; }>(`${this.basePath}/editor/question/count/topic-ids`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a complex QuestionDTO List by Filter, deprecated: use instead /data/questionsByFilter/
     * 
     * @param district district
     * @param editor editor
     * @param iconPath iconPath
     * @param name 
     * @param points points
     * @param questionType questionType
     * @param reported reported
     * @param schoolGrade schoolGrade
     * @param schoolType schoolType
     * @param subject subject
     * @param topic topic
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionDTOByFilterUsingGET(district?: number, editor?: number, iconPath?: boolean, name?: string, points?: number, questionType?: 'YES_NO' | 'MULTIPLE_CHOICE' | 'NUMBER_INPUT' | 'TEXT_INPUT' | 'COMPLEX', reported?: boolean, schoolGrade?: number, schoolType?: number, subject?: number, topic?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionDTO>>;
    public getQuestionDTOByFilterUsingGET(district?: number, editor?: number, iconPath?: boolean, name?: string, points?: number, questionType?: 'YES_NO' | 'MULTIPLE_CHOICE' | 'NUMBER_INPUT' | 'TEXT_INPUT' | 'COMPLEX', reported?: boolean, schoolGrade?: number, schoolType?: number, subject?: number, topic?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionDTO>>>;
    public getQuestionDTOByFilterUsingGET(district?: number, editor?: number, iconPath?: boolean, name?: string, points?: number, questionType?: 'YES_NO' | 'MULTIPLE_CHOICE' | 'NUMBER_INPUT' | 'TEXT_INPUT' | 'COMPLEX', reported?: boolean, schoolGrade?: number, schoolType?: number, subject?: number, topic?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionDTO>>>;
    public getQuestionDTOByFilterUsingGET(district?: number, editor?: number, iconPath?: boolean, name?: string, points?: number, questionType?: 'YES_NO' | 'MULTIPLE_CHOICE' | 'NUMBER_INPUT' | 'TEXT_INPUT' | 'COMPLEX', reported?: boolean, schoolGrade?: number, schoolType?: number, subject?: number, topic?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (district !== undefined && district !== null) {
            queryParameters = queryParameters.set('district', <any>district);
        }
        if (editor !== undefined && editor !== null) {
            queryParameters = queryParameters.set('editor', <any>editor);
        }
        if (iconPath !== undefined && iconPath !== null) {
            queryParameters = queryParameters.set('iconPath', <any>iconPath);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (points !== undefined && points !== null) {
            queryParameters = queryParameters.set('points', <any>points);
        }
        if (questionType !== undefined && questionType !== null) {
            queryParameters = queryParameters.set('questionType', <any>questionType);
        }
        if (reported !== undefined && reported !== null) {
            queryParameters = queryParameters.set('reported', <any>reported);
        }
        if (schoolGrade !== undefined && schoolGrade !== null) {
            queryParameters = queryParameters.set('schoolGrade', <any>schoolGrade);
        }
        if (schoolType !== undefined && schoolType !== null) {
            queryParameters = queryParameters.set('schoolType', <any>schoolType);
        }
        if (subject !== undefined && subject !== null) {
            queryParameters = queryParameters.set('subject', <any>subject);
        }
        if (topic !== undefined && topic !== null) {
            queryParameters = queryParameters.set('topic', <any>topic);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleQuestionDTO>>(`${this.basePath}/editor/question/data/complex/questionsByFilter/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a pageable list of active questions by filter
     * 
     * @param page page
     * @param pageSize pageSize
     * @param questionsByFilterDTO questionsByFilterDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionDTOsByFilterAndPageUsingGET(page: number, pageSize: number, questionsByFilterDTO: QuestionsByFilterDTO, observe?: 'body', reportProgress?: boolean): Observable<PageOfSimpleQuestionDTO>;
    public getQuestionDTOsByFilterAndPageUsingGET(page: number, pageSize: number, questionsByFilterDTO: QuestionsByFilterDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOfSimpleQuestionDTO>>;
    public getQuestionDTOsByFilterAndPageUsingGET(page: number, pageSize: number, questionsByFilterDTO: QuestionsByFilterDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOfSimpleQuestionDTO>>;
    public getQuestionDTOsByFilterAndPageUsingGET(page: number, pageSize: number, questionsByFilterDTO: QuestionsByFilterDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getQuestionDTOsByFilterAndPageUsingGET.');
        }

        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getQuestionDTOsByFilterAndPageUsingGET.');
        }

        if (questionsByFilterDTO === null || questionsByFilterDTO === undefined) {
            throw new Error('Required parameter questionsByFilterDTO was null or undefined when calling getQuestionDTOsByFilterAndPageUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<PageOfSimpleQuestionDTO>(`${this.basePath}/editor/question/data/questionsByFilter/pageable/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a pageable list of active or both active and inactive questions by filter
     * 
     * @param activeOnly activeOnly
     * @param page page
     * @param pageSize pageSize
     * @param questionsByFilterDTO questionsByFilterDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionDTOsByFilterAndPageUsingGET1(activeOnly: boolean, page: number, pageSize: number, questionsByFilterDTO: QuestionsByFilterDTO, observe?: 'body', reportProgress?: boolean): Observable<PageOfSimpleQuestionDTO>;
    public getQuestionDTOsByFilterAndPageUsingGET1(activeOnly: boolean, page: number, pageSize: number, questionsByFilterDTO: QuestionsByFilterDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOfSimpleQuestionDTO>>;
    public getQuestionDTOsByFilterAndPageUsingGET1(activeOnly: boolean, page: number, pageSize: number, questionsByFilterDTO: QuestionsByFilterDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOfSimpleQuestionDTO>>;
    public getQuestionDTOsByFilterAndPageUsingGET1(activeOnly: boolean, page: number, pageSize: number, questionsByFilterDTO: QuestionsByFilterDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getQuestionDTOsByFilterAndPageUsingGET1.');
        }

        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getQuestionDTOsByFilterAndPageUsingGET1.');
        }

        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getQuestionDTOsByFilterAndPageUsingGET1.');
        }

        if (questionsByFilterDTO === null || questionsByFilterDTO === undefined) {
            throw new Error('Required parameter questionsByFilterDTO was null or undefined when calling getQuestionDTOsByFilterAndPageUsingGET1.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<PageOfSimpleQuestionDTO>(`${this.basePath}/editor/question/data/questionsByFilter/pageable/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a list of active questions by filter
     * 
     * @param questionFilter questionFilter
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionDTOsByFilterUsingGET(questionFilter: QuestionsByFilterDTO, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionDTO>>;
    public getQuestionDTOsByFilterUsingGET(questionFilter: QuestionsByFilterDTO, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionDTO>>>;
    public getQuestionDTOsByFilterUsingGET(questionFilter: QuestionsByFilterDTO, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionDTO>>>;
    public getQuestionDTOsByFilterUsingGET(questionFilter: QuestionsByFilterDTO, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionFilter === null || questionFilter === undefined) {
            throw new Error('Required parameter questionFilter was null or undefined when calling getQuestionDTOsByFilterUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<Array<SimpleQuestionDTO>>(`${this.basePath}/editor/question/data/questionsByFilter/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a list of active or both active and inactive questions by filter
     * 
     * @param activeOnly activeOnly
     * @param questionFilter questionFilter
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionDTOsByFilterUsingGET1(activeOnly: boolean, questionFilter: QuestionsByFilterDTO, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionDTO>>;
    public getQuestionDTOsByFilterUsingGET1(activeOnly: boolean, questionFilter: QuestionsByFilterDTO, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionDTO>>>;
    public getQuestionDTOsByFilterUsingGET1(activeOnly: boolean, questionFilter: QuestionsByFilterDTO, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionDTO>>>;
    public getQuestionDTOsByFilterUsingGET1(activeOnly: boolean, questionFilter: QuestionsByFilterDTO, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getQuestionDTOsByFilterUsingGET1.');
        }

        if (questionFilter === null || questionFilter === undefined) {
            throw new Error('Required parameter questionFilter was null or undefined when calling getQuestionDTOsByFilterUsingGET1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<Array<SimpleQuestionDTO>>(`${this.basePath}/editor/question/data/questionsByFilter/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetch users question reports using given question report filter.
     * 
     * @param questionReportsFilterDto questionReportsFilterDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionReportsUsingFilterUsingPOST(questionReportsFilterDto: QuestionsReportsFilterDto, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getQuestionReportsUsingFilterUsingPOST(questionReportsFilterDto: QuestionsReportsFilterDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getQuestionReportsUsingFilterUsingPOST(questionReportsFilterDto: QuestionsReportsFilterDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getQuestionReportsUsingFilterUsingPOST(questionReportsFilterDto: QuestionsReportsFilterDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionReportsFilterDto === null || questionReportsFilterDto === undefined) {
            throw new Error('Required parameter questionReportsFilterDto was null or undefined when calling getQuestionReportsUsingFilterUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RestResponseMessage>(`${this.basePath}/editor/question/get/question-reports/using/filter`,
            questionReportsFilterDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple ComplexQuestionDTO for an active or inactive complex question
     * 
     * @param activeOnly activeOnly
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleComplexQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleComplexQuestionDTO>;
    public getSimpleComplexQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleComplexQuestionDTO>>;
    public getSimpleComplexQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleComplexQuestionDTO>>;
    public getSimpleComplexQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getSimpleComplexQuestionDTOByIdUsingGET2.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleComplexQuestionDTOByIdUsingGET2.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleComplexQuestionDTO>(`${this.basePath}/editor/question/data/simple/single-complex/${encodeURIComponent(String(questionId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple ComplexQuestionDTO for an active complex question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleComplexQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleComplexQuestionDTO>;
    public getSimpleComplexQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleComplexQuestionDTO>>;
    public getSimpleComplexQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleComplexQuestionDTO>>;
    public getSimpleComplexQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleComplexQuestionDTOByIdUsingGET3.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleComplexQuestionDTO>(`${this.basePath}/editor/question/data/simple/single-complex/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple MultipleChoiceQuestionDTO for an active or inactive multiple choice question
     * 
     * @param activeOnly activeOnly
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleMultipleChoiceQuestionDTO>;
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleMultipleChoiceQuestionDTO>>;
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleMultipleChoiceQuestionDTO>>;
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getSimpleMultipleChoiceQuestionDTOByIdUsingGET2.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleMultipleChoiceQuestionDTOByIdUsingGET2.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleMultipleChoiceQuestionDTO>(`${this.basePath}/editor/question/data/simple/single-multiple-choice/${encodeURIComponent(String(questionId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple MultipleChoiceQuestionDTO for an active multiple choice question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleMultipleChoiceQuestionDTO>;
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleMultipleChoiceQuestionDTO>>;
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleMultipleChoiceQuestionDTO>>;
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleMultipleChoiceQuestionDTOByIdUsingGET3.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleMultipleChoiceQuestionDTO>(`${this.basePath}/editor/question/data/simple/single-multiple-choice/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple NumberInputQuestionDTO for an active or inactive number input question
     * 
     * @param activeOnly activeOnly
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleNumberInputQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleNumberInputQuestionDTO>;
    public getSimpleNumberInputQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleNumberInputQuestionDTO>>;
    public getSimpleNumberInputQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleNumberInputQuestionDTO>>;
    public getSimpleNumberInputQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getSimpleNumberInputQuestionDTOByIdUsingGET2.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleNumberInputQuestionDTOByIdUsingGET2.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleNumberInputQuestionDTO>(`${this.basePath}/editor/question/data/simple/single-number-input/${encodeURIComponent(String(questionId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple NumberInputQuestionDTO for an active number input question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleNumberInputQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleNumberInputQuestionDTO>;
    public getSimpleNumberInputQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleNumberInputQuestionDTO>>;
    public getSimpleNumberInputQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleNumberInputQuestionDTO>>;
    public getSimpleNumberInputQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleNumberInputQuestionDTOByIdUsingGET3.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleNumberInputQuestionDTO>(`${this.basePath}/editor/question/data/simple/single-number-input/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple QuestionDTO for an active or inactive question
     * 
     * @param activeOnly activeOnly
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleQuestionDTO>;
    public getSimpleQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleQuestionDTO>>;
    public getSimpleQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleQuestionDTO>>;
    public getSimpleQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getSimpleQuestionDTOByIdUsingGET2.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleQuestionDTOByIdUsingGET2.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleQuestionDTO>(`${this.basePath}/editor/question/data/simple/single/${encodeURIComponent(String(questionId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple QuestionDTO for an active question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleQuestionDTO>;
    public getSimpleQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleQuestionDTO>>;
    public getSimpleQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleQuestionDTO>>;
    public getSimpleQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleQuestionDTOByIdUsingGET3.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleQuestionDTO>(`${this.basePath}/editor/question/data/simple/single/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a list of simple QuestionDTOs for active or both active and inactive questions by a list of ids
     * 
     * @param activeOnly activeOnly
     * @param questionIdList questionIdList
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleQuestionDTOListByIdListUsingGET2(activeOnly: boolean, questionIdList: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionDTO>>;
    public getSimpleQuestionDTOListByIdListUsingGET2(activeOnly: boolean, questionIdList: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionDTO>>>;
    public getSimpleQuestionDTOListByIdListUsingGET2(activeOnly: boolean, questionIdList: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionDTO>>>;
    public getSimpleQuestionDTOListByIdListUsingGET2(activeOnly: boolean, questionIdList: string, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getSimpleQuestionDTOListByIdListUsingGET2.');
        }

        if (questionIdList === null || questionIdList === undefined) {
            throw new Error('Required parameter questionIdList was null or undefined when calling getSimpleQuestionDTOListByIdListUsingGET2.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleQuestionDTO>>(`${this.basePath}/editor/question/data/simple/list/${encodeURIComponent(String(questionIdList))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a list of simple QuestionDTOs for active questions by a list of ids
     * 
     * @param questionIdList questionIdList
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleQuestionDTOListByIdListUsingGET3(questionIdList: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionDTO>>;
    public getSimpleQuestionDTOListByIdListUsingGET3(questionIdList: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionDTO>>>;
    public getSimpleQuestionDTOListByIdListUsingGET3(questionIdList: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionDTO>>>;
    public getSimpleQuestionDTOListByIdListUsingGET3(questionIdList: string, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionIdList === null || questionIdList === undefined) {
            throw new Error('Required parameter questionIdList was null or undefined when calling getSimpleQuestionDTOListByIdListUsingGET3.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleQuestionDTO>>(`${this.basePath}/editor/question/data/simple/list/${encodeURIComponent(String(questionIdList))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple TextInputQuestionDTO for an active or inactive text input question
     * 
     * @param activeOnly activeOnly
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleTextInputQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleTextInputQuestionDTO>;
    public getSimpleTextInputQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleTextInputQuestionDTO>>;
    public getSimpleTextInputQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleTextInputQuestionDTO>>;
    public getSimpleTextInputQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getSimpleTextInputQuestionDTOByIdUsingGET2.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleTextInputQuestionDTOByIdUsingGET2.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleTextInputQuestionDTO>(`${this.basePath}/editor/question/data/simple/single-text-input/${encodeURIComponent(String(questionId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple TextInputQuestionDTO for an active text input question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleTextInputQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleTextInputQuestionDTO>;
    public getSimpleTextInputQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleTextInputQuestionDTO>>;
    public getSimpleTextInputQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleTextInputQuestionDTO>>;
    public getSimpleTextInputQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleTextInputQuestionDTOByIdUsingGET3.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleTextInputQuestionDTO>(`${this.basePath}/editor/question/data/simple/single-text-input/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple YesNoQuestionDTO an active or inactive yes/no question
     * 
     * @param activeOnly activeOnly
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleYesNoQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleYesNoQuestionDTO>;
    public getSimpleYesNoQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleYesNoQuestionDTO>>;
    public getSimpleYesNoQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleYesNoQuestionDTO>>;
    public getSimpleYesNoQuestionDTOByIdUsingGET2(activeOnly: boolean, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getSimpleYesNoQuestionDTOByIdUsingGET2.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleYesNoQuestionDTOByIdUsingGET2.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleYesNoQuestionDTO>(`${this.basePath}/editor/question/data/simple/single-yes-no/${encodeURIComponent(String(questionId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple YesNoQuestionDTO an active yes/no question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleYesNoQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleYesNoQuestionDTO>;
    public getSimpleYesNoQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleYesNoQuestionDTO>>;
    public getSimpleYesNoQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleYesNoQuestionDTO>>;
    public getSimpleYesNoQuestionDTOByIdUsingGET3(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleYesNoQuestionDTOByIdUsingGET3.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleYesNoQuestionDTO>(`${this.basePath}/editor/question/data/simple/single-yes-no/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a list of simple QuestionDTOs for active or both active and inactive questions by a search query
     * 
     * @param activeOnly activeOnly
     * @param query query
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchSimpleQuestionsDTOsUsingGET2(activeOnly: boolean, query: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionDTO>>;
    public searchSimpleQuestionsDTOsUsingGET2(activeOnly: boolean, query: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionDTO>>>;
    public searchSimpleQuestionsDTOsUsingGET2(activeOnly: boolean, query: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionDTO>>>;
    public searchSimpleQuestionsDTOsUsingGET2(activeOnly: boolean, query: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling searchSimpleQuestionsDTOsUsingGET2.');
        }

        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling searchSimpleQuestionsDTOsUsingGET2.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleQuestionDTO>>(`${this.basePath}/editor/question/data/simple/search/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a list of simple QuestionDTOs for active questions by a search query
     * 
     * @param query query
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchSimpleQuestionsDTOsUsingGET3(query: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionDTO>>;
    public searchSimpleQuestionsDTOsUsingGET3(query: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionDTO>>>;
    public searchSimpleQuestionsDTOsUsingGET3(query: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionDTO>>>;
    public searchSimpleQuestionsDTOsUsingGET3(query: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling searchSimpleQuestionsDTOsUsingGET3.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleQuestionDTO>>(`${this.basePath}/editor/question/data/simple/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set picture for question
     * 
     * @param file file
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setPictureForQuestionWithIdUsingPOST1(file: Blob, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public setPictureForQuestionWithIdUsingPOST1(file: Blob, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public setPictureForQuestionWithIdUsingPOST1(file: Blob, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public setPictureForQuestionWithIdUsingPOST1(file: Blob, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling setPictureForQuestionWithIdUsingPOST1.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling setPictureForQuestionWithIdUsingPOST1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams.append('file', <any>file);
        }

        return this.httpClient.post<ResponseEntity>(`${this.basePath}/editor/question/id/${encodeURIComponent(String(questionId))}/picture/`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Marks all question reports of the given question as solved.
     * 
     * @param questionId questionId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setQuestionReportSolvedByQuestionIdUsingPUT(questionId: number, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public setQuestionReportSolvedByQuestionIdUsingPUT(questionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public setQuestionReportSolvedByQuestionIdUsingPUT(questionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public setQuestionReportSolvedByQuestionIdUsingPUT(questionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling setQuestionReportSolvedByQuestionIdUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<RestResponseMessage>(`${this.basePath}/editor/question/report/solve/by/question-id/${encodeURIComponent(String(questionId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Marks given question reports as solved.
     * 
     * @param questionReportIds questionReportIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setQuestionReportSolvedByQuestionReportIdsUsingPUT(questionReportIds: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public setQuestionReportSolvedByQuestionReportIdsUsingPUT(questionReportIds: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public setQuestionReportSolvedByQuestionReportIdsUsingPUT(questionReportIds: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public setQuestionReportSolvedByQuestionReportIdsUsingPUT(questionReportIds: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionReportIds === null || questionReportIds === undefined) {
            throw new Error('Required parameter questionReportIds was null or undefined when calling setQuestionReportSolvedByQuestionReportIdsUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<RestResponseMessage>(`${this.basePath}/editor/question/report/solve/by/question-report-ids/${encodeURIComponent(String(questionReportIds))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Assign question report to a root question report. The relationship is only used to detect reports of the same bug. Each report must be explicitly marked as solved.
     * 
     * @param questionReportId questionReportId
     * @param rootQuestionReportId rootQuestionReportId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setRootQuestionReportByQuestionReportIdUsingPUT(questionReportId: number, rootQuestionReportId: number, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public setRootQuestionReportByQuestionReportIdUsingPUT(questionReportId: number, rootQuestionReportId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public setRootQuestionReportByQuestionReportIdUsingPUT(questionReportId: number, rootQuestionReportId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public setRootQuestionReportByQuestionReportIdUsingPUT(questionReportId: number, rootQuestionReportId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionReportId === null || questionReportId === undefined) {
            throw new Error('Required parameter questionReportId was null or undefined when calling setRootQuestionReportByQuestionReportIdUsingPUT.');
        }

        if (rootQuestionReportId === null || rootQuestionReportId === undefined) {
            throw new Error('Required parameter rootQuestionReportId was null or undefined when calling setRootQuestionReportByQuestionReportIdUsingPUT.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (questionReportId !== undefined && questionReportId !== null) {
            queryParameters = queryParameters.set('questionReportId', <any>questionReportId);
        }
        if (rootQuestionReportId !== undefined && rootQuestionReportId !== null) {
            queryParameters = queryParameters.set('rootQuestionReportId', <any>rootQuestionReportId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<RestResponseMessage>(`${this.basePath}/editor/question/report/set/root-question-report/by/question-report-id`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Makes the requesting user responsible for the question report.
     * 
     * @param questionReportId questionReportId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setUserResponsibleForQuestionReportUsingPUT(questionReportId: number, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public setUserResponsibleForQuestionReportUsingPUT(questionReportId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public setUserResponsibleForQuestionReportUsingPUT(questionReportId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public setUserResponsibleForQuestionReportUsingPUT(questionReportId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionReportId === null || questionReportId === undefined) {
            throw new Error('Required parameter questionReportId was null or undefined when calling setUserResponsibleForQuestionReportUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<RestResponseMessage>(`${this.basePath}/editor/question/report/set/responsible-user/by/question-report-id/${encodeURIComponent(String(questionReportId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a question
     * 
     * @param simpleQuestionDTO simpleQuestionDTO
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateQuestionFromSimpleDTOUsingPUT1(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public updateQuestionFromSimpleDTOUsingPUT1(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public updateQuestionFromSimpleDTOUsingPUT1(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public updateQuestionFromSimpleDTOUsingPUT1(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleQuestionDTO === null || simpleQuestionDTO === undefined) {
            throw new Error('Required parameter simpleQuestionDTO was null or undefined when calling updateQuestionFromSimpleDTOUsingPUT1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResponseEntity>(`${this.basePath}/editor/question/data/simple`,
            simpleQuestionDTO,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a question report by a SimpleQuestionDTO with a corrected question content. The new question content will be sent to Skills4School-Service for review and confirmation.
     * 
     * @param simpleQuestionWithReportDto simpleQuestionWithReportDto
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateQuestionReportBySimpleQuestionWithReportDTOUsingPUT(simpleQuestionWithReportDto: SimpleQuestionWithReportDTO, name?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateQuestionReportBySimpleQuestionWithReportDTOUsingPUT(simpleQuestionWithReportDto: SimpleQuestionWithReportDTO, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateQuestionReportBySimpleQuestionWithReportDTOUsingPUT(simpleQuestionWithReportDto: SimpleQuestionWithReportDTO, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateQuestionReportBySimpleQuestionWithReportDTOUsingPUT(simpleQuestionWithReportDto: SimpleQuestionWithReportDTO, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleQuestionWithReportDto === null || simpleQuestionWithReportDto === undefined) {
            throw new Error('Required parameter simpleQuestionWithReportDto was null or undefined when calling updateQuestionReportBySimpleQuestionWithReportDTOUsingPUT.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/editor/question/update/question-report/by/simple-question-with-report-dto`,
            simpleQuestionWithReportDto,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
