/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { GenericMessage } from '../model/genericMessage';
import { QuestionDTO } from '../model/questionDTO';
import { ResponseEntity } from '../model/responseEntity';
import { SimpleComplexQuestionDTO } from '../model/simpleComplexQuestionDTO';
import { SimpleMultipleChoiceQuestionDTO } from '../model/simpleMultipleChoiceQuestionDTO';
import { SimpleNumberInputQuestionDTO } from '../model/simpleNumberInputQuestionDTO';
import { SimpleQuestionDTO } from '../model/simpleQuestionDTO';
import { SimpleTextInputQuestionDTO } from '../model/simpleTextInputQuestionDTO';
import { SimpleYesNoQuestionDTO } from '../model/simpleYesNoQuestionDTO';
import { UserTrainStatsDTO } from '../model/userTrainStatsDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AppQuestionControllerService {

    protected basePath = 'https://api.skills4school.de/s4s-api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create a Question including Hints and Formulas
     * 
     * @param simpleQuestionDTO simpleQuestionDTO
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createQuestionFromSimpleDTOUsingPOST(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public createQuestionFromSimpleDTOUsingPOST(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public createQuestionFromSimpleDTOUsingPOST(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public createQuestionFromSimpleDTOUsingPOST(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleQuestionDTO === null || simpleQuestionDTO === undefined) {
            throw new Error('Required parameter simpleQuestionDTO was null or undefined when calling createQuestionFromSimpleDTOUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/question/data/simple`,
            simpleQuestionDTO,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a Question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteQuestionByIdUsingDELETE(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public deleteQuestionByIdUsingDELETE(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public deleteQuestionByIdUsingDELETE(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public deleteQuestionByIdUsingDELETE(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling deleteQuestionByIdUsingDELETE.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ResponseEntity>(`${this.basePath}/question/id/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a complex QuestionDTO for an active or inactive question
     * 
     * @param activeOnly activeOnly
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getComplexQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<QuestionDTO>;
    public getComplexQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<QuestionDTO>>;
    public getComplexQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<QuestionDTO>>;
    public getComplexQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getComplexQuestionDTOByIdUsingGET.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getComplexQuestionDTOByIdUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<QuestionDTO>(`${this.basePath}/question/data/complex/single/${encodeURIComponent(String(questionId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a complex QuestionDTO for an active question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getComplexQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<QuestionDTO>;
    public getComplexQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<QuestionDTO>>;
    public getComplexQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<QuestionDTO>>;
    public getComplexQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getComplexQuestionDTOByIdUsingGET1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<QuestionDTO>(`${this.basePath}/question/data/complex/single/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request the ids of all active or both active and inactive questions created by the current user
     * 
     * @param activeOnly activeOnly
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIdsOfQuestionsCreatedByActiveUserUsingGET(activeOnly: boolean, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public getIdsOfQuestionsCreatedByActiveUserUsingGET(activeOnly: boolean, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public getIdsOfQuestionsCreatedByActiveUserUsingGET(activeOnly: boolean, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public getIdsOfQuestionsCreatedByActiveUserUsingGET(activeOnly: boolean, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getIdsOfQuestionsCreatedByActiveUserUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/question/data/id/owned-by-user/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request the ids of all active questions created by the current user
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIdsOfQuestionsCreatedByActiveUserUsingGET1(name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public getIdsOfQuestionsCreatedByActiveUserUsingGET1(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public getIdsOfQuestionsCreatedByActiveUserUsingGET1(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public getIdsOfQuestionsCreatedByActiveUserUsingGET1(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/question/data/id/owned-by-user`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request the ids of all active or both active and inactive questions created by a user with the given id. Supervisor, Chief Editor und Admin only!
     * 
     * @param activeOnly activeOnly
     * @param userId userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET(activeOnly: boolean, userId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET(activeOnly: boolean, userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET(activeOnly: boolean, userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET(activeOnly: boolean, userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getIdsOfQuestionsCreatedByUserWithIdUsingGET.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getIdsOfQuestionsCreatedByUserWithIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/question/id/owned-by-user/${encodeURIComponent(String(userId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request the ids of all active questions created by a user with the given id. Supervisor, Chief Editor und Admin only!
     * 
     * @param userId userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET1(userId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET1(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET1(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public getIdsOfQuestionsCreatedByUserWithIdUsingGET1(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getIdsOfQuestionsCreatedByUserWithIdUsingGET1.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/question/id/owned-by-user/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the count of active or both active and inactive questions for a list of topics
     * 
     * @param activeOnly activeOnly
     * @param topicIds topicIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionCountForTopicIdsUsingGET(activeOnly: boolean, topicIds: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: number; }>;
    public getQuestionCountForTopicIdsUsingGET(activeOnly: boolean, topicIds: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: number; }>>;
    public getQuestionCountForTopicIdsUsingGET(activeOnly: boolean, topicIds: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: number; }>>;
    public getQuestionCountForTopicIdsUsingGET(activeOnly: boolean, topicIds: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getQuestionCountForTopicIdsUsingGET.');
        }

        if (topicIds === null || topicIds === undefined) {
            throw new Error('Required parameter topicIds was null or undefined when calling getQuestionCountForTopicIdsUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<{ [key: string]: number; }>(`${this.basePath}/question/count/topic-ids/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the count of active questions for a list of topics
     * 
     * @param topicIds topicIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionCountForTopicIdsUsingGET1(topicIds: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: number; }>;
    public getQuestionCountForTopicIdsUsingGET1(topicIds: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: number; }>>;
    public getQuestionCountForTopicIdsUsingGET1(topicIds: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: number; }>>;
    public getQuestionCountForTopicIdsUsingGET1(topicIds: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (topicIds === null || topicIds === undefined) {
            throw new Error('Required parameter topicIds was null or undefined when calling getQuestionCountForTopicIdsUsingGET1.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<{ [key: string]: number; }>(`${this.basePath}/question/count/topic-ids`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple ComplexQuestionDTO for an active or inactive complex question
     * 
     * @param activeOnly activeOnly
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleComplexQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleComplexQuestionDTO>;
    public getSimpleComplexQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleComplexQuestionDTO>>;
    public getSimpleComplexQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleComplexQuestionDTO>>;
    public getSimpleComplexQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getSimpleComplexQuestionDTOByIdUsingGET.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleComplexQuestionDTOByIdUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleComplexQuestionDTO>(`${this.basePath}/question/data/simple/single-complex/${encodeURIComponent(String(questionId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple ComplexQuestionDTO for an active complex question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleComplexQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleComplexQuestionDTO>;
    public getSimpleComplexQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleComplexQuestionDTO>>;
    public getSimpleComplexQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleComplexQuestionDTO>>;
    public getSimpleComplexQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleComplexQuestionDTOByIdUsingGET1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleComplexQuestionDTO>(`${this.basePath}/question/data/simple/single-complex/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple MultipleChoiceQuestionDTO for an active or inactive multiple choice question
     * 
     * @param activeOnly activeOnly
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleMultipleChoiceQuestionDTO>;
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleMultipleChoiceQuestionDTO>>;
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleMultipleChoiceQuestionDTO>>;
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getSimpleMultipleChoiceQuestionDTOByIdUsingGET.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleMultipleChoiceQuestionDTOByIdUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleMultipleChoiceQuestionDTO>(`${this.basePath}/question/data/simple/single-multiple-choice/${encodeURIComponent(String(questionId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple MultipleChoiceQuestionDTO for an active multiple choice question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleMultipleChoiceQuestionDTO>;
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleMultipleChoiceQuestionDTO>>;
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleMultipleChoiceQuestionDTO>>;
    public getSimpleMultipleChoiceQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleMultipleChoiceQuestionDTOByIdUsingGET1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleMultipleChoiceQuestionDTO>(`${this.basePath}/question/data/simple/single-multiple-choice/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple NumberInputQuestionDTO for an active or inactive number input question
     * 
     * @param activeOnly activeOnly
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleNumberInputQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleNumberInputQuestionDTO>;
    public getSimpleNumberInputQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleNumberInputQuestionDTO>>;
    public getSimpleNumberInputQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleNumberInputQuestionDTO>>;
    public getSimpleNumberInputQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getSimpleNumberInputQuestionDTOByIdUsingGET.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleNumberInputQuestionDTOByIdUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleNumberInputQuestionDTO>(`${this.basePath}/question/data/simple/single-number-input/${encodeURIComponent(String(questionId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple NumberInputQuestionDTO for an active number input question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleNumberInputQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleNumberInputQuestionDTO>;
    public getSimpleNumberInputQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleNumberInputQuestionDTO>>;
    public getSimpleNumberInputQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleNumberInputQuestionDTO>>;
    public getSimpleNumberInputQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleNumberInputQuestionDTOByIdUsingGET1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleNumberInputQuestionDTO>(`${this.basePath}/question/data/simple/single-number-input/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple QuestionDTO for an active or inactive question
     * 
     * @param activeOnly activeOnly
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleQuestionDTO>;
    public getSimpleQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleQuestionDTO>>;
    public getSimpleQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleQuestionDTO>>;
    public getSimpleQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getSimpleQuestionDTOByIdUsingGET.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleQuestionDTOByIdUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleQuestionDTO>(`${this.basePath}/question/data/simple/single/${encodeURIComponent(String(questionId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple QuestionDTO for an active question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleQuestionDTO>;
    public getSimpleQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleQuestionDTO>>;
    public getSimpleQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleQuestionDTO>>;
    public getSimpleQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleQuestionDTOByIdUsingGET1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleQuestionDTO>(`${this.basePath}/question/data/simple/single/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a list of simple QuestionDTOs for active or both active and inactive questions by a list of ids
     * 
     * @param activeOnly activeOnly
     * @param questionIdList questionIdList
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleQuestionDTOListByIdListUsingGET(activeOnly: boolean, questionIdList: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionDTO>>;
    public getSimpleQuestionDTOListByIdListUsingGET(activeOnly: boolean, questionIdList: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionDTO>>>;
    public getSimpleQuestionDTOListByIdListUsingGET(activeOnly: boolean, questionIdList: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionDTO>>>;
    public getSimpleQuestionDTOListByIdListUsingGET(activeOnly: boolean, questionIdList: string, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getSimpleQuestionDTOListByIdListUsingGET.');
        }

        if (questionIdList === null || questionIdList === undefined) {
            throw new Error('Required parameter questionIdList was null or undefined when calling getSimpleQuestionDTOListByIdListUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleQuestionDTO>>(`${this.basePath}/question/data/simple/list/${encodeURIComponent(String(questionIdList))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a list of simple QuestionDTOs for active questions by a list of ids
     * 
     * @param questionIdList questionIdList
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleQuestionDTOListByIdListUsingGET1(questionIdList: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionDTO>>;
    public getSimpleQuestionDTOListByIdListUsingGET1(questionIdList: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionDTO>>>;
    public getSimpleQuestionDTOListByIdListUsingGET1(questionIdList: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionDTO>>>;
    public getSimpleQuestionDTOListByIdListUsingGET1(questionIdList: string, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionIdList === null || questionIdList === undefined) {
            throw new Error('Required parameter questionIdList was null or undefined when calling getSimpleQuestionDTOListByIdListUsingGET1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleQuestionDTO>>(`${this.basePath}/question/data/simple/list/${encodeURIComponent(String(questionIdList))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple TextInputQuestionDTO for an active or inactive text input question
     * 
     * @param activeOnly activeOnly
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleTextInputQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleTextInputQuestionDTO>;
    public getSimpleTextInputQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleTextInputQuestionDTO>>;
    public getSimpleTextInputQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleTextInputQuestionDTO>>;
    public getSimpleTextInputQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getSimpleTextInputQuestionDTOByIdUsingGET.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleTextInputQuestionDTOByIdUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleTextInputQuestionDTO>(`${this.basePath}/question/data/simple/single-text-input/${encodeURIComponent(String(questionId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple TextInputQuestionDTO for an active text input question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleTextInputQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleTextInputQuestionDTO>;
    public getSimpleTextInputQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleTextInputQuestionDTO>>;
    public getSimpleTextInputQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleTextInputQuestionDTO>>;
    public getSimpleTextInputQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleTextInputQuestionDTOByIdUsingGET1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleTextInputQuestionDTO>(`${this.basePath}/question/data/simple/single-text-input/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple YesNoQuestionDTO for an active or inactive yes/no question
     * 
     * @param activeOnly activeOnly
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleYesNoQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleYesNoQuestionDTO>;
    public getSimpleYesNoQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleYesNoQuestionDTO>>;
    public getSimpleYesNoQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleYesNoQuestionDTO>>;
    public getSimpleYesNoQuestionDTOByIdUsingGET(activeOnly: boolean, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getSimpleYesNoQuestionDTOByIdUsingGET.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleYesNoQuestionDTOByIdUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleYesNoQuestionDTO>(`${this.basePath}/question/data/simple/single-yes-no/${encodeURIComponent(String(questionId))}/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a simple YesNoQuestionDTO for an active yes/no question
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleYesNoQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleYesNoQuestionDTO>;
    public getSimpleYesNoQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleYesNoQuestionDTO>>;
    public getSimpleYesNoQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleYesNoQuestionDTO>>;
    public getSimpleYesNoQuestionDTOByIdUsingGET1(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getSimpleYesNoQuestionDTOByIdUsingGET1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleYesNoQuestionDTO>(`${this.basePath}/question/data/simple/single-yes-no/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the user stats for a collection (filtered by right and wrong answers for active or both active and inactive questions)
     * 
     * @param activeOnly activeOnly
     * @param collectionId collectionId
     * @param minRightAnswers minRightAnswers
     * @param minWrongAnswers minWrongAnswers
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserStatsForCollectionUsingGET(activeOnly: boolean, collectionId: number, minRightAnswers: number, minWrongAnswers: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserTrainStatsDTO>>;
    public getUserStatsForCollectionUsingGET(activeOnly: boolean, collectionId: number, minRightAnswers: number, minWrongAnswers: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserTrainStatsDTO>>>;
    public getUserStatsForCollectionUsingGET(activeOnly: boolean, collectionId: number, minRightAnswers: number, minWrongAnswers: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserTrainStatsDTO>>>;
    public getUserStatsForCollectionUsingGET(activeOnly: boolean, collectionId: number, minRightAnswers: number, minWrongAnswers: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling getUserStatsForCollectionUsingGET.');
        }

        if (collectionId === null || collectionId === undefined) {
            throw new Error('Required parameter collectionId was null or undefined when calling getUserStatsForCollectionUsingGET.');
        }

        if (minRightAnswers === null || minRightAnswers === undefined) {
            throw new Error('Required parameter minRightAnswers was null or undefined when calling getUserStatsForCollectionUsingGET.');
        }

        if (minWrongAnswers === null || minWrongAnswers === undefined) {
            throw new Error('Required parameter minWrongAnswers was null or undefined when calling getUserStatsForCollectionUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (collectionId !== undefined && collectionId !== null) {
            queryParameters = queryParameters.set('collectionId', <any>collectionId);
        }
        if (minRightAnswers !== undefined && minRightAnswers !== null) {
            queryParameters = queryParameters.set('minRightAnswers', <any>minRightAnswers);
        }
        if (minWrongAnswers !== undefined && minWrongAnswers !== null) {
            queryParameters = queryParameters.set('minWrongAnswers', <any>minWrongAnswers);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UserTrainStatsDTO>>(`${this.basePath}/question/get_stats/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the user stats for a collection (filtered by right and wrong answers for active questions)
     * 
     * @param collectionId collectionId
     * @param minRightAnswers minRightAnswers
     * @param minWrongAnswers minWrongAnswers
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserStatsForCollectionUsingGET1(collectionId: number, minRightAnswers: number, minWrongAnswers: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserTrainStatsDTO>>;
    public getUserStatsForCollectionUsingGET1(collectionId: number, minRightAnswers: number, minWrongAnswers: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserTrainStatsDTO>>>;
    public getUserStatsForCollectionUsingGET1(collectionId: number, minRightAnswers: number, minWrongAnswers: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserTrainStatsDTO>>>;
    public getUserStatsForCollectionUsingGET1(collectionId: number, minRightAnswers: number, minWrongAnswers: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (collectionId === null || collectionId === undefined) {
            throw new Error('Required parameter collectionId was null or undefined when calling getUserStatsForCollectionUsingGET1.');
        }

        if (minRightAnswers === null || minRightAnswers === undefined) {
            throw new Error('Required parameter minRightAnswers was null or undefined when calling getUserStatsForCollectionUsingGET1.');
        }

        if (minWrongAnswers === null || minWrongAnswers === undefined) {
            throw new Error('Required parameter minWrongAnswers was null or undefined when calling getUserStatsForCollectionUsingGET1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (collectionId !== undefined && collectionId !== null) {
            queryParameters = queryParameters.set('collectionId', <any>collectionId);
        }
        if (minRightAnswers !== undefined && minRightAnswers !== null) {
            queryParameters = queryParameters.set('minRightAnswers', <any>minRightAnswers);
        }
        if (minWrongAnswers !== undefined && minWrongAnswers !== null) {
            queryParameters = queryParameters.set('minWrongAnswers', <any>minWrongAnswers);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UserTrainStatsDTO>>(`${this.basePath}/question/get_stats`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Increase the user question progress
     * 
     * @param collectionId collectionId
     * @param progressInSeconds progressInSeconds
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public increaseQuestionProgressUsingPOST(collectionId: number, progressInSeconds: number, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public increaseQuestionProgressUsingPOST(collectionId: number, progressInSeconds: number, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public increaseQuestionProgressUsingPOST(collectionId: number, progressInSeconds: number, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public increaseQuestionProgressUsingPOST(collectionId: number, progressInSeconds: number, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (collectionId === null || collectionId === undefined) {
            throw new Error('Required parameter collectionId was null or undefined when calling increaseQuestionProgressUsingPOST.');
        }

        if (progressInSeconds === null || progressInSeconds === undefined) {
            throw new Error('Required parameter progressInSeconds was null or undefined when calling increaseQuestionProgressUsingPOST.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling increaseQuestionProgressUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (collectionId !== undefined && collectionId !== null) {
            queryParameters = queryParameters.set('collectionId', <any>collectionId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (progressInSeconds !== undefined && progressInSeconds !== null) {
            queryParameters = queryParameters.set('progressInSeconds', <any>progressInSeconds);
        }
        if (questionId !== undefined && questionId !== null) {
            queryParameters = queryParameters.set('questionId', <any>questionId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<GenericMessage>(`${this.basePath}/question/increase_question_progress`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Increase the user question stats
     * 
     * @param collectionId collectionId
     * @param questionId questionId
     * @param rightAnswer rightAnswer
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public increaseUserStatsUsingPOST(collectionId: number, questionId: number, rightAnswer: boolean, name?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public increaseUserStatsUsingPOST(collectionId: number, questionId: number, rightAnswer: boolean, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public increaseUserStatsUsingPOST(collectionId: number, questionId: number, rightAnswer: boolean, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public increaseUserStatsUsingPOST(collectionId: number, questionId: number, rightAnswer: boolean, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (collectionId === null || collectionId === undefined) {
            throw new Error('Required parameter collectionId was null or undefined when calling increaseUserStatsUsingPOST.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling increaseUserStatsUsingPOST.');
        }

        if (rightAnswer === null || rightAnswer === undefined) {
            throw new Error('Required parameter rightAnswer was null or undefined when calling increaseUserStatsUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (collectionId !== undefined && collectionId !== null) {
            queryParameters = queryParameters.set('collectionId', <any>collectionId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (questionId !== undefined && questionId !== null) {
            queryParameters = queryParameters.set('questionId', <any>questionId);
        }
        if (rightAnswer !== undefined && rightAnswer !== null) {
            queryParameters = queryParameters.set('rightAnswer', <any>rightAnswer);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<GenericMessage>(`${this.basePath}/question/increase_stats`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reports the given question to the service mail
     * 
     * @param questionId questionId
     * @param name 
     * @param reportMessage reportMessage
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportQuestionByIdUsingGET(questionId: number, name?: string, reportMessage?: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public reportQuestionByIdUsingGET(questionId: number, name?: string, reportMessage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public reportQuestionByIdUsingGET(questionId: number, name?: string, reportMessage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public reportQuestionByIdUsingGET(questionId: number, name?: string, reportMessage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling reportQuestionByIdUsingGET.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (questionId !== undefined && questionId !== null) {
            queryParameters = queryParameters.set('questionId', <any>questionId);
        }
        if (reportMessage !== undefined && reportMessage !== null) {
            queryParameters = queryParameters.set('reportMessage', <any>reportMessage);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResponseEntity>(`${this.basePath}/question/exec/report/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a list of simple QuestionDTOs for active or both active and incactive questions by a search query
     * 
     * @param activeOnly activeOnly
     * @param query query
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchSimpleQuestionsDTOsUsingGET(activeOnly: boolean, query: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionDTO>>;
    public searchSimpleQuestionsDTOsUsingGET(activeOnly: boolean, query: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionDTO>>>;
    public searchSimpleQuestionsDTOsUsingGET(activeOnly: boolean, query: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionDTO>>>;
    public searchSimpleQuestionsDTOsUsingGET(activeOnly: boolean, query: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling searchSimpleQuestionsDTOsUsingGET.');
        }

        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling searchSimpleQuestionsDTOsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleQuestionDTO>>(`${this.basePath}/question/data/simple/search/active-only/${encodeURIComponent(String(activeOnly))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a list of simple QuestionDTOs for active questions by a search query
     * 
     * @param query query
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchSimpleQuestionsDTOsUsingGET1(query: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionDTO>>;
    public searchSimpleQuestionsDTOsUsingGET1(query: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionDTO>>>;
    public searchSimpleQuestionsDTOsUsingGET1(query: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionDTO>>>;
    public searchSimpleQuestionsDTOsUsingGET1(query: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling searchSimpleQuestionsDTOsUsingGET1.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleQuestionDTO>>(`${this.basePath}/question/data/simple/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set picture for question
     * 
     * @param file file
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setPictureForQuestionWithIdUsingPOST(file: Blob, questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public setPictureForQuestionWithIdUsingPOST(file: Blob, questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public setPictureForQuestionWithIdUsingPOST(file: Blob, questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public setPictureForQuestionWithIdUsingPOST(file: Blob, questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling setPictureForQuestionWithIdUsingPOST.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling setPictureForQuestionWithIdUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams.append('file', <any>file);
        }

        return this.httpClient.post<ResponseEntity>(`${this.basePath}/question/id/${encodeURIComponent(String(questionId))}/picture/`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a question
     * 
     * @param simpleQuestionDTO simpleQuestionDTO
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateQuestionFromSimpleDTOUsingPUT(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public updateQuestionFromSimpleDTOUsingPUT(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public updateQuestionFromSimpleDTOUsingPUT(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public updateQuestionFromSimpleDTOUsingPUT(simpleQuestionDTO: SimpleQuestionDTO, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleQuestionDTO === null || simpleQuestionDTO === undefined) {
            throw new Error('Required parameter simpleQuestionDTO was null or undefined when calling updateQuestionFromSimpleDTOUsingPUT.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResponseEntity>(`${this.basePath}/question/data/simple`,
            simpleQuestionDTO,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
