/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Complete DTO for purchases
 */
export interface PurchaseDTO { 
    purchasableId: number;
    purchaseType: PurchaseDTO.PurchaseTypeEnum;
    receipt: string;
}
export namespace PurchaseDTO {
    export type PurchaseTypeEnum = 'APP_STORE' | 'PAYPAL' | 'PLAY_STORE';
    export const PurchaseTypeEnum = {
        APPSTORE: 'APP_STORE' as PurchaseTypeEnum,
        PAYPAL: 'PAYPAL' as PurchaseTypeEnum,
        PLAYSTORE: 'PLAY_STORE' as PurchaseTypeEnum
    };
}
