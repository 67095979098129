/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { HintDTO } from './hintDTO';
import { ReportMessageDTO } from './reportMessageDTO';
import { SimpleFormulaDTO } from './simpleFormulaDTO';
import { SimpleQuestionDTO } from './simpleQuestionDTO';
import { UserDTO } from './userDTO';


/**
 * Simple DTO for number input questions
 */
export interface SimpleNumberInputQuestionDTO { 
    active?: boolean;
    contentPackageId: number;
    creator?: UserDTO;
    description: string;
    errorHintDTO: HintDTO;
    hintDTOOne: HintDTO;
    hintDTOThree: HintDTO;
    hintDTOTwo: HintDTO;
    iconPath: string;
    iconSource: string;
    id: number;
    parentId?: number;
    points: number;
    reportMessages?: Array<ReportMessageDTO>;
    reported?: boolean;
    schoolDistrictIds: Array<number>;
    schoolTopicId: number;
    schoolTypeIds: Array<number>;
    simpleFormulaDTOS: Array<SimpleFormulaDTO>;
    subTopicId: number;
    timestampInserted: Date;
    timestampUpdated?: Date;
    type: SimpleNumberInputQuestionDTO.TypeEnum;
    answer: number;
}
export namespace SimpleNumberInputQuestionDTO {
    export type TypeEnum = 'YES_NO' | 'MULTIPLE_CHOICE' | 'NUMBER_INPUT' | 'TEXT_INPUT' | 'COMPLEX';
    export const TypeEnum = {
        YESNO: 'YES_NO' as TypeEnum,
        MULTIPLECHOICE: 'MULTIPLE_CHOICE' as TypeEnum,
        NUMBERINPUT: 'NUMBER_INPUT' as TypeEnum,
        TEXTINPUT: 'TEXT_INPUT' as TypeEnum,
        COMPLEX: 'COMPLEX' as TypeEnum
    };
}
