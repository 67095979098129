import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

const PREFIX = 's4s_app_2.0_';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private accessToken: string;
  private basePath = 'https://dev.skills4school.de/s4s-api';

  constructor(private http: HttpClient, private storage: Storage) {}

  async login(username: string, password: string) {
    let httpHeader = new HttpHeaders();
    httpHeader = httpHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    httpHeader = httpHeader.append('Authorization', 'Basic ' + btoa('skills4schoolApp' + ':' + ''));

    const body = 'grant_type=password&username=' + username + '&password=' + password + '&client_id=skills4schoolApp';
    const response: any = await this.http.post(this.basePath + '/oauth/token', body, { headers: httpHeader }).toPromise();
    // Set Token
    this.accessToken = response.access_token;
    console.log('this.accessToken', this.accessToken);
    await this.storage.set(PREFIX + 'accessToken', response.access_token);
  }

  logout() {
    return this.storage.clear();
  }

  async setAccessToken() {
    this.accessToken = await this.storage.get(PREFIX + 'accessToken');
  }

  getAccessToken() {
    if (this.accessToken) {
      return this.accessToken;
    } else {
      this.storage.get(PREFIX + 'accessToken').then(accessToken => {
        return accessToken;
      });
    }
  }

  getAccessTokenAysnc(): Promise<string> {
    return this.storage.get(PREFIX + 'accessToken');
  }
}
