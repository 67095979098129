/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { ChatCommentDTO } from '../model/chatCommentDTO';
import { ChatQuestionDTO } from '../model/chatQuestionDTO';
import { ChatQuestionsByFilterDTO } from '../model/chatQuestionsByFilterDTO';
import { GenericMessage } from '../model/genericMessage';
import { RestResponseMessage } from '../model/restResponseMessage';
import { SimpleChatCommentDTO } from '../model/simpleChatCommentDTO';
import { SimpleChatQuestionDTO } from '../model/simpleChatQuestionDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ChatControllerService {

    protected basePath = 'https://api.skills4school.de/s4s-api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create a chat comment
     * 
     * @param chatCommentData chatCommentData
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCommentUsingPOST(chatCommentData: SimpleChatCommentDTO, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ChatCommentDTO>;
    public createCommentUsingPOST(chatCommentData: SimpleChatCommentDTO, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatCommentDTO>>;
    public createCommentUsingPOST(chatCommentData: SimpleChatCommentDTO, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatCommentDTO>>;
    public createCommentUsingPOST(chatCommentData: SimpleChatCommentDTO, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatCommentData === null || chatCommentData === undefined) {
            throw new Error('Required parameter chatCommentData was null or undefined when calling createCommentUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ChatCommentDTO>(`${this.basePath}/chat/createComment`,
            chatCommentData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a chat question
     * 
     * @param chatQuestionData chatQuestionData
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createQuestionUsingPOST(chatQuestionData: SimpleChatQuestionDTO, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ChatQuestionDTO>;
    public createQuestionUsingPOST(chatQuestionData: SimpleChatQuestionDTO, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatQuestionDTO>>;
    public createQuestionUsingPOST(chatQuestionData: SimpleChatQuestionDTO, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatQuestionDTO>>;
    public createQuestionUsingPOST(chatQuestionData: SimpleChatQuestionDTO, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatQuestionData === null || chatQuestionData === undefined) {
            throw new Error('Required parameter chatQuestionData was null or undefined when calling createQuestionUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ChatQuestionDTO>(`${this.basePath}/chat/createQuestion`,
            chatQuestionData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete a comment by token
     * 
     * @param token token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCommentByTokenUsingGET(token: string, observe?: 'body', reportProgress?: boolean): Observable<ChatCommentDTO>;
    public deleteCommentByTokenUsingGET(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatCommentDTO>>;
    public deleteCommentByTokenUsingGET(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatCommentDTO>>;
    public deleteCommentByTokenUsingGET(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling deleteCommentByTokenUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChatCommentDTO>(`${this.basePath}/chat/delete_by_token/comment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete a comment
     * 
     * @param chatCommentId chatCommentId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCommentUsingGET(chatCommentId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ChatCommentDTO>;
    public deleteCommentUsingGET(chatCommentId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatCommentDTO>>;
    public deleteCommentUsingGET(chatCommentId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatCommentDTO>>;
    public deleteCommentUsingGET(chatCommentId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatCommentId === null || chatCommentId === undefined) {
            throw new Error('Required parameter chatCommentId was null or undefined when calling deleteCommentUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chatCommentId !== undefined && chatCommentId !== null) {
            queryParameters = queryParameters.set('chatCommentId', <any>chatCommentId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChatCommentDTO>(`${this.basePath}/chat/delete_comment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete a question by token
     * 
     * @param token token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteQuestionByTokenUsingGET(token: string, observe?: 'body', reportProgress?: boolean): Observable<ChatQuestionDTO>;
    public deleteQuestionByTokenUsingGET(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatQuestionDTO>>;
    public deleteQuestionByTokenUsingGET(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatQuestionDTO>>;
    public deleteQuestionByTokenUsingGET(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling deleteQuestionByTokenUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChatQuestionDTO>(`${this.basePath}/chat/delete_by_token/question`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete a question
     * 
     * @param chatQuestionId chatQuestionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteQuestionUsingGET(chatQuestionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ChatQuestionDTO>;
    public deleteQuestionUsingGET(chatQuestionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatQuestionDTO>>;
    public deleteQuestionUsingGET(chatQuestionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatQuestionDTO>>;
    public deleteQuestionUsingGET(chatQuestionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatQuestionId === null || chatQuestionId === undefined) {
            throw new Error('Required parameter chatQuestionId was null or undefined when calling deleteQuestionUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chatQuestionId !== undefined && chatQuestionId !== null) {
            queryParameters = queryParameters.set('chatQuestionId', <any>chatQuestionId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChatQuestionDTO>(`${this.basePath}/chat/delete_question`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a list of ChatCommentDTOs by a chat question id
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChatCommentsByQuestionIdUsingGET(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChatCommentDTO>>;
    public getChatCommentsByQuestionIdUsingGET(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChatCommentDTO>>>;
    public getChatCommentsByQuestionIdUsingGET(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChatCommentDTO>>>;
    public getChatCommentsByQuestionIdUsingGET(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getChatCommentsByQuestionIdUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ChatCommentDTO>>(`${this.basePath}/chat/getChatCommentsByQuestionId/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a chat question by id
     * 
     * @param questionId questionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChatQuestionByIdUsingGET(questionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ChatQuestionDTO>;
    public getChatQuestionByIdUsingGET(questionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatQuestionDTO>>;
    public getChatQuestionByIdUsingGET(questionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatQuestionDTO>>;
    public getChatQuestionByIdUsingGET(questionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getChatQuestionByIdUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChatQuestionDTO>(`${this.basePath}/chat/getChatQuestionById/${encodeURIComponent(String(questionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a ChatQuestionDTO list by filter
     * 
     * @param chatQuestionsByFilterDTO chatQuestionsByFilterDTO
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChatQuestionsByFilterUsingPOST(chatQuestionsByFilterDTO: ChatQuestionsByFilterDTO, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChatQuestionDTO>>;
    public getChatQuestionsByFilterUsingPOST(chatQuestionsByFilterDTO: ChatQuestionsByFilterDTO, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChatQuestionDTO>>>;
    public getChatQuestionsByFilterUsingPOST(chatQuestionsByFilterDTO: ChatQuestionsByFilterDTO, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChatQuestionDTO>>>;
    public getChatQuestionsByFilterUsingPOST(chatQuestionsByFilterDTO: ChatQuestionsByFilterDTO, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatQuestionsByFilterDTO === null || chatQuestionsByFilterDTO === undefined) {
            throw new Error('Required parameter chatQuestionsByFilterDTO was null or undefined when calling getChatQuestionsByFilterUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ChatQuestionDTO>>(`${this.basePath}/chat/getChatQuestionsByFilter/`,
            chatQuestionsByFilterDTO,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get chat questions marked by current user
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChatQuestionsMarkedByCurrentUserUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getChatQuestionsMarkedByCurrentUserUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getChatQuestionsMarkedByCurrentUserUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getChatQuestionsMarkedByCurrentUserUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/chat/getChatQuestionsMarkedByCurrentUser`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a comment marked by a supervisor.
     * 
     * @param chatQuestionId chatQuestionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCommentMarkedBySupervisorUsingGET(chatQuestionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ChatCommentDTO>;
    public getCommentMarkedBySupervisorUsingGET(chatQuestionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatCommentDTO>>;
    public getCommentMarkedBySupervisorUsingGET(chatQuestionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatCommentDTO>>;
    public getCommentMarkedBySupervisorUsingGET(chatQuestionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatQuestionId === null || chatQuestionId === undefined) {
            throw new Error('Required parameter chatQuestionId was null or undefined when calling getCommentMarkedBySupervisorUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chatQuestionId !== undefined && chatQuestionId !== null) {
            queryParameters = queryParameters.set('chatQuestionId', <any>chatQuestionId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChatCommentDTO>(`${this.basePath}/chat/getCommentMarkedBySupervisor`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a picture for a chat comment
     * 
     * @param chatCommentPicturesId chatCommentPicturesId
     * @param size size
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCommentPictureUsingGET(chatCommentPicturesId: number, size: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getCommentPictureUsingGET(chatCommentPicturesId: number, size: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getCommentPictureUsingGET(chatCommentPicturesId: number, size: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getCommentPictureUsingGET(chatCommentPicturesId: number, size: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatCommentPicturesId === null || chatCommentPicturesId === undefined) {
            throw new Error('Required parameter chatCommentPicturesId was null or undefined when calling getCommentPictureUsingGET.');
        }

        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getCommentPictureUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'image/jpeg'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/chat/comment_picture/${encodeURIComponent(String(chatCommentPicturesId))}/${encodeURIComponent(String(size))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a picture for a chat question
     * 
     * @param chatQuestionPicturesId chatQuestionPicturesId
     * @param size size
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPictureUsingGET(chatQuestionPicturesId: number, size: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getPictureUsingGET(chatQuestionPicturesId: number, size: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getPictureUsingGET(chatQuestionPicturesId: number, size: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getPictureUsingGET(chatQuestionPicturesId: number, size: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatQuestionPicturesId === null || chatQuestionPicturesId === undefined) {
            throw new Error('Required parameter chatQuestionPicturesId was null or undefined when calling getPictureUsingGET.');
        }

        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getPictureUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'image/jpeg'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/chat/picture/${encodeURIComponent(String(chatQuestionPicturesId))}/${encodeURIComponent(String(size))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * like a chat comment
     * 
     * @param chatCommentId chatCommentId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public likeCommentUsingPATCH(chatCommentId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public likeCommentUsingPATCH(chatCommentId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public likeCommentUsingPATCH(chatCommentId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public likeCommentUsingPATCH(chatCommentId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatCommentId === null || chatCommentId === undefined) {
            throw new Error('Required parameter chatCommentId was null or undefined when calling likeCommentUsingPATCH.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chatCommentId !== undefined && chatCommentId !== null) {
            queryParameters = queryParameters.set('chatCommentId', <any>chatCommentId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<RestResponseMessage>(`${this.basePath}/chat/comment/like`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set a comment marked by a supervisor.
     * 
     * @param chatCommentId chatCommentId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public markCommentBySupervisorUsingPOST(chatCommentId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ChatCommentDTO>;
    public markCommentBySupervisorUsingPOST(chatCommentId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatCommentDTO>>;
    public markCommentBySupervisorUsingPOST(chatCommentId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatCommentDTO>>;
    public markCommentBySupervisorUsingPOST(chatCommentId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatCommentId === null || chatCommentId === undefined) {
            throw new Error('Required parameter chatCommentId was null or undefined when calling markCommentBySupervisorUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chatCommentId !== undefined && chatCommentId !== null) {
            queryParameters = queryParameters.set('chatCommentId', <any>chatCommentId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ChatCommentDTO>(`${this.basePath}/chat/markCommentBySupervisor`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * rate a comment
     * 
     * @param chatCommentId chatCommentId
     * @param positive positive
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rateCommentUsingGET(chatCommentId: number, positive: boolean, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ChatCommentDTO>;
    public rateCommentUsingGET(chatCommentId: number, positive: boolean, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatCommentDTO>>;
    public rateCommentUsingGET(chatCommentId: number, positive: boolean, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatCommentDTO>>;
    public rateCommentUsingGET(chatCommentId: number, positive: boolean, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatCommentId === null || chatCommentId === undefined) {
            throw new Error('Required parameter chatCommentId was null or undefined when calling rateCommentUsingGET.');
        }

        if (positive === null || positive === undefined) {
            throw new Error('Required parameter positive was null or undefined when calling rateCommentUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chatCommentId !== undefined && chatCommentId !== null) {
            queryParameters = queryParameters.set('chatCommentId', <any>chatCommentId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (positive !== undefined && positive !== null) {
            queryParameters = queryParameters.set('positive', <any>positive);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChatCommentDTO>(`${this.basePath}/chat/rate_comment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * report a comment
     * 
     * @param chatCommentId chatCommentId
     * @param reportMessage reportMessage
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportChatCommentUsingGET(chatCommentId: number, reportMessage: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public reportChatCommentUsingGET(chatCommentId: number, reportMessage: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public reportChatCommentUsingGET(chatCommentId: number, reportMessage: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public reportChatCommentUsingGET(chatCommentId: number, reportMessage: string, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatCommentId === null || chatCommentId === undefined) {
            throw new Error('Required parameter chatCommentId was null or undefined when calling reportChatCommentUsingGET.');
        }

        if (reportMessage === null || reportMessage === undefined) {
            throw new Error('Required parameter reportMessage was null or undefined when calling reportChatCommentUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chatCommentId !== undefined && chatCommentId !== null) {
            queryParameters = queryParameters.set('chatCommentId', <any>chatCommentId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (reportMessage !== undefined && reportMessage !== null) {
            queryParameters = queryParameters.set('reportMessage', <any>reportMessage);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GenericMessage>(`${this.basePath}/chat/report_comment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * report a question
     * 
     * @param chatQuestionId chatQuestionId
     * @param reportMessage reportMessage
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportChatQuestionUsingGET(chatQuestionId: number, reportMessage: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public reportChatQuestionUsingGET(chatQuestionId: number, reportMessage: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public reportChatQuestionUsingGET(chatQuestionId: number, reportMessage: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public reportChatQuestionUsingGET(chatQuestionId: number, reportMessage: string, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatQuestionId === null || chatQuestionId === undefined) {
            throw new Error('Required parameter chatQuestionId was null or undefined when calling reportChatQuestionUsingGET.');
        }

        if (reportMessage === null || reportMessage === undefined) {
            throw new Error('Required parameter reportMessage was null or undefined when calling reportChatQuestionUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chatQuestionId !== undefined && chatQuestionId !== null) {
            queryParameters = queryParameters.set('chatQuestionId', <any>chatQuestionId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (reportMessage !== undefined && reportMessage !== null) {
            queryParameters = queryParameters.set('reportMessage', <any>reportMessage);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GenericMessage>(`${this.basePath}/chat/report_question`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * like or unlike a question
     * 
     * @param chatQuestionId chatQuestionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public switchQuestionLikeUsingGET(chatQuestionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ChatQuestionDTO>;
    public switchQuestionLikeUsingGET(chatQuestionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatQuestionDTO>>;
    public switchQuestionLikeUsingGET(chatQuestionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatQuestionDTO>>;
    public switchQuestionLikeUsingGET(chatQuestionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatQuestionId === null || chatQuestionId === undefined) {
            throw new Error('Required parameter chatQuestionId was null or undefined when calling switchQuestionLikeUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chatQuestionId !== undefined && chatQuestionId !== null) {
            queryParameters = queryParameters.set('chatQuestionId', <any>chatQuestionId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChatQuestionDTO>(`${this.basePath}/chat/switch_question_like`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * mark / unmark a question
     * 
     * @param chatQuestionId chatQuestionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public switchQuestionMarkUsingGET(chatQuestionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ChatQuestionDTO>;
    public switchQuestionMarkUsingGET(chatQuestionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatQuestionDTO>>;
    public switchQuestionMarkUsingGET(chatQuestionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatQuestionDTO>>;
    public switchQuestionMarkUsingGET(chatQuestionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatQuestionId === null || chatQuestionId === undefined) {
            throw new Error('Required parameter chatQuestionId was null or undefined when calling switchQuestionMarkUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chatQuestionId !== undefined && chatQuestionId !== null) {
            queryParameters = queryParameters.set('chatQuestionId', <any>chatQuestionId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChatQuestionDTO>(`${this.basePath}/chat/switch_question_mark`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * undelete a comment by token
     * 
     * @param token token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public undeleteCommentByTokenUsingGET(token: string, observe?: 'body', reportProgress?: boolean): Observable<ChatCommentDTO>;
    public undeleteCommentByTokenUsingGET(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatCommentDTO>>;
    public undeleteCommentByTokenUsingGET(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatCommentDTO>>;
    public undeleteCommentByTokenUsingGET(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling undeleteCommentByTokenUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChatCommentDTO>(`${this.basePath}/chat/undelete_by_token/comment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * undelete a comment
     * 
     * @param chatCommentId chatCommentId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public undeleteCommentUsingGET(chatCommentId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ChatCommentDTO>;
    public undeleteCommentUsingGET(chatCommentId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatCommentDTO>>;
    public undeleteCommentUsingGET(chatCommentId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatCommentDTO>>;
    public undeleteCommentUsingGET(chatCommentId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatCommentId === null || chatCommentId === undefined) {
            throw new Error('Required parameter chatCommentId was null or undefined when calling undeleteCommentUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chatCommentId !== undefined && chatCommentId !== null) {
            queryParameters = queryParameters.set('chatCommentId', <any>chatCommentId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChatCommentDTO>(`${this.basePath}/chat/undelete_comment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * undelete a question by token
     * 
     * @param token token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public undeleteQuestionByTokenUsingGET(token: string, observe?: 'body', reportProgress?: boolean): Observable<ChatQuestionDTO>;
    public undeleteQuestionByTokenUsingGET(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatQuestionDTO>>;
    public undeleteQuestionByTokenUsingGET(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatQuestionDTO>>;
    public undeleteQuestionByTokenUsingGET(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling undeleteQuestionByTokenUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChatQuestionDTO>(`${this.basePath}/chat/undelete_by_token/question`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * undelete a question
     * 
     * @param chatQuestionId chatQuestionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public undeleteQuestionUsingGET(chatQuestionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ChatQuestionDTO>;
    public undeleteQuestionUsingGET(chatQuestionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChatQuestionDTO>>;
    public undeleteQuestionUsingGET(chatQuestionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChatQuestionDTO>>;
    public undeleteQuestionUsingGET(chatQuestionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatQuestionId === null || chatQuestionId === undefined) {
            throw new Error('Required parameter chatQuestionId was null or undefined when calling undeleteQuestionUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chatQuestionId !== undefined && chatQuestionId !== null) {
            queryParameters = queryParameters.set('chatQuestionId', <any>chatQuestionId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChatQuestionDTO>(`${this.basePath}/chat/undelete_question`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * unlike a chat comment
     * 
     * @param chatCommentId chatCommentId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unlikeCommentUsingPATCH(chatCommentId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public unlikeCommentUsingPATCH(chatCommentId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public unlikeCommentUsingPATCH(chatCommentId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public unlikeCommentUsingPATCH(chatCommentId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chatCommentId === null || chatCommentId === undefined) {
            throw new Error('Required parameter chatCommentId was null or undefined when calling unlikeCommentUsingPATCH.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chatCommentId !== undefined && chatCommentId !== null) {
            queryParameters = queryParameters.set('chatCommentId', <any>chatCommentId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<RestResponseMessage>(`${this.basePath}/chat/comment/unlike`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
