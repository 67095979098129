import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Storage } from '@ionic/storage';
import { DomController } from '@ionic/angular';

const PREFIX = 's4s_app_2.0_';

interface Colors {
  primary: {
    main: string;
    mainRGB: string;
    contrast: string;
    contrastRGB: string;
    shade: string;
    tint: string;
  };
  secondary: {
    main: string;
    mainRGB: string;
    contrast: string;
    contrastRGB: string;
    shade: string;
    tint: string;
  };
}

@Injectable({
  providedIn: 'root'
})

export class ThemeSwitcherService {

  constructor(
    private storage: Storage,
    private domCtrl: DomController,
    @Inject(DOCUMENT) private document) { }

  async initTheme() {
    const colors: Colors = await this.storage.get(PREFIX + 's4w_customer_colors');
    console.log('colors', colors);
    if (colors) {
      console.log('initTheme()', colors);
      await this.setTheme(colors);
    }
  }

  async setTheme(colors: Colors): Promise<any> {
    await this.storage.set(PREFIX + 's4w_customer_colors', colors);
    return new Promise<string>((resolve) => {
      this.domCtrl.write(() => {
        // Primary
        this.document.documentElement.style.setProperty('--ion-color-primary', colors.primary.main);
        this.document.documentElement.style.setProperty('--ion-color-primary-rgb', colors.primary.mainRGB);
        this.document.documentElement.style.setProperty('--ion-color-primary-contrast', colors.primary.contrast);
        this.document.documentElement.style.setProperty('--ion-color-primary-contrast-rgb', colors.primary.contrastRGB);
        this.document.documentElement.style.setProperty('--ion-color-primary-shade', colors.primary.shade);
        this.document.documentElement.style.setProperty('--ion-color-primary-tint', colors.primary.tint);
        // Secondary
        this.document.documentElement.style.setProperty('--ion-color-secondary', colors.secondary.main);
        this.document.documentElement.style.setProperty('--ion-color-secondary-rgb', colors.secondary.mainRGB);
        this.document.documentElement.style.setProperty('--ion-color-secondary-contrast', colors.secondary.contrast);
        this.document.documentElement.style.setProperty('--ion-color-secondary-contrast-rgb', colors.secondary.contrastRGB);
        this.document.documentElement.style.setProperty('--ion-color-secondary-shade', colors.secondary.shade);
        this.document.documentElement.style.setProperty('--ion-color-secondary-tint', colors.secondary.tint);
      });
      resolve();
    });
  }

  async resetTheme() {
    await this.storage.remove(PREFIX + 's4w_customer_colors');
    return new Promise<string>((resolve) => {
      this.domCtrl.write(() => {
        // Primary
        this.document.documentElement.style.removeProperty('--ion-color-primary');
        this.document.documentElement.style.removeProperty('--ion-color-primary-rgb');
        this.document.documentElement.style.removeProperty('--ion-color-primary-contrast');
        this.document.documentElement.style.removeProperty('--ion-color-primary-contrast-rgb');
        this.document.documentElement.style.removeProperty('--ion-color-primary-shade');
        this.document.documentElement.style.removeProperty('--ion-color-primary-tint');
        // Secondary
        this.document.documentElement.style.removeProperty('--ion-color-secondary');
        this.document.documentElement.style.removeProperty('--ion-color-secondary-rgb');
        this.document.documentElement.style.removeProperty('--ion-color-secondary-contrast');
        this.document.documentElement.style.removeProperty('--ion-color-secondary-contrast-rgb');
        this.document.documentElement.style.removeProperty('--ion-color-secondary-shade');
        this.document.documentElement.style.removeProperty('--ion-color-secondary-tint');
      });
      resolve();
    });
  }
}
