import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/tabs/home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'forum',
    loadChildren: () => import('./pages/forum/forum.module').then( m => m.ForumPageModule)
  },
  {
    path: 'forum/:id',
    loadChildren: () => import('./pages/forum-detail/forum-detail.module').then( m => m.ForumDetailPageModule)
  },
  {
    path: 'classroom',
    loadChildren: () => import('./pages/classroom/classroom.module').then( m => m.ClassroomPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'profile-password',
    loadChildren: () => import('./pages/profile-password/profile-password.module').then( m => m.ProfilePasswordPageModule)
  },
  {
    path: 'profile-preferences',
    loadChildren: () => import('./pages/profile-preferences/profile-preferences.module').then( m => m.ProfilePreferencesPageModule)
  },
  {
    path: 'profile-language',
    loadChildren: () => import('./pages/profile-language/profile-language.module').then( m => m.ProfileLanguagePageModule)
  },
  {
    path: 'profile-premium',
    loadChildren: () => import('./pages/profile-premium/profile-premium.module').then( m => m.ProfilePremiumPageModule)
  },
  {
    path: 'profile-notifications',
    loadChildren: () => import('./pages/profile-notifications/profile-notifications.module').then( m => m.ProfileNotificationsPageModule)
  },
  {
    path: 'buy-premium',
    loadChildren: () => import('./pages/buy-premium/buy-premium.module').then( m => m.BuyPremiumPageModule)
  },
  {
    path: 'profile-levels',
    loadChildren: () => import('./pages/profile-levels/profile-levels.module').then( m => m.ProfileLevelsPageModule)
  },
  {
    path: 'profile-feedback',
    loadChildren: () => import('./pages/profile-feedback/profile-feedback.module').then( m => m.ProfileFeedbackPageModule)
  },
  {
    path: 'training/:mode/:topicOrSubtopicId',
    loadChildren: () => import('./pages/training-mode/training-mode.module').then( m => m.TrainingModePageModule)
  },
  {
    path: 'training/collection/:collectionShareCode/:mode/:topicOrSubtopicId',
    loadChildren: () => import('./pages/training-mode/training-mode.module').then( m => m.TrainingModePageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'rooms',
    loadChildren: () => import('./pages/rooms/rooms.module').then( m => m.RoomsPageModule)
  },
  {
    path: 'room-details',
    loadChildren: () => import('./pages/room-details/room-details.module').then( m => m.RoomDetailsPageModule)
  },
  {
    path: 'room-settings',
    loadChildren: () => import('./pages/room-settings/room-settings.module').then( m => m.RoomSettingsPageModule)
  },
  {
    path: 'room-creation',
    loadChildren: () => import('./pages/room-creation/room-creation.module').then( m => m.RoomCreationPageModule)
  },
  {
    path: 'room-documents',
    loadChildren: () => import('./pages/room-documents/room-documents.module').then( m => m.RoomDocumentsPageModule)
  },
  {
    path: 'my-questions',
    loadChildren: () => import('./pages/my-questions/my-questions.module').then( m => m.MyQuestionsPageModule)
  },
  {
    path: 'room-collections',
    loadChildren: () => import('./pages/room-collections/room-collections.module').then( m => m.RoomCollectionsPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then( m => m.ErrorPageModule)
  },
  {
    path: 'reported-questions',
    loadChildren: () => import('./pages/reported-questions/reported-questions.module').then( m => m.ReportedQuestionsPageModule)
  },
  /*
  * IMPORTANT: Add all other Links above this line. Otherwise they will not work, because the wildcard route overrides them!
  */
  {
    path: '**', // Wildcard route for a 404 page
    redirectTo: '/tabs/home'
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
