/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AvailabilityResponse } from '../model/availabilityResponse';
import { ChangeUserRoleData } from '../model/changeUserRoleData';
import { DeleteUserData } from '../model/deleteUserData';
import { GenericDataMessage } from '../model/genericDataMessage';
import { GenericMessage } from '../model/genericMessage';
import { GenericUserDTOMessage } from '../model/genericUserDTOMessage';
import { PageOfUserDTO } from '../model/pageOfUserDTO';
import { PaymentInfoDTO } from '../model/paymentInfoDTO';
import { ProfileDTO } from '../model/profileDTO';
import { RegistrationData } from '../model/registrationData';
import { RegistrationTransformationData } from '../model/registrationTransformationData';
import { RequestPasswordResetData } from '../model/requestPasswordResetData';
import { RestResponseMessage } from '../model/restResponseMessage';
import { SubscriptionDTO } from '../model/subscriptionDTO';
import { UpdateEmailDataDTO } from '../model/updateEmailDataDTO';
import { UpdatePasswordData } from '../model/updatePasswordData';
import { UpdateUserNameDto } from '../model/updateUserNameDto';
import { UserDTO } from '../model/userDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserControllerService {

    protected basePath = 'https://api.skills4school.de/s4s-api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Buys a topic with credits for a user
     * 
     * @param topicId topicId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public buyTopicUsingPOST(topicId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public buyTopicUsingPOST(topicId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public buyTopicUsingPOST(topicId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public buyTopicUsingPOST(topicId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (topicId === null || topicId === undefined) {
            throw new Error('Required parameter topicId was null or undefined when calling buyTopicUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<boolean>(`${this.basePath}/user/buyTopic/${encodeURIComponent(String(topicId))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * changeUserEmail
     * 
     * @param updateEmailData updateEmailData
     * @param registrationSource registrationSource
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeUserEmailUsingPOST(updateEmailData: UpdateEmailDataDTO, registrationSource?: 'APP' | 'SALES_FUNNEL', observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public changeUserEmailUsingPOST(updateEmailData: UpdateEmailDataDTO, registrationSource?: 'APP' | 'SALES_FUNNEL', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public changeUserEmailUsingPOST(updateEmailData: UpdateEmailDataDTO, registrationSource?: 'APP' | 'SALES_FUNNEL', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public changeUserEmailUsingPOST(updateEmailData: UpdateEmailDataDTO, registrationSource?: 'APP' | 'SALES_FUNNEL', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateEmailData === null || updateEmailData === undefined) {
            throw new Error('Required parameter updateEmailData was null or undefined when calling changeUserEmailUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (registrationSource !== undefined && registrationSource !== null) {
            queryParameters = queryParameters.set('registrationSource', <any>registrationSource);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericMessage>(`${this.basePath}/user/updateEmail`,
            updateEmailData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * changeUserPassword
     * 
     * @param updatePasswordData updatePasswordData
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeUserPasswordUsingPOST(updatePasswordData: UpdatePasswordData, observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public changeUserPasswordUsingPOST(updatePasswordData: UpdatePasswordData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public changeUserPasswordUsingPOST(updatePasswordData: UpdatePasswordData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public changeUserPasswordUsingPOST(updatePasswordData: UpdatePasswordData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updatePasswordData === null || updatePasswordData === undefined) {
            throw new Error('Required parameter updatePasswordData was null or undefined when calling changeUserPasswordUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericMessage>(`${this.basePath}/user/updatePassword`,
            updatePasswordData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * changeUserRole
     * 
     * @param changeUserRoleData changeUserRoleData
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeUserRoleUsingPOST(changeUserRoleData: ChangeUserRoleData, name?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public changeUserRoleUsingPOST(changeUserRoleData: ChangeUserRoleData, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public changeUserRoleUsingPOST(changeUserRoleData: ChangeUserRoleData, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public changeUserRoleUsingPOST(changeUserRoleData: ChangeUserRoleData, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (changeUserRoleData === null || changeUserRoleData === undefined) {
            throw new Error('Required parameter changeUserRoleData was null or undefined when calling changeUserRoleUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericMessage>(`${this.basePath}/user/changeRole`,
            changeUserRoleData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check availability for username
     * 
     * @param userName userName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkAvailableUserNameUsingGET(userName: string, observe?: 'body', reportProgress?: boolean): Observable<AvailabilityResponse>;
    public checkAvailableUserNameUsingGET(userName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AvailabilityResponse>>;
    public checkAvailableUserNameUsingGET(userName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AvailabilityResponse>>;
    public checkAvailableUserNameUsingGET(userName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userName === null || userName === undefined) {
            throw new Error('Required parameter userName was null or undefined when calling checkAvailableUserNameUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AvailabilityResponse>(`${this.basePath}/user/check-username/${encodeURIComponent(String(userName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if user is a pay-what-you-want donator
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIfUserIsDonatorUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public checkIfUserIsDonatorUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public checkIfUserIsDonatorUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public checkIfUserIsDonatorUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/user/check/is-donator`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if user is supporter and had active payments before product changed to free of charge
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIfUserIsSupporterUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public checkIfUserIsSupporterUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public checkIfUserIsSupporterUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public checkIfUserIsSupporterUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/user/check/is-supporter`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify external registrations (sales funnel)
     * 
     * @param token token
     * @param registrationSource registrationSource
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmRegistrationExternalUsingGET(token: string, registrationSource?: 'APP' | 'SALES_FUNNEL', observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public confirmRegistrationExternalUsingGET(token: string, registrationSource?: 'APP' | 'SALES_FUNNEL', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public confirmRegistrationExternalUsingGET(token: string, registrationSource?: 'APP' | 'SALES_FUNNEL', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public confirmRegistrationExternalUsingGET(token: string, registrationSource?: 'APP' | 'SALES_FUNNEL', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling confirmRegistrationExternalUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (registrationSource !== undefined && registrationSource !== null) {
            queryParameters = queryParameters.set('registrationSource', <any>registrationSource);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GenericMessage>(`${this.basePath}/user/verifyRegistrationExternal`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Decline a user with the token from the registration mail
     * 
     * @param token token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public declineRegistrationUsingGET(token: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public declineRegistrationUsingGET(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public declineRegistrationUsingGET(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public declineRegistrationUsingGET(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling declineRegistrationUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/user/declineRegistration`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteUserAccount
     * 
     * @param deleteUserData deleteUserData
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUserAccountUsingPOST(deleteUserData: DeleteUserData, observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public deleteUserAccountUsingPOST(deleteUserData: DeleteUserData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public deleteUserAccountUsingPOST(deleteUserData: DeleteUserData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public deleteUserAccountUsingPOST(deleteUserData: DeleteUserData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deleteUserData === null || deleteUserData === undefined) {
            throw new Error('Required parameter deleteUserData was null or undefined when calling deleteUserAccountUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericMessage>(`${this.basePath}/user/deleteAccount`,
            deleteUserData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a user by id
     * 
     * @param userId userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUserByIdUsingGET(userId: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public deleteUserByIdUsingGET(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public deleteUserByIdUsingGET(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public deleteUserByIdUsingGET(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling deleteUserByIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/user/delete-by-id/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Expire/Disable a user by id
     * 
     * @param userId userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public expireUserByIdUsingGET(userId: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public expireUserByIdUsingGET(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public expireUserByIdUsingGET(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public expireUserByIdUsingGET(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling expireUserByIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/user/expire-by-id/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Expire/Disable a user by name
     * 
     * @param username username
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public expireUserByUsernameUsingGET(username: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public expireUserByUsernameUsingGET(username: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public expireUserByUsernameUsingGET(username: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public expireUserByUsernameUsingGET(username: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling expireUserByUsernameUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/user/expire-by-name/${encodeURIComponent(String(username))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets active topics from user
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveTopicsFromCurrentUserUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public getActiveTopicsFromCurrentUserUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public getActiveTopicsFromCurrentUserUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public getActiveTopicsFromCurrentUserUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/user/getActiveTopicsFromCurrentUser`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets credits from user
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCreditsFromCurrentUserUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public getCreditsFromCurrentUserUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public getCreditsFromCurrentUserUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public getCreditsFromCurrentUserUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.basePath}/user/getCreditsFromCurrentUser`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a pageable list of editor user information
     * 
     * @param page page
     * @param pageSize pageSize
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEditorUserDTOsUsingGET(page: number, pageSize: number, observe?: 'body', reportProgress?: boolean): Observable<PageOfUserDTO>;
    public getEditorUserDTOsUsingGET(page: number, pageSize: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOfUserDTO>>;
    public getEditorUserDTOsUsingGET(page: number, pageSize: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOfUserDTO>>;
    public getEditorUserDTOsUsingGET(page: number, pageSize: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getEditorUserDTOsUsingGET.');
        }

        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getEditorUserDTOsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageOfUserDTO>(`${this.basePath}/user/list/editors`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get profile picture of active user
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnProfilePictureUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getOwnProfilePictureUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getOwnProfilePictureUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getOwnProfilePictureUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/user/profile/picture`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get profile of active user
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnProfileUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileDTO>;
    public getOwnProfileUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileDTO>>;
    public getOwnProfileUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileDTO>>;
    public getOwnProfileUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProfileDTO>(`${this.basePath}/user/profile`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets subscriptions and credits from user
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPaymentInfoFromCurrentUserUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<PaymentInfoDTO>;
    public getPaymentInfoFromCurrentUserUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentInfoDTO>>;
    public getPaymentInfoFromCurrentUserUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentInfoDTO>>;
    public getPaymentInfoFromCurrentUserUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PaymentInfoDTO>(`${this.basePath}/user/getPaymentInfoFromCurrentUser`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get profile picture of any user by !profile! id
     * 
     * @param profileId profileId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfilePictureByUserIdUsingGET(profileId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getProfilePictureByUserIdUsingGET(profileId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getProfilePictureByUserIdUsingGET(profileId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getProfilePictureByUserIdUsingGET(profileId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling getProfilePictureByUserIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/user/profile/picture_profile/${encodeURIComponent(String(profileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets subscription from user
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubscriptionFromCurrentUserUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<SubscriptionDTO>;
    public getSubscriptionFromCurrentUserUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubscriptionDTO>>;
    public getSubscriptionFromCurrentUserUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubscriptionDTO>>;
    public getSubscriptionFromCurrentUserUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SubscriptionDTO>(`${this.basePath}/user/getSubscriptionFromCurrentUser`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get own userDTO
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserDTOUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<UserDTO>;
    public getUserDTOUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDTO>>;
    public getUserDTOUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDTO>>;
    public getUserDTOUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserDTO>(`${this.basePath}/user/data/principal`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a userDTO for a username
     * 
     * @param username username
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserDTOsUsingGET(username: string, observe?: 'body', reportProgress?: boolean): Observable<UserDTO>;
    public getUserDTOsUsingGET(username: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDTO>>;
    public getUserDTOsUsingGET(username: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDTO>>;
    public getUserDTOsUsingGET(username: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getUserDTOsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (username !== undefined && username !== null) {
            queryParameters = queryParameters.set('username', <any>username);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserDTO>(`${this.basePath}/user/data`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a pageable list of userinformation
     * 
     * @param page page
     * @param pageSize pageSize
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserDTOsUsingGET1(page: number, pageSize: number, observe?: 'body', reportProgress?: boolean): Observable<PageOfUserDTO>;
    public getUserDTOsUsingGET1(page: number, pageSize: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOfUserDTO>>;
    public getUserDTOsUsingGET1(page: number, pageSize: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOfUserDTO>>;
    public getUserDTOsUsingGET1(page: number, pageSize: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getUserDTOsUsingGET1.');
        }

        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getUserDTOsUsingGET1.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageOfUserDTO>(`${this.basePath}/user/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Register a new user
     * 
     * @param registrationData registrationData
     * @param isTeacher isTeacher
     * @param registrationSource registrationSource
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerUserAccountUsingPOST(registrationData: RegistrationData, isTeacher?: boolean, registrationSource?: 'APP' | 'SALES_FUNNEL', observe?: 'body', reportProgress?: boolean): Observable<GenericDataMessage>;
    public registerUserAccountUsingPOST(registrationData: RegistrationData, isTeacher?: boolean, registrationSource?: 'APP' | 'SALES_FUNNEL', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericDataMessage>>;
    public registerUserAccountUsingPOST(registrationData: RegistrationData, isTeacher?: boolean, registrationSource?: 'APP' | 'SALES_FUNNEL', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericDataMessage>>;
    public registerUserAccountUsingPOST(registrationData: RegistrationData, isTeacher?: boolean, registrationSource?: 'APP' | 'SALES_FUNNEL', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (registrationData === null || registrationData === undefined) {
            throw new Error('Required parameter registrationData was null or undefined when calling registerUserAccountUsingPOST.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isTeacher !== undefined && isTeacher !== null) {
            queryParameters = queryParameters.set('isTeacher', <any>isTeacher);
        }
        if (registrationSource !== undefined && registrationSource !== null) {
            queryParameters = queryParameters.set('registrationSource', <any>registrationSource);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericDataMessage>(`${this.basePath}/user/registration`,
            registrationData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a password reset for the given user
     * 
     * @param requestPasswordResetData requestPasswordResetData
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestPasswordResetUsingPOST(requestPasswordResetData: RequestPasswordResetData, observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public requestPasswordResetUsingPOST(requestPasswordResetData: RequestPasswordResetData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public requestPasswordResetUsingPOST(requestPasswordResetData: RequestPasswordResetData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public requestPasswordResetUsingPOST(requestPasswordResetData: RequestPasswordResetData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (requestPasswordResetData === null || requestPasswordResetData === undefined) {
            throw new Error('Required parameter requestPasswordResetData was null or undefined when calling requestPasswordResetUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericMessage>(`${this.basePath}/user/resetPassword`,
            requestPasswordResetData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Resend an activation mail
     * 
     * @param username username
     * @param registrationSource registrationSource
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resendActivationMailUsingGET(username: string, registrationSource?: 'APP' | 'SALES_FUNNEL', observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public resendActivationMailUsingGET(username: string, registrationSource?: 'APP' | 'SALES_FUNNEL', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public resendActivationMailUsingGET(username: string, registrationSource?: 'APP' | 'SALES_FUNNEL', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public resendActivationMailUsingGET(username: string, registrationSource?: 'APP' | 'SALES_FUNNEL', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling resendActivationMailUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (registrationSource !== undefined && registrationSource !== null) {
            queryParameters = queryParameters.set('registrationSource', <any>registrationSource);
        }
        if (username !== undefined && username !== null) {
            queryParameters = queryParameters.set('username', <any>username);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GenericMessage>(`${this.basePath}/user/resendActivation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change user&#39;s donated flag
     * 
     * @param donated donated
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setDonatedFlagUsingPUT(donated: boolean, name?: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public setDonatedFlagUsingPUT(donated: boolean, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public setDonatedFlagUsingPUT(donated: boolean, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public setDonatedFlagUsingPUT(donated: boolean, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (donated === null || donated === undefined) {
            throw new Error('Required parameter donated was null or undefined when calling setDonatedFlagUsingPUT.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<RestResponseMessage>(`${this.basePath}/user/flag/donated/${encodeURIComponent(String(donated))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set favorite district of a user
     * 
     * @param districtId districtId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setOwnFavoriteDistrictUsingPOST(districtId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileDTO>;
    public setOwnFavoriteDistrictUsingPOST(districtId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileDTO>>;
    public setOwnFavoriteDistrictUsingPOST(districtId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileDTO>>;
    public setOwnFavoriteDistrictUsingPOST(districtId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (districtId === null || districtId === undefined) {
            throw new Error('Required parameter districtId was null or undefined when calling setOwnFavoriteDistrictUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (districtId !== undefined && districtId !== null) {
            queryParameters = queryParameters.set('districtId', <any>districtId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ProfileDTO>(`${this.basePath}/user/profile/district`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set favorite school type of a user
     * 
     * @param schoolTypeId schoolTypeId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setOwnFavoriteSchoolTypeUsingPOST(schoolTypeId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileDTO>;
    public setOwnFavoriteSchoolTypeUsingPOST(schoolTypeId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileDTO>>;
    public setOwnFavoriteSchoolTypeUsingPOST(schoolTypeId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileDTO>>;
    public setOwnFavoriteSchoolTypeUsingPOST(schoolTypeId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schoolTypeId === null || schoolTypeId === undefined) {
            throw new Error('Required parameter schoolTypeId was null or undefined when calling setOwnFavoriteSchoolTypeUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (schoolTypeId !== undefined && schoolTypeId !== null) {
            queryParameters = queryParameters.set('schoolTypeId', <any>schoolTypeId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ProfileDTO>(`${this.basePath}/user/profile/school-type`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set profile picture of active user
     * 
     * @param base64String base64String
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setOwnProfilePictureUsingPOST(base64String: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public setOwnProfilePictureUsingPOST(base64String: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public setOwnProfilePictureUsingPOST(base64String: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public setOwnProfilePictureUsingPOST(base64String: string, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (base64String === null || base64String === undefined) {
            throw new Error('Required parameter base64String was null or undefined when calling setOwnProfilePictureUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericMessage>(`${this.basePath}/user/profile/picture`,
            base64String,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set correct question count of a user
     * 
     * @param count count
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setOwnQuestionCountUsingPOST(count: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileDTO>;
    public setOwnQuestionCountUsingPOST(count: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileDTO>>;
    public setOwnQuestionCountUsingPOST(count: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileDTO>>;
    public setOwnQuestionCountUsingPOST(count: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (count === null || count === undefined) {
            throw new Error('Required parameter count was null or undefined when calling setOwnQuestionCountUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (count !== undefined && count !== null) {
            queryParameters = queryParameters.set('count', <any>count);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ProfileDTO>(`${this.basePath}/user/profile/question-count`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Transform demo account into user account
     * 
     * @param demoUserId demoUserId
     * @param registrationTransformationData registrationTransformationData
     * @param isTeacher isTeacher
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public transformUserAccountUsingPOST(demoUserId: number, registrationTransformationData: RegistrationTransformationData, isTeacher?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GenericUserDTOMessage>;
    public transformUserAccountUsingPOST(demoUserId: number, registrationTransformationData: RegistrationTransformationData, isTeacher?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericUserDTOMessage>>;
    public transformUserAccountUsingPOST(demoUserId: number, registrationTransformationData: RegistrationTransformationData, isTeacher?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericUserDTOMessage>>;
    public transformUserAccountUsingPOST(demoUserId: number, registrationTransformationData: RegistrationTransformationData, isTeacher?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (demoUserId === null || demoUserId === undefined) {
            throw new Error('Required parameter demoUserId was null or undefined when calling transformUserAccountUsingPOST.');
        }

        if (registrationTransformationData === null || registrationTransformationData === undefined) {
            throw new Error('Required parameter registrationTransformationData was null or undefined when calling transformUserAccountUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isTeacher !== undefined && isTeacher !== null) {
            queryParameters = queryParameters.set('isTeacher', <any>isTeacher);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericUserDTOMessage>(`${this.basePath}/user/transform/${encodeURIComponent(String(demoUserId))}`,
            registrationTransformationData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update user name
     * 
     * @param updateUserNameDto updateUserNameDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserNameUsingPUT(updateUserNameDto: UpdateUserNameDto, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public updateUserNameUsingPUT(updateUserNameDto: UpdateUserNameDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public updateUserNameUsingPUT(updateUserNameDto: UpdateUserNameDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public updateUserNameUsingPUT(updateUserNameDto: UpdateUserNameDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateUserNameDto === null || updateUserNameDto === undefined) {
            throw new Error('Required parameter updateUserNameDto was null or undefined when calling updateUserNameUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<RestResponseMessage>(`${this.basePath}/user/update/user-name`,
            updateUserNameDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Checks if user name requires an update
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userNameRequiresUpdateUsingGET(observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public userNameRequiresUpdateUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public userNameRequiresUpdateUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public userNameRequiresUpdateUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/user/check/username-requires-update`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
