/**
 * Skills4School REST API
 * The API for the Skills4School backend.
 *
 * OpenAPI spec version: 1.8.12.0
 * Contact: heiko.teschke@skills4school.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { GenericDataMessage } from '../model/genericDataMessage';
import { GenericMessage } from '../model/genericMessage';
import { RestResponseMessage } from '../model/restResponseMessage';
import { SimpleQuestionCollectionDTO } from '../model/simpleQuestionCollectionDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CollectionControllerService {

    protected basePath = 'https://api.skills4school.de/s4s-api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add the active user to the collection with the given share code
     * 
     * @param shareCode shareCode
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addActiveUserToCollectionWithShareCodeUsingPUT(shareCode: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SimpleQuestionCollectionDTO>;
    public addActiveUserToCollectionWithShareCodeUsingPUT(shareCode: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleQuestionCollectionDTO>>;
    public addActiveUserToCollectionWithShareCodeUsingPUT(shareCode: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleQuestionCollectionDTO>>;
    public addActiveUserToCollectionWithShareCodeUsingPUT(shareCode: string, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shareCode === null || shareCode === undefined) {
            throw new Error('Required parameter shareCode was null or undefined when calling addActiveUserToCollectionWithShareCodeUsingPUT.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SimpleQuestionCollectionDTO>(`${this.basePath}/collection/exec/join-by-code`,
            shareCode,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add the collection to the users list of collections by share code
     * 
     * @param estimatedCredits estimatedCredits
     * @param shareCode shareCode
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCollectionByShareCodeUsingPOST(estimatedCredits: number, shareCode: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public addCollectionByShareCodeUsingPOST(estimatedCredits: number, shareCode: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public addCollectionByShareCodeUsingPOST(estimatedCredits: number, shareCode: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public addCollectionByShareCodeUsingPOST(estimatedCredits: number, shareCode: string, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (estimatedCredits === null || estimatedCredits === undefined) {
            throw new Error('Required parameter estimatedCredits was null or undefined when calling addCollectionByShareCodeUsingPOST.');
        }

        if (shareCode === null || shareCode === undefined) {
            throw new Error('Required parameter shareCode was null or undefined when calling addCollectionByShareCodeUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (estimatedCredits !== undefined && estimatedCredits !== null) {
            queryParameters = queryParameters.set('estimatedCredits', <any>estimatedCredits);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (shareCode !== undefined && shareCode !== null) {
            queryParameters = queryParameters.set('shareCode', <any>shareCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<RestResponseMessage>(`${this.basePath}/collection/exec/add-by-share-code`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new collection for the active user
     * 
     * @param simpleQuestionCollectionDTO simpleQuestionCollectionDTO
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCollectionsForActiveUserUsingPOST(simpleQuestionCollectionDTO: SimpleQuestionCollectionDTO, name?: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public createCollectionsForActiveUserUsingPOST(simpleQuestionCollectionDTO: SimpleQuestionCollectionDTO, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public createCollectionsForActiveUserUsingPOST(simpleQuestionCollectionDTO: SimpleQuestionCollectionDTO, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public createCollectionsForActiveUserUsingPOST(simpleQuestionCollectionDTO: SimpleQuestionCollectionDTO, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simpleQuestionCollectionDTO === null || simpleQuestionCollectionDTO === undefined) {
            throw new Error('Required parameter simpleQuestionCollectionDTO was null or undefined when calling createCollectionsForActiveUserUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/collection/data/simple`,
            simpleQuestionCollectionDTO,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a single collection by id
     * 
     * @param collectionId collectionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCollectionByIdUsingDELETE(collectionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteCollectionByIdUsingDELETE(collectionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteCollectionByIdUsingDELETE(collectionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteCollectionByIdUsingDELETE(collectionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (collectionId === null || collectionId === undefined) {
            throw new Error('Required parameter collectionId was null or undefined when calling deleteCollectionByIdUsingDELETE.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/collection/exec/delete-by-id/${encodeURIComponent(String(collectionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Extend the validity of a collection: set date 7 days in the future.
     * 
     * @param collectionId collectionId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public extendCollectionValidityForSevenDaysUsingPOST(collectionId: number, observe?: 'body', reportProgress?: boolean): Observable<SimpleQuestionCollectionDTO>;
    public extendCollectionValidityForSevenDaysUsingPOST(collectionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleQuestionCollectionDTO>>;
    public extendCollectionValidityForSevenDaysUsingPOST(collectionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleQuestionCollectionDTO>>;
    public extendCollectionValidityForSevenDaysUsingPOST(collectionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (collectionId === null || collectionId === undefined) {
            throw new Error('Required parameter collectionId was null or undefined when calling extendCollectionValidityForSevenDaysUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<SimpleQuestionCollectionDTO>(`${this.basePath}/collection/data/simple/extension-of-validity/${encodeURIComponent(String(collectionId))}/for-seven-days`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Extend the validity of a collection: set date 30 days in the future and pay credits for expired school topics
     * 
     * @param collectionId collectionId
     * @param estimatedCredits estimatedCredits
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public extendCollectionValidityForThirtyDaysUsingPOST(collectionId: number, estimatedCredits: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public extendCollectionValidityForThirtyDaysUsingPOST(collectionId: number, estimatedCredits: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public extendCollectionValidityForThirtyDaysUsingPOST(collectionId: number, estimatedCredits: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public extendCollectionValidityForThirtyDaysUsingPOST(collectionId: number, estimatedCredits: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (collectionId === null || collectionId === undefined) {
            throw new Error('Required parameter collectionId was null or undefined when calling extendCollectionValidityForThirtyDaysUsingPOST.');
        }

        if (estimatedCredits === null || estimatedCredits === undefined) {
            throw new Error('Required parameter estimatedCredits was null or undefined when calling extendCollectionValidityForThirtyDaysUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<RestResponseMessage>(`${this.basePath}/collection/data/simple/extension-of-validity/${encodeURIComponent(String(collectionId))}/for-thirty-days/with-credits/${encodeURIComponent(String(estimatedCredits))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Extend the validity of a collection: set date 30 days in the future (subscription context).
     * 
     * @param collectionId collectionId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public extendCollectionValidityUsingPOST(collectionId: number, observe?: 'body', reportProgress?: boolean): Observable<SimpleQuestionCollectionDTO>;
    public extendCollectionValidityUsingPOST(collectionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleQuestionCollectionDTO>>;
    public extendCollectionValidityUsingPOST(collectionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleQuestionCollectionDTO>>;
    public extendCollectionValidityUsingPOST(collectionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (collectionId === null || collectionId === undefined) {
            throw new Error('Required parameter collectionId was null or undefined when calling extendCollectionValidityUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<SimpleQuestionCollectionDTO>(`${this.basePath}/collection/data/simple/extension-of-validity/${encodeURIComponent(String(collectionId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all collection-ids for active user
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCollectionIdsForActiveUserUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public getAllCollectionIdsForActiveUserUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public getAllCollectionIdsForActiveUserUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public getAllCollectionIdsForActiveUserUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/collection/id/active-user`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all collections for active user
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCollectionsForActiveUserUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionCollectionDTO>>;
    public getAllCollectionsForActiveUserUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionCollectionDTO>>>;
    public getAllCollectionsForActiveUserUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionCollectionDTO>>>;
    public getAllCollectionsForActiveUserUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleQuestionCollectionDTO>>(`${this.basePath}/collection/data/simple/active-user`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a single collection by id
     * 
     * @param collectionId collectionId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCollectionByIdUsingGET(collectionId: number, observe?: 'body', reportProgress?: boolean): Observable<SimpleQuestionCollectionDTO>;
    public getCollectionByIdUsingGET(collectionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleQuestionCollectionDTO>>;
    public getCollectionByIdUsingGET(collectionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleQuestionCollectionDTO>>;
    public getCollectionByIdUsingGET(collectionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (collectionId === null || collectionId === undefined) {
            throw new Error('Required parameter collectionId was null or undefined when calling getCollectionByIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleQuestionCollectionDTO>(`${this.basePath}/collection/data/simple/single/${encodeURIComponent(String(collectionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get collection ID and name by share code
     * 
     * @param shareCode shareCode
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCollectionIdAndNameByShareCodeUsingGET(shareCode: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getCollectionIdAndNameByShareCodeUsingGET(shareCode: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getCollectionIdAndNameByShareCodeUsingGET(shareCode: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getCollectionIdAndNameByShareCodeUsingGET(shareCode: string, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shareCode === null || shareCode === undefined) {
            throw new Error('Required parameter shareCode was null or undefined when calling getCollectionIdAndNameByShareCodeUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/collection/get/id-and-name/by/share-code/${encodeURIComponent(String(shareCode))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get users own question collections with their school topics, sub topics and learning progress details
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnCollectionsWithTopicsAndSubTopicsAndLearningProgressDetailsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getOwnCollectionsWithTopicsAndSubTopicsAndLearningProgressDetailsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getOwnCollectionsWithTopicsAndSubTopicsAndLearningProgressDetailsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getOwnCollectionsWithTopicsAndSubTopicsAndLearningProgressDetailsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/collection/get/own/collections/with/topics/and/sub-topics/and/learning-progress-details`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the required amount of credits to extend validity of given collection
     * 
     * @param collectionId collectionId
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRequiredCreditsForCollectionUsingGET(collectionId: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericDataMessage>;
    public getRequiredCreditsForCollectionUsingGET(collectionId: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericDataMessage>>;
    public getRequiredCreditsForCollectionUsingGET(collectionId: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericDataMessage>>;
    public getRequiredCreditsForCollectionUsingGET(collectionId: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (collectionId === null || collectionId === undefined) {
            throw new Error('Required parameter collectionId was null or undefined when calling getRequiredCreditsForCollectionUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GenericDataMessage>(`${this.basePath}/collection/data/simple/single/${encodeURIComponent(String(collectionId))}/required-credits`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the required amount of credits to add a collection by share code
     * 
     * @param shareCode shareCode
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRequiredCreditsToAddCollectionByShareCodeUsingGET(shareCode: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getRequiredCreditsToAddCollectionByShareCodeUsingGET(shareCode: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getRequiredCreditsToAddCollectionByShareCodeUsingGET(shareCode: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getRequiredCreditsToAddCollectionByShareCodeUsingGET(shareCode: string, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shareCode === null || shareCode === undefined) {
            throw new Error('Required parameter shareCode was null or undefined when calling getRequiredCreditsToAddCollectionByShareCodeUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (shareCode !== undefined && shareCode !== null) {
            queryParameters = queryParameters.set('shareCode', <any>shareCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/collection/data/get/required-credits-to-add-by-share-code`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a list of simple SimpleCollectionDTOs by a list of ids
     * 
     * @param collectionIdList collectionIdList
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleQuestionCollectionDTOListByIdListUsingPOST(collectionIdList: Array<number>, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleQuestionCollectionDTO>>;
    public getSimpleQuestionCollectionDTOListByIdListUsingPOST(collectionIdList: Array<number>, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleQuestionCollectionDTO>>>;
    public getSimpleQuestionCollectionDTOListByIdListUsingPOST(collectionIdList: Array<number>, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleQuestionCollectionDTO>>>;
    public getSimpleQuestionCollectionDTOListByIdListUsingPOST(collectionIdList: Array<number>, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (collectionIdList === null || collectionIdList === undefined) {
            throw new Error('Required parameter collectionIdList was null or undefined when calling getSimpleQuestionCollectionDTOListByIdListUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<SimpleQuestionCollectionDTO>>(`${this.basePath}/collection/data/simple/list`,
            collectionIdList,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get SimpleQuestionCollectionDTO by share code
     * 
     * @param shareCode shareCode
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimpleQuestionCollectionDtoByShareCodeUsingGET(shareCode: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getSimpleQuestionCollectionDtoByShareCodeUsingGET(shareCode: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getSimpleQuestionCollectionDtoByShareCodeUsingGET(shareCode: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getSimpleQuestionCollectionDtoByShareCodeUsingGET(shareCode: string, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shareCode === null || shareCode === undefined) {
            throw new Error('Required parameter shareCode was null or undefined when calling getSimpleQuestionCollectionDtoByShareCodeUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/collection/get/by/share-code/${encodeURIComponent(String(shareCode))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get users question collections with learning progress details
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersCollectionsWithLearningProgressDetailsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public getUsersCollectionsWithLearningProgressDetailsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public getUsersCollectionsWithLearningProgressDetailsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public getUsersCollectionsWithLearningProgressDetailsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RestResponseMessage>(`${this.basePath}/collection/get/users/data/with/learning-progress-details`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rename a single collection by ID, if user is the owner
     * 
     * @param collectionId collectionId
     * @param name name
     * @param name2 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public renameCollectionUsingPUT(collectionId: number, name: string, name2?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericMessage>;
    public renameCollectionUsingPUT(collectionId: number, name: string, name2?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericMessage>>;
    public renameCollectionUsingPUT(collectionId: number, name: string, name2?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericMessage>>;
    public renameCollectionUsingPUT(collectionId: number, name: string, name2?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (collectionId === null || collectionId === undefined) {
            throw new Error('Required parameter collectionId was null or undefined when calling renameCollectionUsingPUT.');
        }

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling renameCollectionUsingPUT.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name2 !== undefined && name2 !== null) {
            queryParameters = queryParameters.set('name', <any>name2);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<GenericMessage>(`${this.basePath}/collection/exec/rename/${encodeURIComponent(String(collectionId))}`,
            name,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * set exam date
     * 
     * @param collectionId collectionId
     * @param examDay examDay
     * @param examMonth examMonth
     * @param examYear examYear
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setExamDateUsingPATCH(collectionId: number, examDay: number, examMonth: number, examYear: number, name?: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponseMessage>;
    public setExamDateUsingPATCH(collectionId: number, examDay: number, examMonth: number, examYear: number, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponseMessage>>;
    public setExamDateUsingPATCH(collectionId: number, examDay: number, examMonth: number, examYear: number, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponseMessage>>;
    public setExamDateUsingPATCH(collectionId: number, examDay: number, examMonth: number, examYear: number, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (collectionId === null || collectionId === undefined) {
            throw new Error('Required parameter collectionId was null or undefined when calling setExamDateUsingPATCH.');
        }

        if (examDay === null || examDay === undefined) {
            throw new Error('Required parameter examDay was null or undefined when calling setExamDateUsingPATCH.');
        }

        if (examMonth === null || examMonth === undefined) {
            throw new Error('Required parameter examMonth was null or undefined when calling setExamDateUsingPATCH.');
        }

        if (examYear === null || examYear === undefined) {
            throw new Error('Required parameter examYear was null or undefined when calling setExamDateUsingPATCH.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (collectionId !== undefined && collectionId !== null) {
            queryParameters = queryParameters.set('collectionId', <any>collectionId);
        }
        if (examDay !== undefined && examDay !== null) {
            queryParameters = queryParameters.set('examDay', <any>examDay);
        }
        if (examMonth !== undefined && examMonth !== null) {
            queryParameters = queryParameters.set('examMonth', <any>examMonth);
        }
        if (examYear !== undefined && examYear !== null) {
            queryParameters = queryParameters.set('examYear', <any>examYear);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<RestResponseMessage>(`${this.basePath}/collection/exec/exam-date`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
